import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  verifyUserAuth,
  useVerifyUserLoading,
  useIsUserVerified,

} from "redux/appSlice";
import tick from "assets/tick.png";
import error from "assets/close.png";
import "./UserVerifyRedirect.css";
import CustomButton from "shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

const UserVerifyRedirect = () => {
  /**
   * Function that returns a page where user is redirected to after
   * clicking the verification link that is sent to their email.
   */

  const [searchParams, setSearchParams] = useSearchParams();
  const verificationToken = searchParams.get("token");


  const dispatch = useDispatch();
  const navigate = useNavigate();

  // function that calls auth/verify api
  const sendUserVerification = (verificationToken) => {
    if (verificationToken) {
      dispatch(verifyUserAuth({ verificationToken: verificationToken }));
    }
  };

  // on first load, send user verification
  useEffect(() => {

    sendUserVerification(verificationToken);
  }, []);

  // import state from redux store
  const isUserVerified = useIsUserVerified(); // to track when user has successfully verified
  const isVerifyUserLoading = useVerifyUserLoading(); // to track when user verify api is in transit to the server
  const isVerificationLinkExpired = true;

  const renderBasedOnCondition = () => {
    // function that renders the user's view based on certain conditions

    if (!verificationToken) {
      // if verification token is missing, render missing token view
      return (
        <div className="user-verify-redirect-container">
          <div className="user-verify-redirect-details">
            Error: verification token is missing.
          </div>
        </div>
      );
    } else if (isVerifyUserLoading) {
      // if verification is sent and loading, show to user verification is loading, don't close the page
      return (
        <div className="user-verify-redirect-container">
          <div className="user-verify-redirect-details">
            Awaiting email verification, don't refresh the browser or close the
            page.
          </div>
        </div>
      );
    } else if (!isVerifyUserLoading) {
      // if verification request is completed/rejected
      if (isUserVerified) {
        // if verification is successful
        return (
          <div className="user-verify-redirect-container">
            <img
              style={{
                marginTop: "1.25rem",
                width: "120px",
                height: "120px",
                marginBottom: "14px",
              }}
              src={tick}
            />
            <div className="user-verify-redirect-title">Verified!</div>
            <div className="user-verify-redirect-details">
              Your account has been verified.
            </div>
            <CustomButton
              testId="login-btn"
              className="basic"
              content="Login"
              clicked={async () => {
                navigate("/login");
              }}
            ></CustomButton>
          </div>
        );
      } else {
        // if verification has failed
        if (isVerificationLinkExpired) {
          // if verification link/token has expired
          return (
            <div className="user-verify-redirect-container">
              <img
                style={{
                  marginTop: "1.25rem",
                  width: "120px",
                  height: "120px",
                  marginBottom: "16px",
                }}
                src={error}
              />
              <div className="user-verify-redirect-details">
                Error: Email verification has failed as your link has expired.
                <br /> <br /> Please <b>login</b> again so that you can generate
                a new verification <br />
                link that will be sent to your registered email.
                <br /> <br />
                You cannot participate in the challenge if your email is not
                verified.
              </div>
              <CustomButton
                testId="login-btn"
                className="basic"
                content="Login"
                clicked={async () => {
                  navigate("/login");
                }}
              ></CustomButton>
            </div>
          );
        } else {
          // other errors
          return (
            <div className="user-verify-redirect-container">
              <img
                style={{
                  marginTop: "1.25rem",
                  width: "120px",
                  height: "120px",
                  marginBottom: "16px",
                }}
                src={error}
              />
              <div className="user-verify-redirect-details">
                Error: Email verification has failed, please retry.
              </div>
              <div className="user-verify-redirect-details">
                You cannot participate in the challenge if your email is not
                verified.
              </div>
            </div>
          );
        }
      }
    }
  };

  return (
    <>
      <div>{renderBasedOnCondition()}</div>
    </>
  );
};

export default UserVerifyRedirect;
