function checkValidJsonParse(jsonString) {
  /*
   * Check if jsonString is a valid JSON string for JSON.parse(),
   * to prevent a fatal TypeError when JSON.parse() is called with an invalid jsonString.
   * This can happen when backend API produces a "Internal Server Error", in which case fetch() will produce a "TypeError: Failed to fetch",
   * which will cause all JSON.parse(error.message) to raise a fatal "SyntaxError: is not valid JSON" and break the page. 
   */
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

export default checkValidJsonParse;
