import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { backendDomain } from "utils/envConfig";
import axios from "axios";
import axiosRetry from "axios-retry";
import bytesConverter from "utils/bytesConverter";
import datetimeConverter from "utils/datetimeConverter";
import { logOut } from "redux/loginSlice";
import AggregatedStatusIcon from "components/Submit/AggregatedStatusIcon";
// import FinalPickReminderBanner from "components/Submit/FinalPickReminderBanner";
import checkValidJsonParse from "utils/checkValidJsonParse";
import JsonSubmissionFileValidation from "components/Submit/JsonSubmissionFileValidation";

// import doApiRequest from "utils/doApiRequest"; // for testing doApiRequest function

// css-related components
import toast from "react-hot-toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import Stack from "react-bootstrap/Stack";
import { alpha } from "@mui/material/styles";
// import MuiButton from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

// material ui icons
// import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
// import FilePresentIcon from "@mui/icons-material/FilePresent";
// import AnnouncementIcon from "@mui/icons-material/Announcement";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
// import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
// import ErrorIcon from "@mui/icons-material/Error";
// import TitleIcon from "@mui/icons-material/Title";
import NotesIcon from "@mui/icons-material/Notes";
// import CommentIcon from "@mui/icons-material/Comment";
import TopicIcon from "@mui/icons-material/Topic";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import EventIcon from "@mui/icons-material/Event";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import AnnouncementRoundedIcon from "@mui/icons-material/AnnouncementRounded";

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

import "./SubmitCreate.css";

const schema = yup.object().shape({
  file_select: yup
    .mixed()
    .required("A file is required")
    .test(
      "fileSize",
      `Only .tar.gz files up to ${bytesConverter(
        process.env.REACT_APP_FILE_LIMIT,
        "GB"
      )} are permitted`,
      (value, context) => {
        return (
          value && value.size <= parseInt(process.env.REACT_APP_FILE_LIMIT, 10)
        );
      }
    ),
});

const SubmitCreate = () => {
  /*
   * Main function that renders the entire submissions page.
   */
  const {
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [selectedFile, setSelectedFile] = useState(null); // stores user selected submission image file
  const [isFilePicked, setIsFilePicked] = useState(false); // track whether user has selected a submission image file
  const [preSignedURL, setPreSignedURL] = useState(null); // stores API object related to presigned URL, returned from POST submissions
  const [multipartPresignedURLs, setMultipartPresignedURLs] = useState(null); // stores response of multipart presigned url, returned from POST submissions multipart
  const [submissionImageUploading, setSubmissionImageUploading] =
    useState(false); // to track when image uploading to bucket is happening
  const [validateError, setValidateError] = useState(null);
  const [uploadProgressPct, setUploadProgressPct] = useState(0); // tracks submission upload to presigned url progress percentage
  const [abortControllerState, setAbortControllerState] = useState(null);
  const [isSubmissionCreated, setIsSubmissionCreated] = useState(false);
  const [userInputSubmissionTitle, setUserInputSubmissionTitle] = useState(""); // storing user's submission title text box
  const [userInputSubmissionComment, setUserInputSubmissionComment] =
    useState(""); // storing user's submission comment text box
  const [teamDt, setTeamDt] = useState([]); // store team details from team-profile API
  const [rows, setRows] = useState([]); // the submissions API response and the data that will be rendered on the submissions table
  const [allRowsCached, setAllRowsCached] = useState([]); // to cache original submissions API response
  const [selectedCached, setSelectedCached] = useState(new Set()); //  to cache the result_id of original selected submissions API response
  const [tableActiveFilters, setTableActiveFilters] = useState([""]); // for tracking the which submission table filter button is clicked
  const [selected, setSelected] = useState(new Set()); // for tracking user's selected submissions
  const [submissionPhaseFlag, setSubmissionPhaseFlag] = useState(null);
  const [commentCharCount, setCommentCharCount] = useState(0); // for showing submissions upload user's comment box character count
  const [showKSubmitConfirmationModal, setShowKSubmitConfirmationModal] =
    useState(false); // to store condition w.r.t when confirmation modal should be rendered
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // to track whether there is unsaved changes made to the submissions table w.r.t selecting K submissions
  const [loading, setLoading] = useState(false);
  const [
    showUploadSubmissionConfirmationModal,
    setShowUploadSubmissionConfirmationModal,
  ] = useState(false); // to store condition w.r.t when upload submission confirmation modal should be rendered
  const [allQuotasOfTeam, setAllQuotasOfTeam] = useState(false); // to store all types of the team's submission quotas
  const [isUserInTeam, setIsUserInTeam] = useState(false); // to user is in a team
  const [multipartUploadProgressTracker, setMultipartUploadProgressTracker] =
    useState(Array(25).fill(0)); // create 25 indexes to track the upload progress of each part in the multipart upload
  const [multipartUploadAbortController, setMultipartUploadAbortController] =
    useState(new AbortController()); // abortController for cancelling all multipart uploads
  const [isToolbarVisible, setToolbarVisible] = useState(false);

  const handleScroll = () => {
    // Check if the user has scrolled enough to show/hide the toolbar
    const scrollPosition = window.scrollY;
    setToolbarVisible(scrollPosition > 200); // Adjust the scroll position as needed
  };

  // Attach the scroll event listener
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const teamProfile = teamDt?.team_name;

  const loadSelectedToState = async (response_data) => {
    /*
     * Function that stores already selected (ie. final_pick = true) records' result_ids
     * to state, using data from API's response.
     */
    const loadSelected = new Set(); // initialize set (result_id is unique)
    if (response_data?.num_picked_results > 0) {
      response_data.aggregated_response.map((row, index) => {
        // iterate through API response and find final_pick=true records
        if (row.Results?.final_pick) {
          // store result_id to set
          loadSelected.add(row.Results.result_id);
        }
      });
    }
    // store already selected result_ids to state
    setSelected(loadSelected);
    setSelectedCached(loadSelected);
  };

  // for testing doApiRequest function
  // const getUserProfile2 = async () => {
  //   /*
  //    * Function that handles GET profile API call using unified doApiRequest().
  //    */

  //   const apiResponse = await doApiRequest("get", "/api/v0.1/auth/profile")
  // }

  const getUserProfile = async () => {
    /*
     * Function that handles GET profile API call.
     */
    let url = `${backendDomain}/api/v0.1/auth/profile`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const res = await response.json();

        // check if user is in team
        if (res.team && res.team !== "") {
          setIsUserInTeam(true);
        }
        return;
      }
    } catch (error) {
      // console.log(error);
      console.log("Error retrieving user's profile");
      // TODO: add error mgmt for get profile api? (not sure if needed since it's only used to check if user in team)
    }
  };

  const fetchSubmissionGET = async () => {
    /*
     * Function that handles GET team-aggregated API call.
     */
    const url = `${backendDomain}/api/v0.1/submissions/team-aggregated`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        // if api returns an error response
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
        return;
      } else {
        // api returns response successfully
        // await read the body stream response.json (Promise)
        const response_data = await response.json();

        loadSelectedToState(response_data); // store selected records to state
        setAllRowsCached(response_data);
        setRows(response_data);
        // console.log("rows --->", rows);
        return;

        // toast.success("Submissions updated successfully.");
      }
    } catch (error) {
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong loading your submissions.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11023") {
        // user is not a partcipant error
        toast.error(
          "Error: Submissions failed to load, you are not a participant."
        );
      } else if (parsed_error["error code"] === "AUTH-11022") {
        // user is not in team error
        toast.error(
          "Error: Submissions failed to load, you are not in a team."
        );
      } else if (parsed_error["error code"] === "AUTH-11028") {
        // user's account has been disabled error
        toast.error(
          "Error: Submissions failed to load, your account has been disabled."
        );
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // user's jwt token signature has expired
        // dispatch(logOut());
        toast.error(
          "Error: Submissions failed to load, token signature has expired. Please re-login."
        );
      } else if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid
        // dispatch(logOut());
        toast.error(
          "Error: Submissions failed to load, invalid token. Please re-login."
        );
      } else {
        toast.error(`Error: Something went wrong loading your submissions.`);
      }
    } finally {
      setLoading(false);
    }
  };

  const getSubmissionPhase = async () => {
    /*
     * Function that handles GET /submission-phase API call.
     */
    let url = `${backendDomain}/api/v0.1/submissions/submission-phase`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const res = await response.json();

        setSubmissionPhaseFlag(res.setting_value);
        return;
        // console.log(res);
      } else {
        // if api returns an error response
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
        return;
      }
    } catch (error) {
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log(error);
      if (parsed_error["error code"] === "AUTH-11021") {
        // user's jwt token signature has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      }
    }
  };

  const getAllQuotasOfTeam = async () => {
    /*
     * Function that handles GET all-quotas-of-team API call.
     */
    let url = `${backendDomain}/api/v0.1/submissions/all-quotas-of-team`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();
        // console.log(response_data);
        setAllQuotasOfTeam(response_data);
      } else {
        // if api returns an error response
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      }
    } catch (error) {
      // console.log(error);
      /* 
        1. AUTH-11002: Invalid token
        2. AUTH-11022: Not in a team
        3. AUTH-11028: User is Disabled
        4. SUBM-22008: Server has encountered an error. Please look for an admin
        5. SUBM-22005: Something went wrong, Please check with admin about the quota
      */
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error(
          "Error: Something went wrong loading your team's submission all quotas count.",
          {
            id: "json_parse_error",
          }
        );
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user is not a partcipant error
        toast.error(
          "Error: Submissions all quotas count failed to load, invalid token. Please re-login."
        );
      } else if (parsed_error["error code"] === "AUTH-11022") {
        // user is not in team error
        toast.error(
          "Error: Submission all quotas count failed to load, you are not in a team."
        );
      } else if (parsed_error["error code"] === "AUTH-11028") {
        // user's account has been disabled error
        toast.error(
          "Error: Submission all quotas count failed to load, your account has been disabled."
        );
      } else {
        toast.error(
          `Error: Something went wrong loading your team's submission all quotas count.`
        );
      }
    }
  };

  const fetchTeamProfile = async () => {
    /*
     * Function that handles GET team-profile API call.
     */
    const url = `${backendDomain}/api/v0.1/team/team-profile`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        // if api returns an error response
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      } else {
        // api returns response successfully
        // await read the body stream response.json (Promise)
        const response_data = await response.json();
        setTeamDt(response_data);
        // toast.success("Team profile retrieved successfully.")
        return;
      }
    } catch (error) {
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong loading your submissions.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11022") {
        // user is not in team error
        toast.error(
          "Error: Submissions failed to load, you are not in a team."
        );
      } else if (parsed_error["error code"] === "AUTH-11028") {
        // user's account has been disabled error
        toast.error(
          "Error: Submissions failed to load, your account has been disabled."
        );
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // TODO: log user out instead if token signature has expired
        // user's jwt token signature has expired
        toast.error(
          "Error: Submissions failed to load, token signature has expired. Please re-login."
        );
      } else if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid
        toast.error(
          "Error: Submissions failed to load, invalid token. Please re-login."
        );
      } else {
        toast.error(`Error: Something went wrong loading your submissions.`);
      }
    }
  };

  // get token
  const token = localStorage.getItem("token");

  useEffect(() => {
    // block apis from loading if user is not logged in
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      localStorage.setItem("historyPath", "1");

      dispatch(logOut());
      navigate("/login");
      return;
    }

    getUserProfile(); // checks if user is in team via an api call
    getSubmissionPhase(); // checks the challenge's submission phase via an api call
  }, []);

  useEffect(() => {
    // requires user to be in a team to proceed
    if (isUserInTeam) {
      fetchTeamProfile();
      fetchSubmissionGET();
      getAllQuotasOfTeam();
    }
  }, [isUserInTeam]);

  if (
    localStorage.getItem("token") === null ||
    localStorage.getItem("token") === ""
  ) {
    localStorage.setItem("historyPath", "1");
    dispatch(logOut());
    return <Navigate to="/login" />;
  }

  // TODO: WIP - track user changing pages during upload
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (submissionImageUploading) {
  //       const message =
  //         "You have an ongoing file upload. Are you sure you want to leave?";
  //       event.returnValue = message;
  //       return message;
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [submissionImageUploading]);

  const titleHandler = (event) => {
    /*
     * Function to handle upload submission title text box.
     */
    setUserInputSubmissionTitle(event.target.value);
  };

  const commentHandler = (event) => {
    /*
     * Function to handle upload submission comment text box.
     */
    setUserInputSubmissionComment(event.target.value);
    setCommentCharCount(event.target.value.length);
  };

  const checkSelectedLimitExceeded = () => {
    /*
     * Function that checks whether user selected rows has exceeded the limit allowed.
     */
    return selected.size > process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS;
  };

  function EnhancedTableHead(props) {
    /*
     * Function that checks renders the column headers of the submission table.
     */
    return (
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          {/* <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "15%",
              padding: "6px 12px",
            }}
          >
            <TableHead>Harmonic Mean (ASR)</TableHead>
          </TableCell> */}

          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "15%",
              padding: "6px 12px",
            }}
          >
            <TableHead>Vicuna-7b-v1.5 (Score)</TableHead>
          </TableCell>

          {/* <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "15%",
              padding: "6px 12px",
            }}
          >
            <TableHead>Llama-2-7b-chat-hf (ASR)</TableHead>
          </TableCell> */}

          {/* <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "15%",
              padding: "6px 12px",
            }}
          >
            <TableHead>Vicuna-7B (ASR)</TableHead>
          </TableCell> */}

          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "10%",
              padding: "6px 12px",
            }}
          >
            <TableHead>Who</TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "35%",
              padding: "6px 12px",
            }}
          >
            <TableHead>Details</TableHead>
          </TableCell>
          {/* COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD. */}
          {/* <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "10%",
              padding: "6px 12px",
              whiteSpace: "normal", // Allows wrapping
              wordBreak: "keep-all", // Prevents word breaking
              overflowWrap: "break-word", // Breaks long words at normal break points (spaces)
            }}
            padding="checkbox"
          >
            Private Ranking Selection
          </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    // tableRef: PropTypes.any,
  };

  // ##################################################################################################
  // START -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
  // ##################################################################################################

  // function EnhancedTableToolbar(props) {
  //   /*
  //    * Function that handles rendering of the toolbar at the bottom of the screen,
  //    * which allows user to submit their selected submissions for final scoring.
  //    *
  //    * This toolbar will not render if the submission phase is disabled,
  //    * or if the team has no submissions.
  //    */
  //   const { numSelected, tableRef } = props;
  //   return (
  //     rows?.num_aggregated_response_returned > 0 &&
  //     submissionPhaseFlag === "True" && (
  //       <Toolbar
  //         style={{
  //           // Add your custom styles using the style prop
  //           backgroundColor: "#f2f2f2",
  //           padding: "15px",
  //           position: "fixed",
  //           bottom: 0,
  //           left: 0,
  //           zIndex: 3,
  //           width: "100%",
  //           height: "5%",
  //           boxShadow: "0px -1px 5px 0px rgba(0, 0, 0, 0.1)",
  //           opacity: 1,
  //         }}
  //       >
  //         <Typography
  //           sx={{ flex: "1 1 20%", textAlign: "left" }}
  //           variant="h6"
  //           id="tableTitle"
  //           component="div"
  //         >
  //           <b>Select Submissions</b>
  //         </Typography>
  //         <Typography
  //           sx={{ flex: "1 1 100%" }}
  //           // color="inherit"
  //           color={checkSelectedLimitExceeded() ? "red" : "green"}
  //           variant="subtitle1"
  //           component="div"
  //         >
  //           {numSelected} of{" "}
  //           {process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS} selected{" "}
  //         </Typography>
  //         {hasUnsavedChanges && (
  //           <Typography sx={{ color: "red", marginRight: "1%" }}>
  //             You have unsaved changes!
  //           </Typography>
  //         )}

  //         <Tooltip title="Submit K Submissions">
  //           <span>
  //             <button
  //               className={
  //                 checkSelectedLimitExceeded()
  //                   ? "submission-k-button-disabled"
  //                   : "submission-k-button"
  //               }
  //               testId="submit-k-submissions"
  //               onClick={(event) => handleKSubmit(event)}
  //               disabled={checkSelectedLimitExceeded()} // disable button if selected > limit
  //             >
  //               Submit
  //             </button>
  //           </span>
  //         </Tooltip>
  //       </Toolbar>
  //     )
  //   );
  // }

  // EnhancedTableToolbar.propTypes = {
  //   numSelected: PropTypes.number.isRequired,
  // };
  // ##################################################################################################
  // END -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
  // ##################################################################################################
  const handleSetFilterChange = (filterValues, keyToCheck) => {
    /*
     * Function that handles the logic to apply the user selected filter to the submissions records,
     * and render the filtered submissions on the submissions page.
     */
    if (filterValues && keyToCheck) {
      setTableActiveFilters(filterValues);
      let filteredRows = allRowsCached?.aggregated_response;

      // apply filters to submissions
      filteredRows = filteredRows.filter((record) =>
        filterValues.some((filterValue) =>
          record[keyToCheck].toLowerCase().includes(filterValue.toLowerCase())
        )
      );

      // update state
      setRows((prevData) => ({
        ...prevData,
        aggregated_response: filteredRows,
      }));
      // console.log(rows);
    }
    return;
  };

  function TableControlsToolbar(props) {
    /*
     * Function to render the Table Controls (i.e. filter buttons),
     * for users to filter the records in the submission table.
     */
    return (
      rows?.num_aggregated_response_returned > 0 && (
        <Toolbar
          // Table header
          sx={{
            p: { xs: 1 },
            pl: { xs: 2 },
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            ...{
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main
                  // theme.palette.action.activatedOpacity
                ),
            },
          }}
        >
          {/* Submissions Table Title */}
          <div style={{ marginBottom: 4 }}>
            <Typography
              sx={{ flex: "1 1 20%", textAlign: "left" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <b>Submissions</b>
            </Typography>
          </div>

          {/* Submissions Table Filter Buttons */}
          <div>
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="outline-dark"
                onClick={() => handleSetFilterChange([""], "aggregated_status")}
                active={tableActiveFilters[0] === ""}
              >
                All
              </Button>

              <Button
                variant="outline-dark"
                onClick={() =>
                  handleSetFilterChange(["Completed"], "aggregated_status")
                }
                active={tableActiveFilters[0] === "Completed"}
              >
                Successful
              </Button>
              <Button
                variant="outline-dark"
                onClick={() =>
                  handleSetFilterChange(
                    ["Running", "In Queue"],
                    "aggregated_status"
                  )
                }
                active={tableActiveFilters[0] === "Running"}
              >
                In Progress
              </Button>
              <Button
                variant="outline-dark"
                onClick={() =>
                  handleSetFilterChange(["Error"], "aggregated_status")
                }
                active={tableActiveFilters[0] === "Error"}
              >
                Errors
              </Button>
            </Stack>
          </div>
        </Toolbar>
      )
    );
  }

  // ##################################################################################################
  // START -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
  // ##################################################################################################
  const bothSetsAreEqual = (xs, ys) =>
    /*
     * Function that checks if 2 sets are equal and return a boolean value accordingly.
     */
    xs.size === ys.size && [...xs].every((x) => ys.has(x));

  // const handleRowClick = (
  //   event,
  //   result_id,
  //   // asr_hmean_public,
  //   // asr_llama
  //   // asr_vicuna
  //   process.env.REACT_APP_PUBLIC_2
  // ) => {
  //   /*
  //    * Function that handles the logic when a row in the submissions table is clicked.
  //    * Updates "selected" state based on checked values.
  //    */
  //   if (
  //     result_id &&
  //     process.env.REACT_APP_PUBLIC_2 &&
  //     // asr_hmean_public &&
  //     // asr_llama &&
  //     // asr_vicuna &&
  //     selected.size !== process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS
  //   ) {
  //     const isAlreadySelected = selected.has(result_id);
  //     let newSelected = new Set();

  //     if (!isAlreadySelected) {
  //       // if result_id not in selected state, add it
  //       newSelected = new Set([...selected, result_id]);
  //     } else {
  //       // else, remove it
  //       newSelected = new Set([...selected]);
  //       newSelected.delete(result_id);
  //     }
  //     setHasUnsavedChanges(!bothSetsAreEqual(newSelected, selectedCached));
  //     setSelected(newSelected);
  //   }
  // };
  // ##################################################################################################
  // END -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
  // ##################################################################################################

  // // get token
  // const token = localStorage.getItem("token");

  // useEffect(() => {
  //   if (token === null || token === "") {
  //     localStorage.setItem("historyPath", "1");
  //     navigate("/login");
  //   }
  // }, []);

  // if (token === null || token === "") {
  //   localStorage.setItem("historyPath", "1");
  //   return <Navigate to="/login" />;
  // }

  const resetStatesOnFormRemoval = () => {
    /*
     * Function that handles resetting of states when a user removes their submission,
     * when uploading to the presigned url.
     */
    setSelectedFile(null);
    setIsFilePicked(false);
    setIsSubmissionCreated(false);
    toast.error("Not uploaded");
  };

  const resetStates = () => {
    /*
     * Function that reset states related to the submission form.
     */
    setUploadProgressPct(0); // reset uploadProgressPct state
    setIsSubmissionCreated(false); // reset state to false
    setSubmissionImageUploading(false);
    setShowUploadSubmissionConfirmationModal(false);
    setUserInputSubmissionTitle("");
    setUserInputSubmissionComment("");
    setIsFilePicked(false);
    setSelectedFile(null); // reset file
    setPreSignedURL(null);
    setMultipartPresignedURLs(null);
    setAbortControllerState(null);
    setMultipartUploadAbortController(new AbortController());
    setMultipartUploadProgressTracker(Array(25).fill(0));
  };

  const onFileSelect = (event) => {
    /*
     * Function that handles validation logic of the submission file (e.g. in tar.gz format),
     * after a submission file has been chosen by the user.
     */
    event.preventDefault();

    if (
      teamProfile === null ||
      teamProfile === false ||
      teamProfile === undefined
    ) {
      toast.error("Only users in a team can upload files.");
      return;
    }
    setSelectedFile(null);
    setUserInputSubmissionTitle("");
    setUserInputSubmissionComment("");

    const fileSelected = event.target.files[0];
    console.log(event.target.files);

    // ---------------------- vvv ONLY USED FOR .JSON FILE VALIDATION vvv ----------------------
    // // This is used to render a "loading" toast component, when checking for validating .json files. 
    // const fileValidationLoadingToastId = toast.loading(
    //   "Validating your selected file..."
    // ); // init loading toast


    // // check if user selected file is .json
    // if (fileSelected && fileSelected.type !== "application/json") {
    //   toast.error("You can upload .json files only.", {
    //     id: fileValidationLoadingToastId,
    //   });

    //   setIsFilePicked(false);
    //   setSelectedFile(null);
    //   reset();
    //   return;
    // }
    // ---------------------- ^^^ ONLY USED FOR .JSON FILE VALIDATION ^^^ ----------------------

    if (
      fileSelected &&
      !(
        fileSelected.name.endsWith(".tar.gz") ||
        fileSelected.type === "application/gzip" ||
        fileSelected.type === "application/x-gzip"
      )
    ) {
      toast.error("You can upload .tar.gz files only.");

      setIsFilePicked(false);
      setSelectedFile(null);
      reset();
      return;
    }

    // check if selected file is smaller than specified file lower limit
    if (fileSelected?.size < process.env.REACT_APP_SMALLEST_FILE_LIMIT) {
      // ---------------------- vvv ONLY USED FOR .JSON FILE VALIDATION vvv ----------------------
      // toast.error(
      //   `The size of your selected file is too small. Minimum size is ${bytesConverter(
      //     process.env.REACT_APP_SMALLEST_FILE_LIMIT
      //   )}. Please select another file.`,
      //   { id: fileValidationLoadingToastId }
      // );
      // ---------------------- ^^^ ONLY USED FOR .JSON FILE VALIDATION ^^^ ----------------------

      toast.error(
        `The size of your selected file is too small. Minimum size is ${bytesConverter(
          process.env.REACT_APP_SMALLEST_FILE_LIMIT
        )}. Please select another file.`
      );

      setIsFilePicked(false);
      setSelectedFile(null);
      reset();
      return;
    }

    // check if selected file is bigger than specified file lower limit
    if (fileSelected?.size > process.env.REACT_APP_FILE_LIMIT) {
      // ---------------------- vvv ONLY USED FOR .JSON FILE VALIDATION vvv ----------------------
      // toast.error(
      //   `The size of your selected file is too large. Maximum file size is ${bytesConverter(
      //     process.env.REACT_APP_FILE_LIMIT,
      //     "GB",
      //     0
      //   )}. Please select another file.`,
      //   { id: fileValidationLoadingToastId }
      // );
      // ---------------------- ^^^ ONLY USED FOR .JSON FILE VALIDATION ^^^ ----------------------

      toast.error(
        `The size of your selected file is too large. Maximum file size is ${bytesConverter(
          process.env.REACT_APP_FILE_LIMIT,
          "GB",
          0
        )}. Please select another file.`
      );

      setIsFilePicked(false);
      setSelectedFile(null);
      reset();
      return;
    }
    // ---------------------- vvv ONLY USED FOR .JSON FILE VALIDATION vvv ----------------------
    // const invalidJsonParseMsg = "Invalid JSON content in selected file.";
    // const reader = new FileReader();
    // reader.onload = (e) => {
    //   try {
    //     // Check if the file contents is just a valid .json object
    //     if (!checkValidJsonParse(e.target.result)) {
    //       // console.log("Invalid JSON content.");
    //       throw new Error(invalidJsonParseMsg);
    //     }
    //     const jsonContent = JSON.parse(e.target.result); // get JSON object from the file contents

    //     // Validate the submission to check that it adheres to the Submission Template
    //     const [isFileValid, isFileValidMessage] =
    //       JsonSubmissionFileValidation(jsonContent);

    //     // console.log(isFileValid, isFileValidMessage);

    //     // if the json file is invalid, render toast error message
    //     if (!isFileValid) {
    //       toast.error(
    //         `${isFileValidMessage} Please refer to Submission Guide.`,
    //         {
    //           id: fileValidationLoadingToastId,
    //         }
    //       );
    //       setIsFilePicked(false);
    //       setSelectedFile(null);
    //       reset();
    //       return;
    //     }
    //     toast.success("File validation passed.", {
    //       id: fileValidationLoadingToastId,
    //     });
    //   } catch (error) {
    //     // console.log(error)
    //     if (error.message === invalidJsonParseMsg) {
    //       toast.error(`${error.message} Please refer to Submission Guide.`, {
    //         id: fileValidationLoadingToastId,
    //       });
    //     } else {
    //       toast.error(
    //         `Submission file validation failed. Please refer to Submission Guide.`,
    //         {
    //           id: fileValidationLoadingToastId,
    //         }
    //       );
    //     }

    //     setIsFilePicked(false);
    //     setSelectedFile(null);
    //     reset();
    //   }
    // };
    // reader.readAsText(fileSelected); // read contents of user's selected .json file
    // ---------------------- ^^^ ONLY USED FOR .JSON FILE VALIDATION ^^^ ----------------------

    schema // validate file with yup
      .validate({ file_select: fileSelected })
      .then(() => {
        setSelectedFile(fileSelected); // set to state
        setIsFilePicked(true);
        setValidateError(null);
      })
      .catch((err) => {
        setValidateError(err.errors[0]);
        setIsFilePicked(false);
        setSelectedFile(null);

        reset();
      });
  };

  const cancelSubmission = (event) => {
    /*
     * Function that handles resetting of states when a new submission is cancelled.
     */
    event.preventDefault();
    resetStates();
  };

  const multipartUploadAbort = async (bucket, key, uploadId) => {
    /*
     * Function to call multipart upload complete
     */

    try {
      const body = JSON.stringify({
        bucket: bucket,
        key: key,
        uploadId: uploadId,
      });

      const response = await fetch(
        `${backendDomain}/api/v0.1/submissions/multipart-abort`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: body,
        }
      );

      if (!response.ok) {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      }

      const response_data = await response.json();

      console.log("Multipart Upload Aborted");

      resetStates(); // reset states
      fetchSubmissionGET(); // update page by calling get submissions again
      getAllQuotasOfTeam(); // update submission count state by calling get all quotas again
      return response_data;
    } catch (error) {
      console.error("Error Aborting Multipart Upload");

      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Upload is unsuccessful, please try again.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log("multipartUploadAbort - parsed_error", parsed_error)
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Upload is unsuccessful, please try again.", {
          id: parsed_error["error code"],
        });
      }
    }
  };

  const multipartUploadCompleted = async (parts, bucket, key, uploadId) => {
    /*
     * Function to call multipart upload complete API
     */

    try {
      const body = JSON.stringify({
        bucket: bucket,
        key: key,
        uploadId: uploadId,
        parts: parts,
      });

      const response = await fetch(
        `${backendDomain}/api/v0.1/submissions/multipart-complete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: body,
        }
      );

      if (!response.ok) {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      }

      const response_data = await response.json();
      console.log("Multipart Upload Completed");

      resetStates(); // reset states
      fetchSubmissionGET(); // update page by calling get submissions again
      getAllQuotasOfTeam(); // update submission count state by calling get all quotas again

      return response_data;
    } catch (error) {
      console.error("Error Completing Multipart Upload");

      // call abort api to tell s3 bucket that the object is useless
      const mpuAbortResponse = await multipartUploadAbort(
        bucket,
        key,
        uploadId
      );

      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Upload is unsuccessful, please try again.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log("multipartUploadCompleted - parsed_error", parsed_error)
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Upload is unsuccessful, please try again.", {
          id: parsed_error["error code"],
        });
      }

      return;
    }
  };

  const multipartUploadProgressBarHandler = (
    arrayOfUploadProgress,
    totalSizeOfSubmissionFile
  ) => {
    /*
     * Function to calculate a progress bar percentage based on the upload progress of all multipart upload.
     */

    // sums up all the upload bytes of each part in the list
    const totalUploaded = arrayOfUploadProgress.reduce(
      (total, curr) => total + curr,
      0
    );
    // calculates a percentage of bytes uploaded
    const uploadPct = Math.floor(
      (totalUploaded / totalSizeOfSubmissionFile) * 100
    );

    return uploadPct;
  };

  const asyncMultipartUploadToPresignedURLs = async (
    arrayOfReponses,
    files,
    bucket,
    key,
    uploadId
  ) => {
    /*
     * Function to upload that take a list of files and upload to presigned urls
     */
    try {
      setSubmissionImageUploading(true);

      // init axios client then add axiosRetry to it
      const axiosSubmissionClientWithRetry = axios.create();
      axiosRetry(axiosSubmissionClientWithRetry, {
        retries: 3, // Number of retries
        retryDelay: axiosRetry.exponentialDelay, // Use exponential backoff delay between retries
        retryCondition: (error) => {
          // Retry on network errors or 5xx status codes
          return (
            axiosRetry.isNetworkError(error) ||
            axiosRetry.isRetryableError(error)
          );
        },
      });

      const uploadPromises = arrayOfReponses.map(
        async (responseObject, index) => {
          const abortSignal = multipartUploadAbortController.signal;
          const file = files[index];
          // upload to presigned url
          const response = await axiosSubmissionClientWithRetry.put(
            responseObject.url,
            file,
            {
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress: (progressEvent) => {
                // update tracker array state with latest loaded byte value based on file index
                setMultipartUploadProgressTracker((prev) => {
                  let newTrackerArray = [...prev];
                  newTrackerArray[index] = progressEvent.loaded;
                  return newTrackerArray;
                });
              },
              signal: abortSignal,
            }
          );
          return response;
        }
      );

      // runs all the axios call in parallel (async)
      // throw new Error("test abort at multipart upload fail")
      const responses = await Promise.all(uploadPromises);
      // console.log("All files uploaded successfully:", responses);

      // iterate through responses (responses will be in same order of arrayOfResponses)
      const parts = [];
      responses.map((response) => {
        // const urlParams = new URLSearchParams(response.url); // convert to URLSearchParams to get "partNumber" from url query params
        const urlParams = new URLSearchParams(response.request.responseURL); // convert to URLSearchParams to get "partNumber" from url query params
        parts.push({
          ETag: response.headers.get("etag").slice(1, -1), // get etag from response headers, .slice(1,-1) to remove quotation marks from etag
          PartNumber: urlParams.get("partNumber"), // get "partNumber" from url query params
        });
      });

      // trigger multipart upload completed
      const mpuCompleteResponse = await multipartUploadCompleted(
        parts,
        bucket,
        key,
        uploadId
      );

      if (mpuCompleteResponse?.status === "success") {
        // only show this toast when MPU Complete response returns 'success'
        toast.success("Successfully Uploaded");
      }
      setSubmissionImageUploading(false);

      return responses;
    } catch (error) {
      console.error("Error uploading files");
      if (axios.isCancel(error)) {
        // if error caused by upload that is cancelled manually
        toast.error(`Upload has been cancelled manually.`);
      } else {
        toast.error("Upload is unsuccessful, please try again.");
      }

      const mpuAbortResponse = await multipartUploadAbort(
        bucket,
        key,
        uploadId
      ); // run abort when fail

      return;
    }
  };

  // For handling create submission & get pre-signed URL
  const onCreateSubmissionGetPreSignedUrl = async () => {
    /*
     * Function that runs when participant create their submission record with title and comments,
     * by calling the POST submissions API.
     */
    // event.preventDefault();

    // count total parts
    const partSize = 1024 * 1024 * 1024; // TODO: put the size of each part as a env variable
    const numberOfUploadParts = parseInt(
      Math.ceil(selectedFile.size / partSize)
    );
    const binaryFileParts = [];

    // Partition the file into parts
    for (let i = 0; i < numberOfUploadParts; i++) {
      const start = i * partSize;
      const end = Math.min((i + 1) * partSize, selectedFile.size);
      const part = selectedFile.slice(start, end);
      binaryFileParts.push(part);
    }

    const raw = JSON.stringify({
      total_parts: numberOfUploadParts,
      submit_filename: selectedFile.name,
      submit_title:
        userInputSubmissionTitle.length === 0 ? "" : userInputSubmissionTitle,
      submit_comments:
        userInputSubmissionComment.length === 0
          ? ""
          : userInputSubmissionComment,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
      redirect: "follow",
    };

    try {
      // call POST submissions api
      const response = await fetch(
        `${backendDomain}/api/v0.1/submissions/multipart`,
        requestOptions
      );

      // if POST submissions api returns an error
      if (!response.ok) {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
        return;
      }

      // get presigned url from POST submissions api response and upload submission to presigned url
      const createResponse = await response.json();
      // createResponse[response]: {url: "xxx", fields: {key:..., AWSAccessKeyId: ..., policy: ..., signature: ... }}
      // setPreSignedURL(createResponse["response"]); // store non-multipart presigned url details
      setMultipartPresignedURLs(createResponse["response"]); // stores a list of objects
      setIsSubmissionCreated(true);

      // upload to presigned url bucket (non-multipart upload)
      // onFileUpload(createResponse["response"]);

      // upload to presigned url bucket (multipart upload)
      // Call the upload function with the cancel callback
      asyncMultipartUploadToPresignedURLs(
        createResponse["response"],
        binaryFileParts,
        createResponse["bucket"],
        createResponse["key"],
        createResponse["uploadId"]
      );
    } catch (error) {
      /* 
      Raises: \n
        HTTPException:
          1. SUBM-11009: Submission phase is over
          2. SUBM-22007: Upload has failed due to unforseen circumstances
          3. SUBM-11006: Cannot submit. Max attempts reached.
          4. SUBM-22002: Creation of submission failed
          5. AUTH-11002: Invalid token
          6. AUTH-11022: Not in a team
          7. AUTH-11028: User is Disabled
          8. SUBM-22005: Something went wrong, Please check with admin about the quota
          9. SUBM-22008: Server has encountered an error. Please look for an admin
       */
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error(
          `Error: Something went wrong when uploading your submission.`,
          {
            id: "json_parse_error",
          }
        );
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log(parsed_error);

      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "SUBM-11009") {
        toast.error(
          "Error: Submission failed to upload as the submission phase is already over."
        );
        setSubmissionPhaseFlag(null);
        setIsFilePicked(false);
        setIsSubmissionCreated(false);
      } else if (parsed_error["error code"] === "SUBM-12002") {
        toast.error(
          "Error: Submission not allowed as you already have a submission that is still in progress."
        );
      } else if (parsed_error["error code"] === "SUBM-22007") {
        toast.error(
          "Error: Something went wrong when uploading your submission."
        );
      } else if (parsed_error["error code"] === "SUBM-11006") {
        toast.error(
          "Error: You cannot upload any more submissions as you have exceeded the allowed submission quota."
        );
      } else if (parsed_error["error code"] === "SUBM-22002") {
        toast.error(
          "Error: Something went wrong when creating your submission."
        );
      } else if (parsed_error["error code"] === "AUTH-11002") {
        // TODO: log user out instead if token signature has expired
        toast.error(
          "Error: Submission failed to upload, invalid token. Please re-login."
        );
      } else if (parsed_error["error code"] === "AUTH-11028") {
        toast.error(
          "Error: Submission failed to load as your account has been disabled. Please contact an admin about this issue."
        );
      } else if (parsed_error["error code"] === "SUBM-22005") {
        toast.error("Error: Something went wrong with the server.");
      } else if (parsed_error["error code"] === "SUBM-22008") {
        toast.error("Error: Something went wrong with the server.");
      } else {
        toast.error(
          `Error: Something went wrong when uploading your submission.`
        );
      }
    }
  };

  // const doMultipartUploadResponse = async (url, body) => {
  //   /*
  //    * Function that handles uploading the submission image to the presigned URL from the FE.
  //    */
  //   try {
  //     const abortController = new AbortController(); // for aborting the upload
  //     setAbortControllerState(abortController);
  //     const abortSignal = abortController.signal;
  //     const response = await axios.post(url, body, {
  //       onUploadProgress: (progressEvent) => {
  //         const loadingProgressPct = Math.floor(
  //           (progressEvent.loaded / progressEvent.total) * 100
  //         );
  //         setUploadProgressPct(loadingProgressPct);
  //         if (loadingProgressPct <= 100 && loadingProgressPct % 10 === 0) {
  //         }
  //       },
  //       signal: abortSignal, // set abort signal to api call
  //     });
  //     if (response) {
  //       // TODO: everything multipart: add multipart complete connection
  //       resetStates(); // reset states

  //       // on successful response
  //       if (response.status >= 200 && response.status < 300) {
  //         toast.success("Successfully Uploaded");
  //         fetchSubmissionGET(); // update page by calling get submissions again
  //         // TODO: update submission count state without updating api
  //         getAllQuotasOfTeam(); // update submission count state by calling get all quotas again
  //       }

  //       return;
  //     }
  //   } catch (err) {
  //     // TODO: everything multipart: add abort multipart connection
  //     resetStates(); // reset states
  //     // console.log(err);
  //     fetchSubmissionGET(); // update page by calling get submissions again
  //     getAllQuotasOfTeam(); // update submission count state by calling get all quotas again
  //     if (axios.isCancel(err)) {
  //       // if error caused by upload that is cancelled manually
  //       toast.error(`Upload has been cancelled manually.`);
  //     } else {
  //       // if error caused by other reasons
  //       toast.error("Upload is unsuccessful, please try again.");
  //       if (err.response) {
  //         // server responded with a status code
  //         console.log(err.response.status);
  //       } else if (err.request) {
  //         // request was made but no response received
  //         console.log(err.request);
  //       } else {
  //         // error occurred before the request was made
  //         console.log(err.message);
  //       }
  //     }
  //   }
  //   return;
  // };

  const doUploadResponse = async (url, body) => {
    /*
     * Function that handles uploading the submission image to the presigned URL from the FE (non-multipart upload).
     */
    try {
      const abortController = new AbortController(); // for aborting the upload
      setAbortControllerState(abortController);
      const abortSignal = abortController.signal;
      const response = await axios.post(url, body, {
        onUploadProgress: (progressEvent) => {
          const loadingProgressPct = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgressPct(loadingProgressPct);
          if (loadingProgressPct <= 100 && loadingProgressPct % 10 === 0) {
          }
        },
        signal: abortSignal, // set abort signal to api call
      });
      if (response) {
        resetStates(); // reset states

        // on successful response
        if (response.status >= 200 && response.status < 300) {
          toast.success("Successfully Uploaded");
          fetchSubmissionGET(); // update page by calling get submissions again
          getAllQuotasOfTeam(); // update submission count state by calling get all quotas again
        }

        return;
      }
    } catch (err) {
      resetStates(); // reset states
      // console.log(err);
      fetchSubmissionGET(); // update page by calling get submissions again
      getAllQuotasOfTeam(); // update submission count state by calling get all quotas again
      if (axios.isCancel(err)) {
        // if error caused by upload that is cancelled manually
        toast.error(`Upload has been cancelled manually.`);
      } else {
        // if error caused by other reasons
        toast.error("Upload is unsuccessful, please try again.");
        if (err.response) {
          // server responded with a status code
          console.log(err.response.status);
        } else if (err.request) {
          // request was made but no response received
          console.log(err.request);
        } else {
          // error occurred before the request was made
          console.log(err.message);
        }
      }
    }
    return;
  };

  const onCancelUploadClick = () => {
    /*
     * Function that handles stopping image upload by aborting the axios call. (non-multipart upload)
     */
    if (abortControllerState) {
      abortControllerState.abort(); // also resets state as defined in doUploadResponse() function
    }
  };

  const onCancelMultipartUploadClick = () => {
    /*
     * Function that handles stopping image upload by aborting the axios call. (multipart upload)
     */
    if (multipartUploadAbortController) {
      multipartUploadAbortController.abort();
    }
  };

  // accepts .tar, .gz, For handling file is upload (after creating submission & getting pre-signed URL)
  const onFileUpload = async (preSignedUrlObject) => {
    /*
     * Function that handles uploading submission image to presigned URL from the FE,
     * when user clicks the "Upload" button (non-multipart upload).
     */

    // Set state uploading = true
    setSubmissionImageUploading(true);

    // format request for upload to bucket
    let formData = new FormData();
    for (let key in preSignedUrlObject.fields) {
      formData.append(key, preSignedUrlObject.fields[key]);
    }
    formData.append("file", selectedFile);

    // call upload to presignedurl
    // doUploadResponse(preSignedUrl["url"], formData);
    doUploadResponse(preSignedUrlObject["url"], formData);
  };

  // ##################################################################################################
  // START -- (K SUBMIT) COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
  // ##################################################################################################
  const handleStatesOnKSubmit = async () => {
    /*
     * Function that handles logic when any of the K submit buttons are pressed.
     */
    setShowKSubmitConfirmationModal(false); // hide confirmation modal
    // setHasUnsavedChanges(false); // reset state to have no unsaved changes
    patchKSubmissions(); // send the user selected submissions to the PATCH api
    fetchSubmissionGET(); // call the updated submissions records again
  };

  const handleKSubmit = async (event) => {
    /*
     * Function that handles what happens when the K submit button is pressed.
     * This will determine whether to show the confirmation modal or not.
     */
    event.preventDefault();
    if (selected.size < process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS) {
      // render confirmation modal if num of selected submissions < K
      setShowKSubmitConfirmationModal(true);
    } else {
      // don't render confirmation modal and submit
      // setShowKSubmitConfirmationModal(false);
      // setHasUnsavedChanges(false);
      // patchKSubmissions();
      // fetchSubmissionGET();
      handleStatesOnKSubmit();
    }
  };
  // For PATCH K Submissions
  const patchKSubmissions = async () => {
    /*
     * Function that handles PATCH final-pick API call.
     */
    const datas = [];

    // iterate through set
    selected.forEach((result_id) => {
      datas.push({ result_id: result_id, final_pick: true });
    });

    const data = JSON.stringify({
      final_pick_result_ids: datas,
    });

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: data,
      // redirect: "follow",
    };

    try {
      const response = await fetch(
        `${backendDomain}/api/v0.1/result/final-pick`,
        requestOptions
      );

      if (!response.ok) {
        // if api returns an error response
        setHasUnsavedChanges(true); // set state to notify user there is unsaved changes
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      } else {
        // api returns response successfully
        toast.success("Your selected submissions are confirmed. ");
        setHasUnsavedChanges(false); // reset state to have no unsaved changes
      }
    } catch (error) {
      // console.log("final-pick api error:", error.message);
      const parsed_error = JSON.parse(error.message); // convert back to object

      if (parsed_error["error code"] === "AUTH-11021") {
        // user's jwt token signature has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11023") {
        // user is not a partcipant error
        toast.error("Error: You are not a participant.");
      } else if (parsed_error["error code"] === "AUTH-11022") {
        // user is not in team error
        toast.error("Error: You are not in a team.");
      } else if (parsed_error["error code"] === "AUTH-11028") {
        // user's account has been disabled error
        toast.error(
          "Error: Failed to select submissions, your account has been disabled."
        );
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // TODO: log user out instead if token signature has expired
        // user's jwt token signature has expired
        toast.error(
          "Error: Failed to select submissions, token signature has expired."
        );
      } else if (parsed_error["error code"] === "RSLT-11008") {
        // user has exceeded the limit of submissions to upload
        toast.error(
          "Error: You have exceeded the allowed upload limit for your selected submissions."
        );
      } else if (parsed_error["error code"] === "SUBM-11009") {
        toast.error(
          "Error: Failed to select submissions as the submission phase is already over."
        );
      } else if (
        ["RSLT-11004", "RSLT-11009", "RSLT-11005", "RSLT-11006"].includes(
          parsed_error["error code"]
        )
      ) {
        // user's uploaded invalid result_ids, can be because of these reasons:
        // - RSLT-11004: No result with the result_id(s) found.
        // - RSLT-11009: Requested result(s) can't be picked as it's excluded from quota.
        // - RSLT-11005: Unauthorized access, result(s) does not belong to your team.
        // - RSLT-11006: Result(s) can't be picked as they are not scored.
        toast.error("Error: Invalid result_id(s).");
      } else if (parsed_error["error code"] === "RSLT-22007") {
        // server error updating to db
        toast.error("Error: Server error updating your selected submissions.");
      } else {
        toast.error(`Error: Something went wrong loading your submissions.`);
      }
    }
  };

  const onKSubmitModalContinue = () => {
    /*
     * Function that handles logic on submitting the confirmation modal.
     */
    // setShowKSubmitConfirmationModal(false);
    // setHasUnsavedChanges(false);
    // patchKSubmissions();
    // fetchSubmissionGET();
    handleStatesOnKSubmit();
  };

  const onKSubmitModalCancel = () => {
    /*
    * Function that handles logic on cancelling/closing the confirmation modal.

     */
    setShowKSubmitConfirmationModal(false);
  };

  const kSubmitConfirmationModal = () => {
    /*
     * Function that handles the rendering of the confirmation modal for K submit feature.
     */
    return (
      <Modal
        open={showKSubmitConfirmationModal}
        onClose={onKSubmitModalCancel}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        // slotProps={{ timeout: 500 }}
      >
        <Fade in={showKSubmitConfirmationModal}>
          <div className={"k-submit-confirmation-modal"}>
            <div className={"k-submit-modal-content"}>
              <div>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ marginBottom: "15px" }}
                >
                  You have chosen only {selected.size} out of{" "}
                  {process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS}{" "}
                  submissions. <br></br>
                  Are you sure you want to submit? <br></br>
                </Typography>
              </div>
              <Stack
                direction="horizontal"
                gap={5}
                style={{
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={onKSubmitModalCancel}
                  variant="danger"
                  style={{ width: "100px" }}
                >
                  No
                </Button>
                <Button
                  onClick={onKSubmitModalContinue}
                  variant="success"
                  style={{ width: "100px" }}
                >
                  Yes
                </Button>
              </Stack>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  };

  // DEPRECATED: not generating strikethrough for refunded submissions anymore
  // const getTableRowClassName = (row) => {
  //   /*
  //   * Function to get the className the submissions table's TableRow, to retrieve
  //   * the appropriate css class for the TableRow component, based on the data
  //   * within the row record.
  //   *
  //   * Currently, this will mainly be used to get the css class that creates a
  //   * diagonal strikethrough line, for refunded submissions.
  //   */

  //   if (row.Submissions.exclude_from_quota === true) {
  //     return "crossed-table-row"
  //   }

  //   return ""
  // }

  // ##################################################################################################
  // END -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
  // ##################################################################################################
  const renderTable = () => {
    /*
     * Function that handles the rendering of the submission table.
     */

    return (
      <Box sx={{ paddingBottom: "20px" }}>
        <Paper style={{ width: "100%" }}>
          {/*// ##################################################################################################
          // START -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
          // ################################################################################################## */}
          {/* <EnhancedTableToolbar numSelected={selected.size} /> */}

          <TableContainer style={{ overflowX: "initial" }}>
            {TableControlsToolbar()}
            {/* {kSubmitConfirmationModal()} */}

            <Paper>
              <Table aria-labelledby="tableTitle" size="small">
                <EnhancedTableHead
                  numSelected={selected.size}
                  rowCount={rows?.length}
                />
                <TableBody>
                  {rows?.num_aggregated_response_returned === 0 ? (
                    // if no submissions, render a component on the table to notify
                    // admin that this user doesn't have any submissions
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        This team does not have a submission yet.
                      </TableCell>
                    </TableRow>
                  ) : (
                    rows?.aggregated_response?.map((row, index) => {
                      const isItemSelected = selected.has(
                        row.Results?.result_id
                      );

                      const labelId = `enhanced-table-checkbox-${index}`;

                      // check if the record is a user error status

                      const isExecutorUserError = [
                        "User Submission Error",
                        "Input File Format Error",
                        "Input File Max Token Length Error",
                        "Load Docker Image Error",
                        "Docker Image TarGzip File Error",
                        "Time Limit Exceeded",
                        "Resource Limit Exceeded",
                        "Output Limit Exceeded",
                      ].includes(row.Submissions.submission_exec_status);

                      const isScorerUserError = [
                        "User Submission Error",
                        "Output Format Error",
                      ].includes(row.Results?.scoring_status);

                      const isImageUploadUserError = [
                        "Error - Missing Image",
                      ].includes(row.aggregated_status);
                      const isUserError =
                        row?.aggregated_status?.includes("Error") &&
                        (isExecutorUserError ||
                          isScorerUserError ||
                          isImageUploadUserError);

                      let userErrorMessage = ""; // initialize variable to store user error message
                      if (isUserError) {
                        // get specific user error status from row record and concat it to the errorLabel
                        let hasErrorPreviously = false; // to track if both executor and scorer has error

                        // if submission_exec_status is a type of User Error
                        if (isExecutorUserError) {
                          userErrorMessage +=
                            row.Submissions.submission_exec_status;
                          hasErrorPreviously = true;
                        }

                        // if scoring_status is a type of User Error
                        if (isScorerUserError) {
                          if (hasErrorPreviously) userErrorMessage += ", ";

                          userErrorMessage += row.Results?.scoring_status;
                        }

                        if (isImageUploadUserError) {
                          if (hasErrorPreviously) userErrorMessage += ", ";
                          userErrorMessage += "Submission Upload Failed";
                        }
                      }

                      return (
                        <TableRow
                          // className={getTableRowClassName(row)}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.Submissions.submission_id}
                          selected={isItemSelected}
                          style={{
                            backgroundColor:
                              // row.Results?.final_pick === true ? "#e6f7e6" : "",
                              row.Submissions.exclude_from_quota
                                ? "#f2f2f2" // Gray background for refunded records
                                : row.Results?.final_pick === true
                                ? "#e6f7e6" // Green background for final pick
                                : "", // Default background color
                          }}
                        >
                          {/* Merge Score Columns and Display The Status If Score Is Empty */}

                          {/* STATUS/SCORE COLUMNS */}
                          {row?.aggregated_status === "Completed" ? (
                            <>
                              
                              {/* CUSTOM PUBLIC SCORE CELL */}
                              <TableCell
                                id={labelId}
                                sx={{
                                  width: "15%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* {row.Results?.[process.env.REACT_APP_PUBLIC_2]} */}
                                  {/* SCORING FOR GCSS PHASE 2. COMMENT OUT <TableBody> AFTER PHASE 2 */}
                                  <TableBody>
                                    <TableRow
                                      style={{backgroundColor:"#f4f4f4"}}>
                                    <TableCell>
                                      <div>
                                        {process.env.REACT_APP_PUBLIC_2}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        {row.Results?.[process.env.REACT_APP_PUBLIC_2]}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div>
                                        {process.env.REACT_APP_PUBLIC_0}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        {row.Results?.[process.env.REACT_APP_PUBLIC_0]}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div>
                                        {process.env.REACT_APP_PUBLIC_1}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div>
                                        {row.Results?.[process.env.REACT_APP_PUBLIC_1]}
                                      </div>
                                    </TableCell>
                                    </TableRow>
                                    </TableBody>
                                </div>
                              </TableCell>

                              {/* HMEAN PUBLIC CELL */}
                              {/* <TableCell
                                id={labelId}
                                // style={{ borderRight: "1px solid #EFEFEF" }}
                                sx={{
                                  width: "15%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {row.Results?.asr_hmean_public}
                                </div>
                              </TableCell> */}

                              {/* LLAMA CELL */}
                              {/* <TableCell
                                id={labelId}
                                sx={{
                                  width: "15%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {row.Results?.asr_llama}
                                </div>
                              </TableCell> */}

                              {/* VICUNA CELL */}
                              {/* <TableCell
                                id={labelId}
                                sx={{
                                  width: "15%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {row.Results?.asr_vicuna}
                                </div>
                              </TableCell> */}
                            </>
                          ) : (
                            <>
                              <TableCell colSpan={1} align="center">
                                {row?.aggregated_status?.includes(
                                  "Running"
                                ) && (
                                  <AggregatedStatusIcon
                                    statusType={"running"}
                                  />
                                )}

                                {row?.aggregated_status?.includes("Error") &&
                                  (isUserError ? (
                                    <>
                                      <AggregatedStatusIcon
                                        statusType={"userError"}
                                        // row={row}
                                        errorTooltipMessage={userErrorMessage}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <AggregatedStatusIcon
                                        statusType={"sysError"}
                                      />
                                    </>
                                  ))}

                                {row?.aggregated_status?.includes(
                                  "In Queue"
                                ) && (
                                  <AggregatedStatusIcon
                                    statusType={"inQueue"}
                                  />
                                )}
                              </TableCell>
                            </>
                          )}

                          {/* CREATED_BY / WHO COLUMN */}
                          <TableCell sx={{ width: "10%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "3px",
                              }}
                            >
                              {row.username}
                            </div>
                          </TableCell>

                          {/* SUBMISSIONS DETAILS COLUMN */}
                          <TableCell sx={{ width: "35%" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                width: 600,
                              }}
                            >
                              <div style={{ marginRight: "5px" }}>
                                <Tooltip
                                  title="Submission ID"
                                  slotProps={{
                                    // adjust distance of tooltip message from anchor
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, -14],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  <FingerprintIcon />
                                </Tooltip>{" "}
                                {row.Submissions.exclude_from_quota && (
                                  <span style={{ fontWeight: "bold" }}>
                                    (REFUNDED){" "}
                                  </span>
                                )}
                                {row?.Submissions?.submission_id}
                              </div>

                              <div>
                                <Tooltip
                                  title="Filename"
                                  slotProps={{
                                    // adjust distance of tooltip message from anchor
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, -14],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  <AttachFileIcon />
                                </Tooltip>{" "}
                                <b> {row.Submissions.submit_filename}</b>
                              </div>
                            </div>
                            <div
                              style={{
                                color: "grey",
                                marginRight: "5px",
                                marginTop: "7.5px",
                              }}
                            >
                              <Tooltip
                                title="Created Date"
                                slotProps={{
                                  // adjust distance of tooltip message from anchor
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -14],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              >
                                <EventIcon />
                              </Tooltip>{" "}
                              {datetimeConverter(row.Submissions.created_date)}
                            </div>
                            <div
                              style={{ marginRight: "5px", marginTop: "7.5px" }}
                            >
                              {row.Submissions.submit_title && (
                                <div>
                                  <p>
                                    <Tooltip
                                      title="Title"
                                      slotProps={{
                                        // adjust distance of tooltip message from anchor
                                        popper: {
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -14],
                                              },
                                            },
                                          ],
                                        },
                                      }}
                                    >
                                      <TopicIcon />
                                    </Tooltip>{" "}
                                    {row.Submissions.submit_title}
                                  </p>
                                </div>
                              )}
                              {row.Submissions.submit_comments && (
                                <div>
                                  <p>
                                    <Tooltip
                                      title="Comments"
                                      slotProps={{
                                        // adjust distance of tooltip message from anchor
                                        popper: {
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -14],
                                              },
                                            },
                                          ],
                                        },
                                      }}
                                    >
                                      <NotesIcon />
                                    </Tooltip>
                                    <br />
                                    {row.Submissions.submit_comments}
                                  </p>
                                </div>
                              )}
                              {isUserError && (
                                <div
                                  style={{
                                    backgroundColor: "#fdffd4",
                                    color: "#F05959",
                                    // display: "flex",
                                    // alignItems: "center",
                                    // height: "30px",
                                    fontWeight: "700",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    <Tooltip
                                      title="Error Message"
                                      slotProps={{
                                        // adjust distance of tooltip message from anchor
                                        popper: {
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -14],
                                              },
                                            },
                                          ],
                                        },
                                      }}
                                    >
                                      <AnnouncementRoundedIcon />
                                    </Tooltip>{" "}
                                    {userErrorMessage}
                                  </p>
                                </div>
                              )}
                            </div>
                          </TableCell>

                          {/*// ##################################################################################################
                            // START -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
                            // ################################################################################################## */}
                                {/* Only show final_pick checkbox when there's scores 
                                and it's an non-refunded submission record */}
                          {/* <TableCell
                            padding="checkbox"
                            sx={{
                              width: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ButtonBase
                                onClick={(event) =>
                                  handleRowClick(
                                    event,
                                    row.Results?.result_id,
                                    // row.Results?.score_public_auroc,
                                    // row.Results?.score_public_accuracy
                                    // row.Results?.asr_hmean_public,
                                    row.Results?.asr_llama
                                    // row.Results?.asr_vicuna
                                  )
                                }
                                disabled={
                                  submissionPhaseFlag !== "True" &&
                                  selected.size.toString() ===
                                    process.env
                                      .REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS &&
                                  !isItemSelected
                                }
                              >
                                {row.Results?.asr_llama &&
                                // row.Results?.asr_hmean_public &&
                                // row.Results?.asr_vicuna &&
                                row.Submissions.exclude_from_quota === false ? (
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                    disabled={submissionPhaseFlag !== "True"}
                                  />
                                ) : (
                                  <div>&mdash;</div>
                                )}
                              </ButtonBase>
                            </div>
                          </TableCell> */}
                          {/*// ##################################################################################################
                            // END -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
                            // ################################################################################################## */}

                          {/* <TableCell
                            padding="checkbox"
                            sx={{
                              width: "10%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {row.Results?.final_pick ? (
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  disabled
                                />
                              ) : (
                                <div>&mdash;</div>
                              )}
                            </div>
                          </TableCell> */}
                        </TableRow>
                      );
                    })
                  )}

                  {rows?.num_aggregated_response_returned > 0 && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            // width: 620,
                          }}
                        >
                          End Of Table
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                {/*// ##################################################################################################
                  // START -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
                  // ################################################################################################## */}

                {rows?.num_aggregated_response_returned > 0 && (
                  <TableFooter
                    sx={{
                      pl: { sm: 2 },
                      pr: { xs: 1, sm: 1 },
                      height: "45px", // height px aligned with height of K submit toolbar
                      textAlign: "center",
                      ...{
                        bgcolor: (theme) =>
                          alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                          ),
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell colSpan={6}></TableCell>
                    </TableRow>
                  </TableFooter>
                )}

                {/*// ##################################################################################################
                  // END -- COMMENTED BUT NOT DELETED. PART OF K SUBMIT FEATURE THAT IS PUT ON HOLD.
                  // ################################################################################################## */}
              </Table>
            </Paper>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  // Functions to check whether team can submit
  const hasTeamExceededWindowQuota = (allQuotasOfTeam) => {
    /*
     * Function to check if team has exceeded quota within time window.
     */
    const remaining =
      allQuotasOfTeam["window_submission_quota"] -
      allQuotasOfTeam["window_submission_count"];
    const result = remaining < 1;
    return result;
  };

  const hasTeamExceededOverallQuota = (allQuotasOfTeam) => {
    /*
     * Function to check if team has exceeded overall quota.
     */
    const remaining =
      allQuotasOfTeam["total_submission_quota"] -
      allQuotasOfTeam["total_submission_count"];
    const result = remaining < 1;
    return result;
  };

  const SubmitFileForm = () => {
    /*
     * Function that handles rendering components related to allowing the participant
     * to choose their submission image.
     */

    if (submissionPhaseFlag !== "True") {
      // if submission phase is disabled
      return <div>{renderSubmissionPhaseDisabled("submissions_disabled")}</div>;
    } else if (!isUserInTeam) {
      return <div>{renderSubmissionPhaseDisabled("user_not_in_team")}</div>;
    }

    // check whether team has hit submission quota
    if (allQuotasOfTeam && hasTeamExceededOverallQuota(allQuotasOfTeam)) {
      // if team has exceeded the overall submission quota
      return (
        <div>{renderSubmissionPhaseDisabled("overall_quota_exceeded")}</div>
      );
    } else if (allQuotasOfTeam && hasTeamExceededWindowQuota(allQuotasOfTeam)) {
      // if team has exceeded the window's submission quota
      return (
        <div>
          {renderSubmissionPhaseDisabled(
            "window_quota_exceeded",
            allQuotasOfTeam["date_of_next_submission"]
          )}
        </div>
      );
    }

    // if the checks above has been passed^
    return !isFilePicked ? (
      <div>{renderFileNotPicked()}</div> // firstly, this is rendered when file hasnt been picked
    ) : (
      <div>{renderNewSubmission()}</div> // secondly, this is rendered when file has been picked
    );
  };

  // DEPRECATED: extra screen to upload to presigned url
  // const renderSubmissionDraft = () => {
  //   /*
  //    * Function that handles rendering of the upload to presigned url form.
  //    */
  //   return (
  //     // if submission created, show "Upload" view to allow user to upload their image to minio via presigned url
  //     <div className="sc-border">
  //       <div className="sc-pre-signed-select">
  //         <p>Title: {title}</p>
  //         <p>Comment: {comment}</p>
  //         <p>Filename: {selectedFile?.name}</p>
  //         <p>Size in bytes: {selectedFile?.size}</p>
  //         {/* <p>
  //           Last Modified Date: {console.log(selectedFile)}
  //           {console.log(selectedFile)}
  //           {selectedFile.lastModifiedDate
  //             ? {selectedFile.lastModifiedDate.toLocaleDateString()
  //             : ""}
  //         </p> */}
  //         {/* <p>Presigned URL generated: {preSignedUrl?.url}</p> */}
  //         {!submissionImageUploading && (
  //           <div style={{ marginBottom: "10px" }}>
  //             {" "}
  //             <Button variant="primary" type="submit" onClick={onFileUpload}>
  //               Upload
  //             </Button>{" "}
  //             <Button
  //               variant="secondary"
  //               type="button"
  //               onClick={resetStatesOnFormRemoval}
  //             >
  //               Cancel
  //             </Button>
  //           </div>
  //         )}
  //         {/* <div style={{ marginBottom: "10px" }}>
  //           {" "}
  //           <Button variant="primary" type="submit" onClick={onFileUpload}>
  //             Upload
  //           </Button>{" "}
  //           <Button
  //             variant="secondary"
  //             type="button"
  //             onClick={resetStatesOnFormRemoval}
  //           >
  //             Cancel
  //           </Button>
  //         </div> */}

  //         {submissionImageUploading > 0 ? (
  //           <>
  //             <ProgressBar
  //               now={uploadProgressPct}
  //               label={`${uploadProgressPct}%`}
  //             />

  //             <div style={{ marginTop: "10px" }}>
  //               <Button variant="danger" onClick={onCancelUploadClick}>
  //                 Stop Upload
  //               </Button>
  //             </div>
  //           </>
  //         ) : (
  //           <></>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };

  const renderSubmissionPhaseDisabled = (mode, exceededQuotaNextResetDay) => {
    /*
     * Function that handles rendering of the component that crafts a message and notifies the user when they can't submit because:
     *  1. the submission phase is disabled, OR
     *  2. when the participant is not in team, OR
     *  3. that notifies participants that upload of submissions is currently not allowed during, OR
     * this phase of the challenge.
     */

    let message = ``;
    if (mode === "overall_quota_exceeded") {
      message = `Your team has exceeded the overall allowed submission quota. Please contact
      an admin if you wish to raise a case to exclude a previous
      submission.`;
    } else if (mode === "window_quota_exceeded") {
      // TODO: for the "14 days", change to env variable as the rolling window may vary
      message = `Your team has exceeded the allowed submission quota in the past 14 days. You will be allowed to submit again on ${datetimeConverter(
        exceededQuotaNextResetDay
      )}. Please contact
      an admin if you wish to raise a case to exclude a previous
      submission.`;
    } else if (mode === "submissions_disabled") {
      message = `Submissions upload is currently disabled at this phase of the
      challenge. However, you may still view your previous submissions in
      the table below.`;
    } else if (mode === "user_not_in_team") {
      message = `You are currently not in a team. You have to join a team or create your own team to take part in the challenge.`;
    }
    return (
      <div className="sc-border">
        <div className="sc-title-box">{message}</div>
      </div>
    );
  };

  const renderSubmissionQuotaDetails = () => {
    if (allQuotasOfTeam) {
      return (
        <p>
          <HourglassTopIcon />
          Your team's submission quota details:{" "}
          {allQuotasOfTeam["window_submission_count"]}/
          {allQuotasOfTeam["window_submission_quota"]} |{" "}
          {allQuotasOfTeam["total_submission_count"]}/
          {allQuotasOfTeam["total_submission_quota"]}{" "}
          <Tooltip
            title="Over Past 14 Days | Overall"
            slotProps={{
              // adjust distance of tooltip message from anchor
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <HelpOutlineIcon />
          </Tooltip>
        </p>
      );
    }
    return <></>;
  };

  const renderFileNotPicked = () => {
    /*
     * Function that handles rendering of the "Choose File" component of the submission form,
     * before a submission file (e.g. in tar.gz format) is selected by the user.
     */
    // console.log(allQuotasOfTeam);

    return (
      // only show upload submission components when submissions aren't disabled
      <div className="sc-border">
        <div className="sc-title-box">
          <div>
            <p>
              <b>Add a new submission </b>
            </p>
          </div>
          <div>{renderSubmissionQuotaDetails()}</div>
        </div>
        <Form.Group
          as={Row}
          className="sc-title-row"
          controlId="formSelectFile"
        >
          <Form.Label>
            <b>Please Select File (Only accepts .tar.gz)</b>{" "}
          </Form.Label>
          <Form.Label>
            <b>
              {/* convert byte from env file to gigabyte */}
              Maximum File Size Limit:{" "}
              {bytesConverter(process.env.REACT_APP_FILE_LIMIT, "GB", 0)}
            </b>{" "}
          </Form.Label>
          <Col sm="14">
            <Form.Control
              type="file"
              name="file_select"
              accept=".tar.gz"
              onChange={(event) => onFileSelect(event)}
            />
          </Col>
        </Form.Group>
      </div>
    );
  };

  // Upload Submission Confirmation Modal
  const onUploadSubmissionModalContinue = () => {
    /*
     * Function that handles logic on submitting the upload submission confirmation modal.
     */
    setShowUploadSubmissionConfirmationModal(false);

    // IMPORTANT: this if-check prevents double uploads when user double clicks the button
    if (!submissionImageUploading) {
      setSubmissionImageUploading(true);
      onCreateSubmissionGetPreSignedUrl();
    }
  };

  const onUploadSubmissionModalCancel = () => {
    /*
    * Function that handles logic on cancelling/closing the upload submission confirmation modal.

     */
    setShowUploadSubmissionConfirmationModal(false);
  };

  const uploadSubmissionConfirmationModal = () => {
    /*
     * Function that handles the rendering of the confirmation modal,
     * for uploading submission.
     */

    return (
      <Modal
        open={showUploadSubmissionConfirmationModal}
        onClose={onUploadSubmissionModalCancel}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        // slotProps={{ timeout: 500 }}
      >
        <Fade in={showUploadSubmissionConfirmationModal}>
          <div className={"k-submit-confirmation-modal"}>
            <div className={"k-submit-modal-content"}>
              <div>
                {/* Upload Submission Confirmation Modal - Warning Message  */}
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ marginBottom: "25px" }}
                >
                  {/* You have chosen {selected.size} out of{" "}
                  {process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS}{" "}
                  submissions. <br></br>
                  Are you sure you want to submit? <br></br> */}
                  <p
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    WARNING!
                  </p>
                  <p
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    Please note that uploading this submission will deduct one
                    from your current submission allowance. Once submitted, you
                    will not be able to edit it.
                  </p>

                  <p
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    Are you sure you want to upload this submission?
                  </p>
                </Typography>
              </div>

              {/* Upload Submission Confirmation Modal - Action Buttons */}
              <Stack
                direction="horizontal"
                gap={5}
                style={{
                  justifyContent: "center",
                  marginBottom: "15px",
                }}
              >
                <Button
                  onClick={onUploadSubmissionModalCancel}
                  variant="danger"
                  style={{ width: "100px" }}
                >
                  No
                </Button>
                <Button
                  onClick={onUploadSubmissionModalContinue}
                  disabled={submissionImageUploading}
                  variant="success"
                  style={{ width: "100px" }}
                >
                  Yes
                </Button>
              </Stack>

              {/* <Typography style={{ marginBottom: "15px", fontSize: "0.75em", marginTop: "20px" }}>
                <div>
                  Reminder: Do not switch to another page or close this page
                  during the upload.
                </div>
              </Typography> */}
            </div>
          </div>
        </Fade>
      </Modal>
    );
  };

  const onUploadSubmission = (event) => {
    event.preventDefault();
    setShowUploadSubmissionConfirmationModal(true);
  };

  const renderTitleComment = () => {
    /*
     * Function that handles rendering the title and comments components of the submission form,
     * after a submission file (e.g. in tar.gz format) has been chosen by the user.
     */

    return (
      <div className="sc-file-select">
        {/* COMPONENT TITLE AND SUBMISSIOON QUOTA COUNT */}
        <div
          style={{
            // fontSize: "1.5rem",
            textAlign: "left",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              fontSize: "1.5rem",
            }}
          >
            <b>
              <p>Edit Your Submission </p>
            </b>
          </div>
          <div
            style={{
              fontSize: "1.25rem",
            }}
          >
            {renderSubmissionQuotaDetails()}
          </div>
        </div>

        {/* DISPLAY FILE DETAILS */}
        <div
          style={{
            fontSize: "1.25rem",
            textAlign: "left",
            marginBottom: "30px",
          }}
        >
          <p>
            <b>Your Selected File</b>
            {/* {selectedFile?.name} (
            {selectedFile?.size} Bytes) */}
          </p>
          <p>
            {/* <b>Filename:</b>  */}
            <span>
              <AttachFileIcon />
            </span>{" "}
            {selectedFile?.name} ({bytesConverter(selectedFile?.size, "auto")})
          </p>
          {/* <p>
            <b>Size in bytes:</b> {selectedFile?.size}
          </p> */}
        </div>

        {/* DISPLAY SUBMISSION TITLE AND COMMENTS INPUT FORM*/}
        {!submissionImageUploading ? (
          // if image is uploading, hide this component
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "20px",
              }}
            >
              <b>Title:</b>
              <input
                style={{
                  borderRadius: "0.25rem",
                  backgroundColor: "white",
                  border: "0.0625rem solid #8080805e",
                  width: "45rem",
                  height: "2rem",
                  padding: "1.25rem",
                  // marginTop: "-5px",
                  marginLeft: "46px",
                }}
                placeholder="Add a title for your submission (optional)."
                value={userInputSubmissionTitle}
                onChange={titleHandler}
                maxLength={30} // Specifies the maximum number of characters allowed in the input box
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <b>Comment: </b>
              <textarea
                style={{
                  borderRadius: "0.25rem",
                  marginTop: "0.9375rem",
                  backgroundColor: "white",
                  border: "0.0625rem solid #8080805e",
                  width: "45rem",
                  height: "7rem",
                  padding: "1.25rem",
                  marginTop: "0px",
                  marginLeft: "9px",
                  marginBottom: "10px",
                }}
                placeholder="Add some comments to describe your submission (optional)."
                value={userInputSubmissionComment}
                onChange={commentHandler}
                wrap="soft"
                maxLength={500} // Specifies the maximum number of characters allowed in the textarea
              />
            </div>
            <div style={{ marginLeft: "750px" }}>{commentCharCount}/500</div>
          </div>
        ) : (
          <div>
            <div
              style={{
                fontSize: "1rem",
                textAlign: "left",
                marginBottom: "30px",
                overflowWrap: "break-word",
                width: "45rem",
              }}
            >
              <b>Title:</b>
              <p>
                <TopicIcon /> {userInputSubmissionTitle}
              </p>
              {/* <p>fkjdsakfljdsklfjdksl title</p> */}
            </div>
            <div
              style={{
                fontSize: "1rem",
                textAlign: "left",
                marginBottom: "30px",
                overflowWrap: "break-word",
                width: "45rem",
              }}
            >
              <b>Comment:</b>
              <p>
                <NotesIcon /> {userInputSubmissionComment}
              </p>
              {/* <p>fdsfdsfjsdkljfdskloirewrwe comment</p> */}
            </div>
          </div>
        )}

        {!submissionImageUploading ? (
          // if image is uploading, hide this component
          <Stack
            direction="horizontal"
            gap={4}
            style={{
              justifyContent: "right",
            }}
          >
            <Button
              // style={{ backgroundColor: "maroon", borderColor: "maroon" }}
              type="button"
              variant="danger"
              onClick={cancelSubmission}
            >
              Cancel Submission
            </Button>
            <Button
              style={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
              variant="success"
              type="button"
              onClick={onUploadSubmission}
            >
              Upload Submission
            </Button>
          </Stack>
        ) : (
          <></>
        )}

        {/* Upload Submission - Confirmation Modal */}
        {uploadSubmissionConfirmationModal()}

        {/* <p>
          <b>
            Last Modified Date:{" "}
            {selectedFile?.lastModifiedDate?.toLocaleDateString()}
          </b>
        </p> */}
        {/* <Button
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
          type="button"
          onClick={onCreateSubmissionGetPreSignedUrl}
        >
          Create Submission
        </Button>
        <Button
          style={{ backgroundColor: "maroon", borderColor: "maroon" }}
          type="button"
          onClick={cancelSubmission}
        >
          Cancel Submission
        </Button> */}

        {submissionImageUploading ? (
          <div>
            <Typography
              style={{
                marginBottom: "15px",
                fontSize: "1.25rem",
                marginTop: "10px",
              }}
            >
              <div>
                <ReportProblemIcon />{" "}
                <b>
                  Reminder: Do not switch to another page or close this page
                  during the upload.
                </b>
              </div>
            </Typography>

            {/* Progress bar for non-mulitpart upload */}
            {/* <ProgressBar
              now={uploadProgressPct}
              label={`${uploadProgressPct}%`}
            /> */}

            {/* Progress bar for mulitpart upload */}
            <ProgressBar
              animated
              now={multipartUploadProgressBarHandler(
                multipartUploadProgressTracker,
                selectedFile.size
              )}
              label={`${multipartUploadProgressBarHandler(
                multipartUploadProgressTracker,
                selectedFile.size
              )}%`}
            />

            {/* cancelling non-multipart upload */}
            {/* <div style={{ marginTop: "10px" }}>
              <Button variant="danger" onClick={onCancelUploadClick}>
                Stop Upload
              </Button>
            </div> */}

            {/* cancelling multipart upload */}
            <div style={{ marginTop: "10px" }}>
              <Button variant="danger" onClick={onCancelMultipartUploadClick}>
                Stop Upload
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderNewSubmission = () => {
    /*
     * Function that handles rendering of the submission form,
     * after a submission file (e.g. in tar.gz format) has been chosen by the user.
     */
    return (
      <div className="sc-border">
        {validateError && <Alert variant="danger">{validateError}</Alert>}
        <>{renderTitleComment()}</>
      </div>
    );
  };

  return (
    <div className="sc-container">
      {loading ? (
        <div className="spinner" style={{ marginTop: "50px" }}>
          <Spinner size={50} />
        </div>
      ) : (
        // DEPRECATED as only submission upload would be disabled.
        // <>
        //   {submissionPhaseFlag === "True" ? (
        //     <>
        //       {" "}
        //       <div className="sc-title">
        //         <h1>Submissions Management</h1>
        //       </div>
        //       <div> {SubmitFileForm()}</div>
        //       {isFilePicked ? (
        //         <div
        //           style={{
        //             fontSize: "1.25rem",
        //             marginBottom: "20px",
        //             marginTop: "20px",
        //           }}
        //         >
        //           - Awaiting Submission -
        //         </div>
        //       ) : (
        //         <div style={{ width: "1100px", wordBreak: "break-word" }}>
        //           {renderTable()}
        //         </div>
        //       )}
        //     </>
        //   ) : (
        //     <div style={{ marginTop: "45px" }}>
        //       - Admin has disabled the functions for this page. -
        //       {/* This stuff is currently switched off. <br />
        //       Contact your admin about it. */}
        //     </div>
        //   )}
        // </>
        <>
          {" "}
          {/* If team doesn't max out their picked submissions limit, 
          show Final Pick Reminder Message. */}
          {/* {selected.size < 2 && FinalPickReminderBanner()} */}
          <div className="sc-title">
            <h1>Submissions Management</h1>
          </div>
          <div> {SubmitFileForm()}</div>
          {isFilePicked ? (
            <div
              style={{
                fontSize: "1.25rem",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              - Awaiting Submission -
            </div>
          ) : (
            <div style={{ width: "1100px", wordBreak: "break-word" }}>
              {renderTable()}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SubmitCreate;
