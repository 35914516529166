import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const initialState = {
  loginLoading: false, // to track with user login is still loading
  loginData: [],
  isLoggedIn: false, // to track with user is logged in

  loginStatus: null, // to track login status

  isUserVerified: false, // to track when user has successfully verified
  verifyUserLoading: false, // to track when user verify api is in transit to the server

  userUnverifiedLogin: false, // is true if an unverified user logins
  isLoginFailedError: false, // to track when user's login has failed
  isResetLoginAttemptFailedError: false, // to track when backend update user login attempt to 0 fails
  isUserLoginAttemptsExceeded: false, // to track when user exceeded the allowed login attempts

  userLoginErrors: [], // to track errors when login api is called
};
const name = "loginState";

//login
export const login = createAsyncThunk(
  `${name}/api/v0.1/auth/login`,
  async ({ username, email, password }) => {
    try {
      const res = await axios.post("/api/v0.1/auth/login", {
        username,
        email,
        password,
      });

      if (res.status !== 200) {
        toast.error("Login failed.");
        // console.log("The URL you just called:", res.config.baseURL.concat(res.config.url));

        return;
      }
      if (res.status === 200) {
        localStorage.setItem("token", res.data.auth_token);
        localStorage.setItem("role", res.data.payload.role);
        // console.log("The URL you just called:", res.config.baseURL.concat(res.config.url));

        return res.data;
      }
    } catch (err) {
      // console.log(flag);
      // if (flag === true) {
      //   toast.error("Login failed. Incorrect credentials.");
      // }
      // console.log("The URL you just called:", err.config.baseURL.concat(err.config.url));
      throw new Error(err.response.data.detail["error code"]); // throws error for builder to catch
    }
  }
);

const loginSlice = createSlice({
  name,
  initialState,
  reducers: {
    // reducers are functions that modify state
    logOut: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      state.loginData = initialState.loginData;
      state.isLoggedIn = initialState.isLoggedIn;

      state.userUnverifiedLogin = initialState.userUnverifiedLogin;
      state.isLoginFailedError = initialState.isLoginFailedError;
      state.isResetLoginAttemptFailedError =
        initialState.isResetLoginAttemptFailedError;
      state.isUserLoginAttemptsExceeded =
        initialState.isUserLoginAttemptsExceeded;
      state.userLoginErrors = initialState.userLoginErrors;
    },
    completeLogin: (state) => {
      state.userUnverifiedLogin = initialState.userUnverifiedLogin;
      state.isLoginFailedError = initialState.isLoginFailedError;
      state.isResetLoginAttemptFailedError =
        initialState.isResetLoginAttemptFailedError;
      state.isUserLoginAttemptsExceeded =
        initialState.isUserLoginAttemptsExceeded;
      state.userLoginErrors = initialState.userLoginErrors;
    },
  },

  extraReducers: (builder) => {
    //handle login api call statuses
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loginData = payload;
      state.isUserVerified = true;
      state.loginStatus = "fulfilled";

      if (payload) {
        state.isLoggedIn = true;
      }
      // reset to initial state
      state.loginLoading = false;
      state.userUnverifiedLogin = false;
      state.isLoginFailedError = false;
      state.userLoginErrors = [];
    });
    builder.addCase(login.pending, (state) => {
      state.loginLoading = true;
      state.loginStatus = "pending";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginLoading = false;
      state.loginStatus = "rejected";

      state.userLoginErrors.push(action.error.message); // catch error message thrown by api and append to state
      if (action.error.message === "AUTH-11005") {
        // if user is unverified, store condition to state for sendVerificationEmail page to load
        state.userUnverifiedLogin = true;
      } else if (action.error.message === "AUTH-11003") {
        // if user's login attempt failed, store condition to state
        state.isLoginFailedError = true;
      } else if (action.error.message === "AUTH-22006") {
        // if backend's reset login attempts failed, store condition to state
        state.isResetLoginAttemptFailedError = true;
      } else if (action.error.message === "AUTH-11004") {
        // if user login exceeded the allowed attempts
        state.isUserLoginAttemptsExceeded = true;
      } else {
        // if other errors occur, set to loginFailed error as well
        state.isLoginFailedError = true;
      }
    });
  },
});

export const { logOut } = loginSlice.actions;
export const { completeLogin } = loginSlice.actions;

export default loginSlice.reducer;

//login complete selectors

export const useLoginStatus = () =>
  useSelector((state) => state.loginState.loginStatus);

export const useUserLoginErrors = () =>
  useSelector((state) => state.loginState.userLoginErrors);

export const useUserUnverifiedLogin = () =>
  useSelector((state) => state.loginState.userUnverifiedLogin);

export const useIsLoginFailedError = () =>
  useSelector((state) => state.loginState.isLoginFailedError);

export const useIsResetLoginAttemptFailedError = () =>
  useSelector((state) => state.loginState.isResetLoginAttemptFailedError);

export const useIsUserLoginAttemptsExceeded = () =>
  useSelector((state) => state.loginState.isUserLoginAttemptsExceeded);

export const useIsLoggedIn = () =>
  useSelector((state) => state.loginState.isLoggedIn);

export const useLoggedInData = () =>
  useSelector((state) => state.loginState.loginData);

export const useLoginLoading = () =>
  useSelector((state) => state.loginState.loginLoading);
