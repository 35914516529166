import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import CustomButton from "shared/CustomButton/CustomButton";
import { backendDomain } from "utils/envConfig";
import checkValidJsonParse from "utils/checkValidJsonParse";

import { MDBDataTable } from "mdbreact";
import "./TeamJoin.css";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { logOut } from "redux/loginSlice";




const TeamJoin = () => {
  const [data, setData] = useState({}); // state to store API response
  const [errors, setErrors] = useState([]); // state to store errors
  const navigate = useNavigate();
  const dispatch = useDispatch()

  let sortedList = [];

  const fetchData = async () => {
    let url = `${backendDomain}/api/v0.1/team/`;
    try {
      let params = {};

      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: params,
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();

        // store API response in state
        setData(response_data);
      } else {
        let response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      // append error in state
      setErrors([...errors, error.response]);

      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Inviting team member failed.", {
          id: 'json_parse_error'
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        
      }

      // output error message into console
    }
  };
  const requestTeamJoin = async (team_id, team_name) => {
    let url = `${backendDomain}/api/v0.1/team/` + team_id + "/request";
    try {
      // query Participant's Own Results endpoint
      let params = {};

      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: params,
      });
      if (response.ok) {
        // await read the body stream response.json (Promise)
        // const response_data = await response.json();
        toast.success("Requested to join " + team_name + "!");
      } else {
        let response_error = await response.json();

        
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      // there is this odd issue where it seems after the error
      // the page is remounted, and since data is useState it sets as {}. 
      // For that a simple workaround was created
      fetchData(); 
      // append error in state
      setErrors([...errors, error.response]);

      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Inviting team member failed.", {
          id: 'json_parse_error'
        });
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        // toast.error("Error: Inviting team member failed.", {
        //   id: parsed_error["error code"],
        // });
        toast.error(
          "Cannot request to join " +
            team_name +
            "! " +
            parsed_error["error message"]
            
        )
      }

    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (typeof data !== "") {
      sortedArr(data);
    }
  }, [data]);

  function sortedArr(data) {
    if (data.length === 0) {
      return;
    }
    data?.result?.map(function (element) {
      sortedList.push({
        teamName: element.team_name,
        action: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CustomButton
              className="team-request-join-button"
              testId="team-request-join-btn"
              content="Join"
              clicked={() =>
                requestTeamJoin(element.team_id, element.team_name)
              }
            ></CustomButton>
          </div>
        ),
      });

      return sortedList;
    });
  }

  const data2 = {
    columns: [
      {
        label: "Team Name",
        field: "teamName",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
      },
    ],
    rows: sortedList,
  };

  return (
    <div className="team-join-container">
      <div className="team-join-title">
        <h1>Join Team</h1>
      </div>
      <div style={{ width: "600px" }}>
        {" "}
        <MDBDataTable
          fixed
          autoWidth={false}
          dark
          noBottomColumns
          data={data2}
          displayEntries={false}
        />{" "}
      </div>

      {/* CANCEL BUTTON */}
      <CustomButton
        className="back-button"
        testId="user-profile-back-btn"
        content="Back"
        clicked={async () => {
          navigate("/team-dashboard");
        }}
      ></CustomButton>
    </div>
  );
};

export default TeamJoin;
