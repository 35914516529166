import React from "react";
import CustomAccordion from "shared/Accordion/Accordion";
import "./Background.css";

const TechnicalDetails = () => {
  const sampleSubmissionGithubUrl =
    "https://github.com/AISG-Technology-Team/GCSS-Track-2A-Submission-Guide";

  return (
    <div data-testid="technical-details-wrapper" className="wrapper">
      <div data-testid="the-title" className="the-title">
        Technical Details
      </div>

      {/* 1. Guidelines on Model & Docker Container Formats */}
      <CustomAccordion
        testId="disclaimer-title"
        title="Guidelines on Model & Docker Container Formats"
      >
        <ol>
          <li>
            The complete submission guidelines containing the required
            input-output formats specifications are available at{" "}
            <a
              href={sampleSubmissionGithubUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              {sampleSubmissionGithubUrl}
            </a>
            . Participants must review these specifications in detail to ensure
            their submissions are processed and scored correctly.{" "}
          </li>
          <li>
            Participants are advised that there are predefined
            limits on the Docker containers. These limits are specified in the
            submission guidelines link above. These limitations are in place to
            ensure equitable computational resource usage and to maintain
            performance efficiency across all submissions.{" "}
          </li>
          <li>
            All essential resources for the model's inference
            phase must be encapsulated within the Docker container. Please note
            that during runtime, the Docker container will be isolated without
            internet connectivity or the ability to access external resources or
            data. This isolation ensures a secure and controlled execution
            environment for the evaluation.{" "}
          </li>
          <li>
            All software and tools in the Docker container and
            the entire development toolchain must be open sourced, ensuring
            transparency, unrestricted auditing, and collaboration. Closed
            source code is strictly prohibited to maintain the principles of
            open innovation, allowing free access and improvement by the
            community.{" "}
          </li>
        </ol>
      </CustomAccordion>

      {/* 2. Leaderboard Ranking */}
      {/* <CustomAccordion
        testId="disclaimer-title"
        title="Leaderboard and Ranking"
      >
        <ol>
          <li>
            Submissions will be evaluated against a hidden evaluation dataset.
            AUROC and accuracy scores will be calculated for each submission,
            and these will be displayed on a leaderboard. Teams will be ranked
            primarily by AUROC scores.
          </li>
          <li>
            In the event of a tie, model accuracy will be used as a secondary
            metric to determine leaderboard ranking. The accuracy of both teams’
            top performing model based on AUROC on the leaderboard will be
            compared against each other.
          </li>
        </ol>
      </CustomAccordion> */}

      {/* 3. Responding to Technical Inquiries (used to be Prizes and Conditions) */}
      <CustomAccordion testId="disclaimer-title" title="Responding to Technical Inquiries">
        <ol>
          <li>
            To address further queries, a dedicated discussion board will be
            established for participant inquiries. Technical issues or questions
            directly related to the Challenge should be submitted through this
            platform. Participants can access the discussion board via this link{" "}
            <a
              href={sampleSubmissionGithubUrl + "/issues"}
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              {sampleSubmissionGithubUrl + "/issues"}
            </a>
            . To post questions or participate in discussions, a GitHub account
            is required. This measure ensures a secure and authenticated user
            experience. Please read the instructions for posting questions here{" "}
            <a
              href={sampleSubmissionGithubUrl + "/issues/1"}
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              {sampleSubmissionGithubUrl + "/issues/1"}
            </a>{" "}
            before posting questions to the discussion board. Our support team
            is committed to responding within three business days, excluding
            weekends and public holidays. In the event of a high volume of
            inquiries or unforeseen circumstances, response times may be
            extended. We appreciate your understanding and patience in these
            situations.
          </li>
          <li>
            The discussion board is intended explicitly for technical issues and
            questions related to the Challenge. Queries outside this scope, such
            as general inquiries about program policies or administrative
            matters, should be directed to our support email at{" "}
            <a
              href="mailto:prizechallenge@aisingapore.org"
              className="url-text-styling"
            >
              prizechallenge@aisingapore.org
            </a>
            .
          </li>
        </ol>
      </CustomAccordion>
    </div>
  );
};

export default TechnicalDetails;
