import React, { useEffect, useState } from "react";
import "./InviteTeam.css";
import { useNavigate } from "react-router-dom";
import CustomButton from "shared/CustomButton/CustomButton";
import toast from "react-hot-toast";
import { backendDomain } from "utils/envConfig";
import { useLoggedInData } from "redux/loginSlice";

const InviteTeam = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [username, setChangeUsername] = useState("");
  const [teamProfile, setTeamProfile] = useState("");

  const loginData = useLoggedInData();

  const teamLeadExist = loginData?.payload?.team_lead;

  const navigate = useNavigate();

  const usernameHandler = (event) => {
    setChangeUsername(event.target.value);
  };

  //get team profile
  const fetchTeamProfile = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${backendDomain}/api/v0.1/team/team-profile`,
        requestOptions
      );

      if (response.ok) {
        const createResponse = await response.json();
        const team_name = createResponse.message.team_name;

        setTeamProfile(team_name);
      } else {
        let errorResponse = await response.json();
 
      }
    } catch (err) {
      console.log(err);
    }
  };

  //get submission history
  const inviteTeamMember = async (username) => {
    var raw = JSON.stringify({
      username: username,
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${backendDomain}/api/v0.1/team/member`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Successfully sent invite.");
      } else {
        toast.error("Not Successful to send invite");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTeamProfile();
  }, []);

  const renderTeamLeadUI = () => {
    return (
      <div className={"invite-team-container"}>
        <div className="invite-team-title">Your Team Name: {teamProfile}</div>
        <div className="invite-title">Invite Team Member to Join Your Team</div>
        <input
          style={{
            borderRadius: "0.25rem",
            marginTop: "1.5625rem",
            marginBottom: "1.875rem",
            backgroundColor: "aliceblue",
            border: "0.0625rem solid #d8e0ff",
            width: "21.25rem",
            height: "3.125rem",
            padding: "1.25rem",
          }}
          type="text"
          name="name"
          placeholder="Team Member Name"
          value={username}
          onChange={usernameHandler}
        />

        {username.length === 0 && formSubmitted ? (
          <div
          data-testid="invite-team-required"
            style={{
              marginRight: "16.25rem",
              paddingTop: "1.25rem",
              paddingBottom: "0.625rem",
              marginBottom: "0.3125rem",
              marginTop: "-0.9375rem",
              color: "red",
            }}
          >
            *required
          </div>
        ) : null}

        <CustomButton
          className="basic"
          testId="invite-team-btn"
          content="Invite Team Member"
          clicked={async () => {
            setFormSubmitted(true);
            inviteTeamMember(username);
          }}
        ></CustomButton>

        <CustomButton
          className="back-button"
          testId="team-dashboard-back-btn"
          content="Back"
          clicked={async () => {
            navigate("/team-dashboard");
          }}
        ></CustomButton>
      </div>
    );
  };

  return (
    <>
      {teamLeadExist ? (
        renderTeamLeadUI()
      ) : (
        <div className={"invite-team-container"}>
          You are not eligible to do invites.
          <CustomButton
            className="back-button-team"
            testId="team-dashboard-back-btn"
            content="Back"
            clicked={async () => {
              navigate("/team-dashboard");
            }}
          ></CustomButton>
        </div>
      )}
    </>
  );
};

export default InviteTeam;
