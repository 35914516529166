import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import "./UserProfile.css";
import CustomButton from "shared/CustomButton/CustomButton";
import { AiFillMail } from "react-icons/ai";
import { FaUserAlt, FaUniversity, FaUserFriends } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";

import { jwtDecode } from "jwt-decode";
import { useCheckTokenExpiry } from "hooks/useCheckTokenExpiry/useCheckTokenExpiry";

import { logOut } from "redux/loginSlice";
import { completeGetCurrentTeamRankingData } from "redux/leaderboardSlice";
import {
  useUserProfileData,
  useIsTokenErrorThenLogout,
  useUserProfileLoading,
  getProfileList,
  completeGetUserProfile,
} from "redux/userProfileSlice";

const UserProfile = () => {
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useUserProfileData();

  // const isTokenErrorThenLogout = useIsTokenErrorThenLogout();
  // const userProfileLoading = useUserProfileLoading();

  const { isTokenExpired } = useCheckTokenExpiry(localStorage.getItem("token"));

  useEffect(() => {
    // on page first load, get user profile from api, if token is not expired
    dispatch(getProfileList());
  }, []);

  const changePasswordButtonHandler = async () => {
    navigate("/change-password");
  };

  const logoutHandler = async () => {
    dispatch(completeGetCurrentTeamRankingData());
    dispatch(completeGetUserProfile());
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("team_lead"); // bool value
    toast.success("Logged out.", {
      id: "AUTH-11002",
    });
    setLogout(true);
    dispatch(logOut());
    navigate("/login");
  };

  const renderMainProfileCard = () => {
    return (
      <div className={"profile-main-container"}>
        <div className="profile-title">User Profile Details</div>

        {/* USER PROFILE BOX */}
        <div className="profile-container">
          <div className="profile-new-container">
            <div className="profile-row">
              <IconContext.Provider
                value={{ color: "black", className: "react-icons" }}
              >
                <div>
                  <FaUserAlt />
                </div>
              </IconContext.Provider>

              <div>
                <div className="profile-key"> Username:</div>
                <div
                  data-testid="profile-username-value"
                  className="profile-value"
                >
                  {profile?.user?.username}
                </div>
              </div>
            </div>
          </div>

          <div className="profile-new-container">
            <div className="profile-row">
              <IconContext.Provider
                value={{ color: "black", className: "react-icons" }}
              >
                <div>
                  <AiFillMail />
                </div>
              </IconContext.Provider>
              <div>
                <div className="profile-key"> Email:</div>
                <div
                  data-testid="profile-email-value"
                  className="profile-value"
                >
                  {profile?.user?.email}
                </div>
              </div>
            </div>
          </div>

          <div className="profile-new-container">
            <div className="profile-row">
              <IconContext.Provider
                value={{ color: "black", className: "react-icons" }}
              >
                <div>
                  <FaUniversity />
                </div>
              </IconContext.Provider>
              <div>
                <div
                  data-testid="profile-organization-value"
                  className="profile-key"
                >
                  {" "}
                  Organisation:
                </div>
                <div
                  className={
                    profile?.user?.organization === "No Organisation"
                      ? "profile-organization-null"
                      : "profile-value"
                  }
                >
                  {profile?.user?.organization}
                </div>
              </div>
            </div>
          </div>

          <div className="profile-new-container">
            <div className="profile-row">
              <div>
                <IconContext.Provider
                  value={{ color: "black", className: "react-icons" }}
                >
                  <div>
                    <FaUserFriends />
                  </div>
                </IconContext.Provider>
              </div>
              <div>
                <div className="profile-key">Team Name:</div>
                <div
                  data-testid="profile-team-name-value"
                  className="profile-value"
                >
                  {profile?.team === "" ? "None" : profile?.team}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CHANGE PASSWORD BUTTON*/}
        <CustomButton
          className="basic"
          testId="change-pw-btn"
          content="Change Password"
          clicked={changePasswordButtonHandler}
        ></CustomButton>

        {/* LOGOUT BUTTON*/}
        <CustomButton
          className="basic"
          testId="logout-btn"
          content="Logout"
          clicked={logoutHandler}
        ></CustomButton>
      </div>
    );
  };

  return <>{renderMainProfileCard()}</>;
};

export default UserProfile;
