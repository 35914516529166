import { toast, Toaster, ToastBar } from "react-hot-toast";

function ToasterMain() {
  /*
   * Main component to configure Toast message behaviors.
   */
  // styling for Toast's dismiss button
  const dismissButtonStyle = {
    backgroundColor: "inherit", // Inherits the background color from its parent
    border: "none", // No border by default
    borderLeft: "1px solid rgba(0, 0, 0, 0.15)", // Thin, faded left border
    color: "inherit", // Inherits the text color from its parent
    padding: "10px 0px 10px 10px", // padding: top, right, bottom, left
    fontSize: "16px",
    cursor: "pointer",
    outline: "none",
    height: "100%", // Extend to the full height of its parent
    transition: "border-color 0.3s ease",

    // Adding some hover effects
    ":hover": {
      borderLeftColor: "rgba(0, 0, 0, 0.8)", // Less faded on hover
    },
  };

  // Button component
  const FadedBorderButton = ({ onClick, children }) => (
    <button style={dismissButtonStyle} onClick={onClick}>
      {children}
    </button>
  );

  return (
    <Toaster
      toastOptions={{
        // Define default options
        duration: 5000, // controls how long the toast message lasts before disappearing (e.g. 5000 = 5 seconds)
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              
              {t.type !== "loading" && !t.data?.isTeamMgmtCustom && (
                // add dismiss button if toast is not a loading toast and a not a custom toast used in team mgmt pages
                
                // <button onClick={() => toast.dismiss(t.id)}>X</button>
                <FadedBorderButton onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </FadedBorderButton>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export default ToasterMain;
