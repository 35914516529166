import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { jwtDecode } from "jwt-decode";

import { logOut } from "redux/loginSlice";

export const checkIsTokenExpired = (token) => {
  /*
   * Function to check whether token has expired.
   * Returns 
   *  - true, if token has expired.
   *  - null, if token is missing
   */

  if (token === null || token === "") {
    return null;
  }

  try {
    const decodedTokenObject = jwtDecode(token);
    const isExpired = Date.now() >= decodedTokenObject.exp * 1000;
    return isExpired;
  } catch (error) {
    // jwtDecode might throw InvalidTokenError if token is not in the correct format
    return true; // return true if error (for now)

    // TODO: maybe add some checks to distinguish the different errors?
    // if (error.name === "InvalidTokenError") {
    //   return true;
    // }
    // return true;
  }
};

export const useCheckTokenExpiry = (token) => {
  /*
   * React hook to check whether JWT token has expired.
   */

  // init hook states
  const [isTokenExpired, setIsTokenExpired] = useState(null); // used to track if token has expired

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const doLogOut = () => {
    /*
     * Function to log user out (by dispatching the logOut action in redux)
     * and navigating the page to /login.
     */
    dispatch(logOut()); // reset redux states + clears localStorage token
    navigate("/login"); // navigate to /login
  };

  const logOutIfTokenExpired = () => {
    /*
     * Function to log user out when token has expired.
     */
    const tokenExpiryStatus = checkIsTokenExpired(token)
    if (tokenExpiryStatus) {
      setIsTokenExpired(true);
      doLogOut();
      toast.error("Error: Your session has timed out. Please re-login.", {
        id: "AUTH-11002",
      });
    } else if (tokenExpiryStatus === null) {
      setIsTokenExpired(null);
      doLogOut();
      toast.error("You are not logged in. Please login.", {
        id: "AUTH-11002",
      });
    } 
    else {
      setIsTokenExpired(false);
    }
  };

  useEffect(() => {
    logOutIfTokenExpired();

    // Optional: Polling to check for token expiry, and logout if necessary.
    // const intervalId = setInterval(logOutIfTokenExpired, 60000); // Check every minute

    // return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [token]);

  return { isTokenExpired };
};
