import React from "react";
import "./TeamDashboard.css";

import MainDash from "./MainDash/MainDash";

const TeamDashboard = () => {
  return <MainDash />;
};

export default TeamDashboard;
