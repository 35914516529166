import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import axios from "axios";
import { backendDomain } from "utils/envConfig";

const initialState = {
  getTeamProfileLoading: false,
  teamProfileData: [],
  isTeamProfileCreated: false,

  getTeamMembersLoading: false,
  teamMembersData: [],
  isTeamMembersCreated: false,
};
const name = "mainDashState";

export const getTeamProfile = createAsyncThunk(
  `${name}/api/v0.1/team/team-profile`,
  async () => {
    try {   

      let data = "";
      const res = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${backendDomain}/api/v0.1/team/team-profile`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: data,
      });

      return res.data;
    } catch (err) {
     
    }
  }
);

export const getTeamMembers = createAsyncThunk(
  `${name}/api/v0.1/team/member`,
  async () => {
    try {
      let data = "";
      const res = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${backendDomain}/api/v0.1/team/member`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: data,
      });

      return res.data.message;
    } catch (err) {

    }
  }
);

const mainDashSlice = createSlice({
  name,
  initialState,
  reducers: {
    completeGetTeamProfile: (state) => {
      state.teamProfileData = initialState.teamProfileData;
    },
    completeGetTeamMembers: (state) => {
      state.teamMembersData = initialState.teamMembersData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTeamProfile.fulfilled, (state, { payload }) => {
      state.teamProfileData = payload;

      if (payload) {
        state.isTeamProfileCreated = true;
      }

      state.getTeamProfileLoading = false;
    });
    builder.addCase(getTeamProfile.pending, (state) => {
      state.getLeaderboardLoading = true;
    });
    builder.addCase(getTeamProfile.rejected, (state, action) => {
      state.getLeaderboardLoading = false;
    });

    builder.addCase(getTeamMembers.fulfilled, (state, { payload }) => {
      state.teamMembersData = payload;

      if (payload) {
        state.isTeamMembersCreated = true;
      }

      state.getTeamMembersLoading = false;
    });
    builder.addCase(getTeamMembers.pending, (state) => {
      state.getTeamMembersLoading = true;
    });
    builder.addCase(getTeamMembers.rejected, (state, action) => {
      state.getTeamMembersLoading = false;
    });
  },
});

export const { completeGetTeamProfile } = mainDashSlice.actions;

export const { completeGetTeamMembers } = mainDashSlice.actions;

export default mainDashSlice.reducer;

export const useTeamProfileData = () =>
  useSelector((state) => state.mainDashState.teamProfileData);

export const useTeamMembersData = () =>
  useSelector((state) => state.mainDashState.teamMemberData);
