import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { useSelector } from "react-redux";
import { backendDomain } from "utils/envConfig";

const initialState = {
  getUserListLoading: false,
  getUserListData: [],
  isGetUserListCreated: false,
};

const name = "userListState";

//get user list

export const getUserList = createAsyncThunk(
  `${name}/api/v0.1/auth/user`,
  async () => {
    try {
      let data = "";
      const res = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${backendDomain}/api/v0.1/result/leaderboard`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: data,
      });

      if (res.status !== 200) {
       

        return;
      } else {
        return res.data;
      }
    } catch (err) {
   
    }
  }
);

const userListSlice = createSlice({
  name,
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.getUserListData = payload;

      if (payload) {
        state.isGetUserListCreated = true;
      }

      state.getUserListLoading = false;
    });
    builder.addCase(getUserList.pending, (state) => {
      state.getUserListLoading = true;
    });
    builder.addCase(getUserList.rejected, (state) => {
      state.getUserListLoading = false;
    });
  },
});

export default userListSlice.reducer;

export const useGetUserListData = () =>
  useSelector((state) => state.appState.getUserListData);
