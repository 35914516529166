import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./PublicLeaderboard.css";
import { getLeaderboard, useGetLeaderboardData } from "redux/leaderboardSlice";
import datetimeConverter from "utils/datetimeConverter";
// import PropTypes from "prop-types";
// import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import {
  PUBLIC_BENCHMARK_RECORDS,
  sortedRecords,
  BenchmarkRecordRow,
} from "components/Results/BenchmarkRecords";

const PublicLeaderboard = () => {
  /* 
   * Main function to render the public leaderboard page. 
  */
  const dispatch = useDispatch();

  function EnhancedTableHead(props) {
    return (
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          <TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            Ranks
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
            }}
          >
            Team Name + Total Submissions
          </TableCell>

          {process.env.REACT_APP_DISPLAY_PUBLIC_2==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_2 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_0==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_0 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_1==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_1 }
          </TableCell>)}
          {/* *****************PUBLIC SCORE 2 IS PUT AS PRIORITY TEMPORARILY */}
          {/* {process.env.REACT_APP_DISPLAY_PUBLIC_2==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_2 }
          </TableCell>)} */}
          {process.env.REACT_APP_DISPLAY_PUBLIC_3==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_3 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_4==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_4 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_5==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_5 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_6==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_6 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_7==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_7 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_8==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_8 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_9==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_9 }
          </TableCell>)}

        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableToolbar(props) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%", textAlign: "left" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <b>Public Leaderboard</b>
        </Typography>
      </Toolbar>
    );
  }

  // EnhancedTableToolbar.propTypes = {
  //   numSelected: PropTypes.number.isRequired,
  // };

  // const [selected, setSelected] = React.useState([]);

  const renderTable = () => {
    return (
      <Box
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Paper>
          <EnhancedTableToolbar />

          <TableContainer style={{ overflowX: "initial" }}>
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead />

              <TableBody>
                {results?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // return

                  if (!row.team_name) {
                    // check if record has a team_name, if not it is a benchmark record
                    return (
                      <BenchmarkRecordRow
                        row={row}
                        leaderboardType={"public"}
                      />
                    );
                  }

                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.team_id}>
                      {/* Ranking Cell */}
                      <TableCell id={labelId}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {row?.ranking}
                        </div>
                      </TableCell>

                      {/* Team Info Cell */}
                      <TableCell id={labelId}>
                        <div
                          style={{
                            paddingTop: "10px",
                            width: 450,
                          }}
                        >
                          <div style={{ color: "#ae2514" }}>
                            <b>{row?.team_name}</b>
                          </div>

                          <div
                            style={{
                              color: "grey",
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "5px",
                            }}
                          >
                            <div>
                              {row?.successful_attempts}
                              {" Submission(s)"}
                            </div>
                            <div>
                              Best public score submission on:{" "}
                              {datetimeConverter(row.created_date)}
                            </div>
                          </div>
                        </div>
                      </TableCell>

                      {process.env.REACT_APP_DISPLAY_PUBLIC_2==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_2]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_0==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_0]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_1==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_1]}
                        </div>
                      </TableCell>)}
                      {/* *****************PUBLIC SCORE 2 IS PUT AS PRIORITY TEMPORARILY */}
                      {/* {process.env.REACT_APP_DISPLAY_PUBLIC_2==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_2]}
                        </div>
                      </TableCell>)} */}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_3==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_3]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_4==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_4]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_5==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_5]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_6==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_6]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_7==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_7]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_8==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_8]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_9==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_9]}
                        </div>
                      </TableCell>)}

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  // const handleFetchLeaderboard = async () => {
  //   dispatch(getLeaderboard());
  // };

  useEffect(() => {
    dispatch(getLeaderboard());

    const timer = setInterval(dispatch(getLeaderboard()), 600000);

    return () => clearInterval(timer);
  }, []);

  let leaderboard = useGetLeaderboardData();
  // let results = leaderboard["results"];

  // // insert benchmark records into the leaderboard
  let results = null;
  if (leaderboard["results"]) {
    if (PUBLIC_BENCHMARK_RECORDS && PUBLIC_BENCHMARK_RECORDS.length > 0) {
      results = sortedRecords(
        // NOTE: putting "...PUBLIC_BENCHMARK_RECORDS" first, followed by "...leaderboard["results"]",
        // causes Teams with exactly the same scores as Baseline models to appear behind the Baseline record.
        // Conversely, putting "...leaderboard["results"]" first, will cause the opposite effect.
        [...PUBLIC_BENCHMARK_RECORDS, ...leaderboard["results"]],
        ["asr_llama"]
      );
    } else {
      results = leaderboard["results"];
    }
  }

  // DEPRECATED
  // returns true if results is not loaded
  // const leaderboardResultsIsNotLoaded =
  //   results == null || (Array.isArray(results) && !results?.length);

  // const allDataIsLoaded = leaderboardResultsIsNotLoaded || rankingIsNotLoaded; // TODO: for creating a loading animation for the whole page

  return (
    <>
      {/* <div className="pr-container">
      <div>{renderTable()}</div>
      </div> */}
      <div className="pr-container">
        <div>{renderTable()}</div>
      </div>
    </>
  );
};

export default PublicLeaderboard;
