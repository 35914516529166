import React, { useState, useEffect } from "react";
import { backendDomain } from "utils/envConfig";
import { useNavigate } from "react-router-dom";

import {
  PRIVATE_BENCHMARK_RECORDS,
  sortedRecords,
  // BenchmarkRecordRow,
} from "components/Results/BenchmarkRecords";

// css imports
import Switch from "shared/Switch/Switch";
import { PrivateLeaderboardTable } from "./PrivateLeaderboardTable";
import "components/Results/PrivateLeaderboard/PrivateLeaderboard.css";

const PrivateLeaderboard = () => {
  /* 
   * Main function to render the private leaderboard page. 
  */
  const [checked, setChecked] = useState(true);
  const [res, setRes] = useState([]);
  const [res2, setRes2] = useState([]);

  // DEPRECATED: for rendering empty row when pagination does not hit max number of records
  // const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  // const [dense, setDense] = useState(false);
  // const [rowsPerPage, setRowsPerPage] = useState(10000);
  // // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - results?.length) : 0;
  // const emptyRows2 =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - results2?.length) : 0;

  const fetchPrivateM1GET = async () => {
    let url = `${backendDomain}/api/v0.1/result/private-leaderboard?mode=1`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();

        setRes(response_data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const fetchPrivateM2GET = async () => {
    let url = `${backendDomain}/api/v0.1/result/private-leaderboard?mode=2`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();

        setRes2(response_data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleFetchLeaderboard = async () => {
    fetchPrivateM1GET();
    fetchPrivateM2GET();
  };

  const navigate = useNavigate();

  useEffect(() => {
    // block user if user's token is not admin
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("role") !== "admin"
    ) {
      navigate("/");
      return;
    }

    handleFetchLeaderboard();

    const timer = setInterval(handleFetchLeaderboard(), 600000);
    return () => clearInterval(timer);
  }, []);

  // let results = res["results"];
  // let results2 = res2["results"];


  // load results from BE, combine with benchmark records, and sort
  let results = null;
  if (res["results"]) {
    if (PRIVATE_BENCHMARK_RECORDS && PRIVATE_BENCHMARK_RECORDS.length > 0) {
      results = sortedRecords(
        [...PRIVATE_BENCHMARK_RECORDS, ...res["results"]],
        ["asr_hmean_private","avg_token_length_ratio"]
      );
    } else {
      results = res["results"];
    }
  }

  let results2 = null;
  if (res2["results"]) {
    if (PRIVATE_BENCHMARK_RECORDS && PRIVATE_BENCHMARK_RECORDS.length > 0) {
      results2 = sortedRecords(
        [...PRIVATE_BENCHMARK_RECORDS, ...res2["results"]],
        ["asr_hmean_private","avg_token_length_ratio"]
      );
    } else {
      results2 = res2["results"];
    }
  }
  
  const renderTable = () => {
    /* 
      Function that renders the mode1 private leaderboard
    */

    return (
      <>
        <PrivateLeaderboardTable
          results={results}
          mode={1}
          tableName={"Private Leaderboard - Best of All Submissions"}
        />
      </>
    );
  };

  const renderTable2 = () => {
    /* 
       Function that renders the mode2 private leaderboard
    */

    return (
      <>
        <PrivateLeaderboardTable
          results={results2}
          mode={2}
          tableName={`Private Leaderboard - Best Of ${process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS} Selected Submissions`}
        />
      </>
    );
  };

  return (
    <div className="pr-container">
      <div className="pr-switch">
        <Switch
          isOn={!checked}
          handleToggle={() => setChecked(!checked)}
          colorOne="#808080"
          colorTwo="#808080"
          includeTitle={false}
          titleOne=""
          titleTwo=""
          className="switch-checkbox"
        />
      </div>

      {checked ? <div>{renderTable()}</div> : <div>{renderTable2()}</div>}
    </div>
  );
};

export default PrivateLeaderboard;
