import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetPasswordAuth,
  useResetPasswordStatus,
  useResetPasswordErrors,
  useIsResetPasswordTokenExpiredError,
  useIsResetPasswordTokenInvalidError,
  completeResetPassword,
} from "redux/appSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import "./ForgetPasswordRedirect.css";
import CustomButton from "shared/CustomButton/CustomButton";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(6, "*Password must be at least 6 characters long")
    .required("New Password is required."),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "*Passwords must match") // check if user new password and confirm new password match
    .required("*Confirmation Password is required."),
});

const ForgetPasswordRedirect = () => {
  /**
   * Function that returns the component that the user is
   * redirected to after clicking on the generated link that
   * is sent to their email to reset their password.
   */

  const [searchParams, setSearchParams] = useSearchParams();
  const resetToken = searchParams.get("token");

  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // register - register inputs, handleSubmit - handle form submit, reset - reset the form, formState containing error
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // event handlers to track user's text input
  const newPasswordHandler = (event) => {
    setNewPassword(event.target.value);
  };

  const confirmNewPasswordHandler = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const sendResetPassword = (resetToken, newPassword) => {
    if ((resetToken, newPassword)) {
      dispatch(resetPasswordAuth({ token: resetToken, password: newPassword }));
    }
  };

  // access redux states
  const resetPasswordStatus = useResetPasswordStatus();
  const resetPasswordErrors = useResetPasswordErrors();
  const isResetPasswordTokenExpiredError =
    useIsResetPasswordTokenExpiredError();
  const isResetPasswordTokenInvalidError =
    useIsResetPasswordTokenInvalidError();

  useEffect(() => {
   

    if (resetPasswordStatus === "fulfilled") {
      // if reset-password api call is successful
      toast.success("Successful, your password has been changed.", {
        duration: 7000,
      });
      dispatch(completeResetPassword()); // reset states
      navigate("/login"); // navigate back to login page
    } else if (resetPasswordStatus === "pending") {
      // if reset-password api call is pending
    } else if (resetPasswordStatus === "rejected") {
      // if reset-password api call has failed/errored out
      if (isResetPasswordTokenExpiredError) {
      
        toast.error(
          "Expired token error: Please generate a new token by going to User Profile -> Forget Password.",
          { duration: 10000 }
        );
      } else if (isResetPasswordTokenInvalidError) {
      
        toast.error(
          "Invalid token error: Please use the unique link that is sent to your email.",
          { duration: 10000 }
        );
      }
    }
  }, [resetPasswordStatus]);

  const onSubmit = async () => {
    // when user clicks submit button, calls the reset-password api
    sendResetPassword(resetToken, newPassword);
  };

  const handlePressEnter = (e) => {
    /*
      Placed in confirm new password input box so submit function is 
      triggered when user presses the "enter" key.
    */
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="forget-password-redirect-container">
      <div className="forget-password-redirect-title">
        Please enter your new password.
      </div>

      
        {/* NEW PASSWORD INPUT BOX*/}
      <div className="forget-password-redirect-error">
        <input
          style={{
            borderRadius: "0.25rem",
            marginTop: "0.9375rem",
            backgroundColor: "aliceblue",
            border: "0.0625rem solid #d8e0ff",
            width: "21.25rem",
            height: "3.125rem",
            padding: "1.25rem",
          }}
          type="password"
          name="newPassword"
          placeholder="New Password"
          {...register("newPassword")}
          value={newPassword}
          onChange={newPasswordHandler}
        />
      
      {errors.newPassword && (
        <p data-testid="new-pw-message" style={{color:"red"}}>
          {errors.newPassword.message}
        </p>
        )}
      </div>

      {/* CONFIRM NEW PASSWORD INPUT BOX*/}
      <div className="forget-password-redirect-error">
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
          // marginBottom: "1.5rem",
        }}
        type="password"
        name="confirmNewPassword"
        placeholder="Confirm New Password"
        {...register("confirmNewPassword")}
        value={confirmNewPassword}
        onChange={confirmNewPasswordHandler}
        // onKeyDown={handlePressEnter}
      />
      {errors.confirmNewPassword && (
        <p data-testid="confirm-pw-message" style={{color:"red"}} >
          {errors.confirmNewPassword.message}
        </p>
        )}
      </div>

      <CustomButton
        className="basic"
        testId="forget-pw-submit-btn"
        content="Submit"
        clicked={handleSubmit(onSubmit)}
      ></CustomButton>

      <CustomButton
        className="back-button"
        testId="forget-pw-cancel-btn"
        content="Cancel"
        clicked={async () => {
          dispatch(completeResetPassword()); // reset states
          navigate("/login");
        }}
      ></CustomButton>
    </div>
  );
};

export default ForgetPasswordRedirect;
