// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import "components/Results/PrivateLeaderboard/PrivateLeaderboard.css";

import datetimeConverter from "utils/datetimeConverter";
import { BenchmarkRecordRow } from "components/Results/BenchmarkRecords";

const downloadCSV = (row) => {
  // Define the CSV header
  const teamHeaders = [
    "team_name", 
    "team_id",
  ];
  // Matches REACT_APP_PRIVATE_/REACT_APP_PUBLIC_ and ensures they have a value
  const additionalHeaders = Object.keys(process.env).filter((key) =>
    /^(REACT_APP_PRIVATE_|REACT_APP_PUBLIC_)\d+$/.test(key) && process.env[key]
  ).map((key) => process.env[key]); // Map to their values instead of keys
  const header = [...teamHeaders, ...additionalHeaders];
  
  // Combine header and rows into a single string
  const csvContent = [
    header.join(","), // Join header
    header.map((header) => row[header]).join(","), // Join row values and filter by header keys
  ].join("\n"); 

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a temporary download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.csv";

  // Trigger the download
  link.click();

  // Clean up the URL object
  URL.revokeObjectURL(link.href);
};

const PrivateLeaderboardTable = (props) => {
  /* 
   * Function to load leaderboard records and render the private leaderboard table component. 
  */
  const { results, mode, tableName } = props;
  return (
    <Box sx={{ paddingBottom: "20px" }}>
      <Paper>
        {/* TOOLBAR ON TOP OF THE TABLE */}
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "date" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <b>{tableName}</b>
          </Typography>
        </Toolbar>

        {/* TABLE */}
        <TableContainer style={{ overflowX: "initial" }}>
          <Table aria-labelledby="tableTitle" size="small">
            {/* TABLE HEADERS */}
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 3,
                width: "100%",
                backgroundColor: "#F2F2F2",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "3px solid grey",
                    width: 30,
                  }}
                >
                  Estimated Rank
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderBottom: "3px solid grey",
                  }}
                >
                  Team Name + Total Submissions
                </TableCell>


          {/* *****************PRIVATE SCORE 2 IS PUT AS PRIORITY TEMPORARILY */}
          {process.env.REACT_APP_DISPLAY_PRIVATE_2==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_2 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_0==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_0 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_1==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_1 }
          </TableCell>)}
          {/* {process.env.REACT_APP_DISPLAY_PRIVATE_2==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_2 }
          </TableCell>)} */}
          {process.env.REACT_APP_DISPLAY_PRIVATE_3==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_3 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_4==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_4 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_5==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_5 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_6==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_6 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_7==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_7 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_8==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_8 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PRIVATE_9==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PRIVATE_NAME_9 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_0==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_0 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_1==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_1 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_2==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_2 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_3==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_3 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_4==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_4 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_5==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_5 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_6==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_6 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_7==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_7 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_8==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_8 }
          </TableCell>)}
          {process.env.REACT_APP_DISPLAY_PUBLIC_9==="none"?null:(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            { process.env.REACT_APP_PUBLIC_NAME_9 }
          </TableCell>)}
          {(<TableCell
            sx={{
              fontWeight: "bold",
              borderBottom: "3px solid grey",
              width: 30,
            }}
          >
            csv
          </TableCell>)}

              </TableRow>
            </TableHead>

            {/* TABLE BODY */}
            <TableBody>
              {results?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                if (!row.team_name) {
                  // if record has no team name, it's a benchmark score (FOR PRIVATE_BENCHMARK_RECORDS)
                  return (
                    <BenchmarkRecordRow row={row} leaderboardType={"private"} />
                  );
                }

                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row?.team_id}>
                    <TableCell id={labelId}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {row?.ranking}
                      </div>
                    </TableCell>
                    <TableCell id={labelId}>
                      <div style={{ paddingTop: "10px", width: 450 }}>
                        <div style={{ color: "#ae2514" }}>
                          <b>{row?.team_name}</b>
                        </div>
                        <div
                          style={{
                            color: "grey",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        >
                          {mode === 2 && (
                            <div style={{ color: "black" }}>
                              {row?.picked_results}
                              {" Selected Submission(s)"}
                            </div>
                          )}
                          <div>
                            {row.successful_attempts}
                            {" Submission(s)"}
                          </div>
                          <div>
                            Best public score submission on:{" "}
                            {datetimeConverter(row.created_date)}
                          </div>
                        </div>
                      </div>
                    </TableCell>

                    {/* *****************PRIVATE SCORE 2 IS PUT AS PRIORITY TEMPORARILY */}
                    {process.env.REACT_APP_DISPLAY_PRIVATE_2==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_2]}
                        </div>
                      </TableCell>)}
                    {process.env.REACT_APP_DISPLAY_PRIVATE_0==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_0]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_1==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_1]}
                        </div>
                      </TableCell>)}
                      {/* {process.env.REACT_APP_DISPLAY_PRIVATE_2==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_2]}
                        </div>
                      </TableCell>)} */}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_3==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_3]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_4==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_4]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_5==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_5]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_6==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_6]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_7==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_7]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_8==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_8]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PRIVATE_9==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PRIVATE_9]}
                        </div>
                      </TableCell>)}
                    {process.env.REACT_APP_DISPLAY_PUBLIC_0==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_0]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_1==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_1]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_2==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_2]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_3==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_3]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_4==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_4]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_5==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_5]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_6==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_6]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_7==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_7]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_8==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_8]}
                        </div>
                      </TableCell>)}
                      {process.env.REACT_APP_DISPLAY_PUBLIC_9==="none"?null:(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.[process.env.REACT_APP_PUBLIC_9]}
                        </div>
                      </TableCell>)}
                      {(<TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          <button onClick={()=>downloadCSV(row)}>Download CSV</button>
                        </div>
                      </TableCell>)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export { PrivateLeaderboardTable };
