import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axiosAuth from "utils/axiosAuth";
// import axios from "utils/axios";

import axios from "axios";
import { backendDomain } from "utils/envConfig";

import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const initialState = {
  // loginLoading: false,
  // loginData: [],
  // isLoggedIn: false,

  // isUserVerified: false, // to track when user has successfully verified
  // verifyUserLoading: false, // to track when user verify api is in transit to the server
  // userUnverifiedLogin: false, // is true if an unverified user logins
  // userLoginErrors: [], // to track errors when login api is called
  getSubmitResultsStatus: null,
  getSubmitResultsHasError: false,
  getSubmitResultsErrors: [],
  submitResultsRecords: null,
};
const name = "submitResultsState";

const api = axios.create({
  baseURL: `${backendDomain}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

// get team aggregated submission-results
export const getSubmitResults = createAsyncThunk(
  `${name}/api/v0.1/submissions/team-aggregated`,
  async () => {
    try {
      const res = await api.get("/api/v0.1/submissions/team-aggregated");

      if (res.status >= 200 && res.status < 300) {
    
        return res.data;
      }
    } catch (err) {
      // toast.error("Error retrieving aggregated submission-results.");

    
      throw new Error(err.message); // throws error for builder to catch
    }
  }
);

const submitResultsSlice = createSlice({
  name,
  initialState,
  reducers: {
    // reducers are functions that modify state
    completeGetSubmitResults: (state) => {
      // localStorage.removeItem("token");
      // state.loginData = initialState.loginData;
      // state.isLoggedIn = initialState.isLoggedIn;
      // state.userUnverifiedLogin = initialState.userUnverifiedLogin;
      state.getSubmitResultsStatus = initialState.getSubmitResultsStatus;
      state.getSubmitResultsHasError = initialState.getSubmitResultsHasError;
      state.getSubmitResultsErrors = initialState.getSubmitResultsErrors;
    },
    // completeLogin: (state) => {
    //   state.userUnverifiedLogin = initialState.userUnverifiedLogin;
    //   state.userLoginErrors = initialState.userLoginErrors;
    // },
  },

  extraReducers: (builder) => {
    //handle login api call statuses
    builder.addCase(getSubmitResults.fulfilled, (state, action) => {
      state.getSubmitResultsStatus = "fulfilled";
      state.getSubmitResultsHasError = false;
      state.getSubmitResultsErrors = [];
      state.submitResultsRecords = action.payload;
      // state.isUserVerified = true;

      // if (payload) {
      //   state.isLoggedIn = true;
      // }
      // // reset to initial state
      // state.loginLoading = false;
      // state.userUnverifiedLogin = false;
      // state.userLoginErrors = [];
      // state.isVerificationLinkExpired = false;
    });
    builder.addCase(getSubmitResults.pending, (state) => {
      // state.loginLoading = true;
      state.getSubmitResultsStatus = "pending";
    });
    builder.addCase(getSubmitResults.rejected, (state, action) => {
      state.getSubmitResultsStatus = "rejected";
      state.getSubmitResultsHasError = true;
      state.submitResultsRecords = null;
    
      state.getSubmitResultsErrors.push(action.error.message);

    
    });
  },
});

export const { completeGetSubmitResults } = submitResultsSlice.actions;

export default submitResultsSlice.reducer;

//submit results selectors
export const useSubmitResultsRecords = () =>
  useSelector((state) => state.submitResultsState.submitResultsRecords);

export const useGetSubmitResultsStatus = () =>
  useSelector((state) => state.submitResultsState.getSubmitResultsStatus);

export const useGetSubmitResultsHasError = () =>
  useSelector((state) => state.submitResultsState.getSubmitResultsHasError);

export const useGetSubmitResultsErrors = () =>
  useSelector((state) => state.submitResultsState.getSubmitResultsErrors);

// export const useUserUnverifiedLogin = () =>
//   useSelector((state) => state.submitResultsState.userUnverifiedLogin);

// export const useIsLoggedIn = () =>
//   useSelector((state) => state.submitResultsState.isLoggedIn);

// export const useLoggedInData = () =>
//   useSelector((state) => state.submitResultsState.loginData);

// export const useLoginLoading = () =>
//   useSelector((state) => state.submitResultsState.loginLoading);
