import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomButton from "shared/CustomButton/CustomButton";
import toast from "react-hot-toast";
import "./RegisterForm.css";
import countries from 'components/Auth/countries.json'; 

import { useDispatch } from "react-redux";
import {
  completeRegister,
  registerAuth,
  useRegisterLoading,
  useRegisterStatus,
  useIsRegisterDuplicateUserError,
  useIsUserCreationFailedError,
  useIsUserRoleCreationFailedError,
} from "redux/registerSlice";

// const unsortedCountries = [
//   "United States",
//   "China",
//   "Germany",
//   "United Kingdom",
//   "Japan",
//   "South Korea",
//   "France",
//   "Canada",
//   "Israel",
//   "Sweden",
//   "Singapore",
//   "Switzerland",
//   "Russia",
//   "India",
//   "Australia",
// ];

// const allCountries = unsortedCountries.sort();

// get all the values within countries.json into an array
const allCountries = Object.values(countries['Country'])

const schema = yup.object().shape({
  username: yup
    .string()
    .required("*Username is required")
    .matches(
      /^[a-zA-Z0-9_]+$/,
      "*Username can only contain letters and numbers."
    ),
  email: yup.string().email("*Invalid email").required("*Email is required"),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "*Emails must match")
    .required("*Confirmation of Email is required"),
  password: yup
    .string()
    .min(6, "*Password must be at least 6 characters long")
    .required("*Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "*Passwords must match")
    .required("*Confirmation Password is required"),
  countryOfOrigin: yup
    .string()
    .oneOf(allCountries, "*Invalid country")
    .required("*Please include your Country of Origin"),
  fullName: yup
    .string()
    .required("*Full name is required")
    .matches(/^[a-zA-Z ]+$/, "*Full name can only contain letters."),
});

function RegisterForm() {
  // API Feedback? Register Fail or Please Verify Application Email
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();

  // Navigation after register attempt
  const navigateLogin = useNavigate();

  // register - register inputs, handleSubmit - handle form submit, reset - reset the form, formState containing error
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [countryOfOrigin, setCountryOfOrigin] = useState("");

  // form onchange event handlers
  const usernameHandler = (event) => {
    setUsername(event.target.value);
  };

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  const confirmEmailHandler = (event) => {
    setConfirmEmail(event.target.value);
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };
  const confirmPasswordHandler = (event) => {
    setConfirmPassword(event.target.value);
  };

  const fullNameHandler = (event) => {
    setFullName(event.target.value);
  };

  const organizationNameHandler = (event) => {
    setOrganizationName(event.target.value);
  };

  const countryOfOriginHandler = (event) => {
    setCountryOfOrigin(event.target.value);
  };

  // get state from redux store
  const registerLoading = useRegisterLoading();
  const registerStatus = useRegisterStatus();
  const isRegisterDuplicateUserError = useIsRegisterDuplicateUserError();
  const isUserCreationFailedError = useIsUserCreationFailedError();
  const isUserRoleCreationFailedError = useIsUserRoleCreationFailedError();

  // pass username to into url via query params
  const navigate = useNavigate();
  const queryParams = new URLSearchParams();

  useEffect(() => {
    // on registration complete, navigate to sendVerificationEmailPage
    if (registerStatus === "fulfilled") {
      toast.success(
        "Registration successful, a verification email has been sent to you."
      ); // toast messsage for registration success

      // construct url with username and email in queryParams to be used to send the verification email
      queryParams.set("username", username);
      queryParams.set("email", email);
      const sendVerificationEmailPageUrl = `/send-verification-email?${queryParams.toString()}`; // add query params to url

      // navigate to sendVerificationEmail page
      dispatch(completeRegister()); // reset states
      navigate(sendVerificationEmailPageUrl); // navigate to sendVerificationEmail page
    } else if (registerStatus === "pending") {
      // TODO: add spinner when registration is pending
    } else if (registerStatus === "rejected") {
      if (isRegisterDuplicateUserError) {
        toast.error("Registration failed: Username or Email is already taken."); // toast message for duplicate user registration error
      } else if (isUserCreationFailedError) {
        toast.error(
          "Registration failed: Something went wrong, please try again."
        ); // toast message for user creation failed error
      } else if (isUserRoleCreationFailedError) {
        toast.error("Registration failed: Username or Email is already taken."); // toast message for user role creation failed error
      }
    }
  }, [registerStatus]);

  const sendRegisterAuth = (
    username,
    email,
    fullName,
    organizationName,
    country_of_origin,
    password
  ) => {
    if (organizationName === "") {
      dispatch(
        registerAuth({
          username: username,
          email: email,
          full_name: fullName,
          organization: "No Organisation",
          country_of_origin: country_of_origin,
          password: password,
        })
      );
    }
    // function to dispatch register auth api call
    else if (
      username &&
      email &&
      fullName &&
      organizationName &&
      country_of_origin &&
      password
    ) {
      // dispatch form data to register API
      dispatch(
        registerAuth({
          username: username,
          email: email,
          full_name: fullName,
          organization: organizationName,
          country_of_origin: country_of_origin,
          password: password,
        })
      );
    }
  };

  const onRegistrationSubmit = async () => {
    sendRegisterAuth(
      username,
      email,
      fullName,
      organizationName,
      countryOfOrigin,
      password
    );
  };

  return (
    <div className="register-container">
      {/* TITLE */}
      <div className={"register-title"}>Sign up</div>

      {/* USERNAME FIELD*/}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="text"
        name="username"
        placeholder="Enter Username"
        {...register("username")}
        value={username}
        onChange={usernameHandler}
      />
      {errors.username && (
        <p
          data-testid="username-error-message"
          style={
            errors.username.message ===
            "*Username can only contain letters and numbers."
              ? { color: "red", marginLeft: "30px" }
              : { color: "red", marginRight: "9.875rem" }
          }
        >
          {errors.username.message}
        </p>
      )}

      {/* EMAIL FIELD*/}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="email"
        name="email"
        placeholder="Enter Email"
        {...register("email")}
        value={email}
        onChange={emailHandler}
      />
      {errors.email && (
        <p
          data-testid="email-error-message"
          style={{ color: "red", marginRight: "12.5rem" }}
        >
          {errors.email.message}
        </p>
      )}

      {/* CONFIRM EMAIL FIELD*/}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="email"
        name="confirmEmail"
        placeholder="Confirm Email"
        {...register("confirmEmail")}
        value={confirmEmail}
        onChange={confirmEmailHandler}
      />

      {errors.confirmEmail && (
        <p
          data-testid="confirm-email-error-message"
          style={
            errors.confirmEmail.message === "*Emails must match"
              ? { color: "red", marginRight: "10rem" }
              : { color: "red", marginRight: "5rem" }
          }
        >
          {errors.confirmEmail.message}
        </p>
      )}

      {/* FULL NAME FIELD*/}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="text"
        name="fullName"
        placeholder="Full Name"
        {...register("fullName")}
        value={fullName}
        onChange={fullNameHandler}
      />
      {errors.fullName && (
        <p
          data-testid="full-name-error-message"
          style={
            errors.fullName.message === "*Full name can only contain letters."
              ? { color: "red", marginRight: "5rem" }
              : { color: "red", marginRight: "10.2rem" }
          }
        >
          {errors.fullName.message}
        </p>
      )}

      {/* ORGANIZATION NAME FIELD*/}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="text"
        name="organizationName"
        placeholder="Organization Name (Optional)"
        {...register("organizationName")}
        value={organizationName}
        onChange={organizationNameHandler}
      />
      {errors.organizationName && (
        <p
          data-testid="organization-name-error-message"
          style={{ color: "red", marginRight: "5rem" }}
        >
          {errors.organizationName.message}
        </p>
      )}

      {/* COUNTRY OF ORIGIN FIELD*/}
      <select
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "0.5rem 1rem",
        }}
        name="countryOfOrigin"
        {...register("countryOfOrigin")}
        value={countryOfOrigin}
        onChange={countryOfOriginHandler}
      >
        <option value="" disabled selected>
          Country Of Origin
        </option>
        {allCountries.map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </select>
      {errors.countryOfOrigin && (
        <p
          data-testid="country-origin-error-message"
          style={{ color: "red", marginRight: "13rem" }}
        >
          {errors.countryOfOrigin.message}
        </p>
      )}

      {/* PASSWORD FIELD*/}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="password"
        name="password"
        placeholder="Enter Password"
        {...register("password")}
        value={password}
        onChange={passwordHandler}
      />
      {errors && errors.password && (
        <p data-testid="error-pw-message" style={{ color: "red" }}>
          {errors.password.message}
        </p>
      )}

      {/* CONFIRM PASSWORD FIELD*/}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
          // marginBottom: "1.25rem",
        }}
        type="password"
        name="confirmPassword"
        placeholder="Confirm password"
        {...register("confirmPassword")}
        value={confirmPassword}
        onChange={confirmPasswordHandler}
      />
      {errors.confirmPassword && (
        <p
          data-testid="confirm-pw-error-message"
          style={
            errors.confirmPassword.message === "*Passwords must match"
              ? { color: "red", marginRight: "9rem" }
              : { color: "red", marginRight: "4.375rem" }
          }
        >
          {errors.confirmPassword.message}
        </p>
      )}

      {/* SUBMIT BUTTON*/}
      <CustomButton
        className="basic"
        testId="register-submit-btn"
        content="Submit"
        clicked={handleSubmit(onRegistrationSubmit)}
      ></CustomButton>

      {/* BACK BUTTON*/}
      <CustomButton
        className="back-button"
        testId="register-back-btn"
        content="Back"
        clicked={async () => {
          navigateLogin("/login");
        }}
      ></CustomButton>
      {apiError && (
        <div className="alert alert-error shadow-lg">
          <span>{apiError}</span>
        </div>
      )}
    </div>
  );
}

export default RegisterForm;
