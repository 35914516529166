import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./ChangePasswordForm.css";
import CustomButton from "shared/CustomButton/CustomButton";
import toast from "react-hot-toast";
import { logOut } from "redux/loginSlice";
import { backendDomain } from "utils/envConfig";
import checkValidJsonParse from "utils/checkValidJsonParse";


const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("*Current password is required.")
    .min(6, "*Password must be at least 6 characters long"),

  newPassword: yup
    .string()
    .required("*New Password is required.")
    .min(6, "*Password must be at least 6 characters long"),

  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "*Passwords must match") // check if user new password and confirm new password match
    .required("*Confirmation Password is required."),
});

const ChangePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // register - register inputs, handleSubmit - handle form submit, reset - reset the form, formState containing error
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // block apis from loading if user is not logged in
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      localStorage.setItem("historyPath", "4");
      navigate("/login");
      return;
    }
  }, []);

  if (
    localStorage.getItem("token") === null ||
    localStorage.getItem("token") === ""
  ) {
    localStorage.setItem("historyPath", "4");
    return <Navigate to="/login" />;
  }

  // For POST Change Password
  const postChangePassword = async (oldPassword, newPassword) => {
    let data = JSON.stringify({
      old_password: oldPassword,
      password: newPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: data,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${backendDomain}/api/v0.1/auth/change-password`,
        requestOptions
      );

      if (!response.ok) {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
        return;
      }

      toast.success("Successful, your password has been changed.", {
        duration: 7000,
      });
      navigate("/user-profile");
      // else {
      //   toast.error("Error, current password is wrong.");
      // }
    } catch (error) {
      // toast.error("Error, something went wrong.");
      // throw new Error(err.response.data.detail["error code"]); // throws error for builder to catc
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong. The password change attempt has failed.")
        return
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.");
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.");
      } else if (parsed_error["error code"] === "AUTH-11024") {
        toast.error(
          "Error: Current password is incorrect. The password change attempt has failed."
        );
      } else if (parsed_error["error code"] === "AUTH-22019") {
        toast.error(
          "Error: Something went wrong. The password change attempt has failed."
        );
      } else {
        toast.error(
          "Error: Something went wrong. The password change attempt has failed."
        );
      }
    }
  };

  const onSubmit = async (data) => {
    postChangePassword(data.oldPassword, data.newPassword);
  };

  const handlePressEnter = (e) => {
    /*
      Placed in forget password input box so submit function is 
      triggered when user presses the "enter" key.
    */
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="change-password-container">
      <div className="change-password-title"> Create New Password</div>

      <div className="change-password-input-container">
        <div>
          <input
            className="change-password-input-general"
            type="password"
            name="name"
            placeholder="Current Password"
            {...register("oldPassword")}
            onKeyDown={handlePressEnter}
          />
        </div>
        {errors.oldPassword && (
          <p
            data-testid="old-pw-error-message"
            style={{
              color: "red",
              marginTop: "1rem",
              textAlign: "left",
              marginLeft: "1.85rem",
            }}
          >
            {errors.oldPassword.message}
          </p>
        )}
        <div>
          <input
            className="change-password-input-general"
            type="password"
            name="name"
            placeholder="Password"
            {...register("newPassword")}
            onKeyDown={handlePressEnter}
          />
        </div>
        {errors.newPassword && (
          <p
            data-testid="new-pw-error-message"
            style={{
              color: "red",
              marginTop: "1rem",
              textAlign: "left",
              marginLeft: "1.85rem",
            }}
          >
            {errors.newPassword.message}
          </p>
        )}

        <div>
          <input
            className="change-password-input-general"
            type="password"
            name="name"
            placeholder="Confirm Password"
            {...register("confirmNewPassword")}
            onKeyDown={handlePressEnter}
          />
        </div>
        {errors.confirmNewPassword && (
          <p
            data-testid="confirm-pw-error-message"
            style={{
              color: "red",
              marginTop: "1rem",
              textAlign: "left",
              marginLeft: "1.85rem",
            }}
          >
            {errors.confirmNewPassword.message}
          </p>
        )}

        <div className="button-container">
          <CustomButton
            className="basic"
            testId="confirm-changes-btn"
            content="Confirm Changes"
            clicked={handleSubmit(onSubmit)}
          ></CustomButton>

          {/* CANCEL BUTTON */}
          <CustomButton
            className="back-button"
            testId="user-profile-back-btn"
            content="Back"
            clicked={async () => {
              navigate("/user-profile");
            }}
          ></CustomButton>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
