import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { backendDomain } from "utils/envConfig";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const initialState = {
  teamCreateLoading: false,
  isTeamCreated: false,
};
const name = "createTeamState";

//create team
export const createTeam = createAsyncThunk(
  `${name}/api/v0.1/team/`,
  async ({ team_name }) => {
    let data = JSON.stringify({
      team_name: team_name,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${backendDomain}/api/v0.1/team/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: data,
    };
    try {
      const response = await axios.request(config);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Created team");
      } else {
        let errorResponse = await response.json();

      
        toast.error(
          "Create team failed here. " + errorResponse.error.toString()
        );
      }
    } catch (err) {
      toast.error("Error. Team name already exist.");
  
    }
  }
);

const createTeamSlice = createSlice({
  name,
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //handle create team api call statuses
    builder.addCase(createTeam.fulfilled, (state) => {
      state.isTeamCreated = true;

      state.teamCreateLoading = false;
    });
    builder.addCase(createTeam.pending, (state) => {
      state.teamCreateLoading = true;
    });
    builder.addCase(createTeam.rejected, (state) => {
      state.teamCreateLoading = false;
    });
  },
});

export const { completeCreateTeam } = createTeamSlice.actions;

export default createTeamSlice.reducer;

//create team complete selectors
export const useTeamCreated = () =>
  useSelector((state) => state.createTeamState.isTeamCreated);
