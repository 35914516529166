import React, { useState, useEffect } from "react";
import "./CreateTeam.css";
import CustomButton from "shared/CustomButton/CustomButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "redux/loginSlice";
import toast from "react-hot-toast";
import { backendDomain } from "utils/envConfig";

const CreateTeam = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [teamName, setChangeTeamName] = useState("");
  const [overlay, setOverlay] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const teamNameHandler = (event) => {
    setChangeTeamName(event.target.value);
  };

  useEffect(() => {
    // log user out if jwt doesn't exist in localStorage
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      localStorage.setItem("historyPath", "3");
      navigate("/login");
      return;
    }
  }, []);

  // log user out if jwt doesn't exist in localStorage
  if (
    localStorage.getItem("token") === null ||
    localStorage.getItem("token") === ""
  ) {
    localStorage.setItem("historyPath", "3");
    return <Navigate to="/login" />;
  }

  //after create team, press back button to re-render main dash

  const createTeam = async (teamName) => {

    var raw = JSON.stringify({
      team_name: teamName,
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
      redirect: "follow",
    };

    try {
      // console.log(backendDomain);
      const response = await fetch(
        `${backendDomain}/api/v0.1/team/`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Created team");
        setChangeTeamName("");
        navigate("/team-dashboard");
      } else {
        let errorResponse = await response.json();
        if (
          ["AUTH-11002", "AUTH-11021"].includes(
            errorResponse.detail["error code"]
          )
        ) {
          // user's jwt token is invalid or
          // toast.error("Error: Your session has timed out. Please re-login.");
          setOverlay(true);
          dispatch(logOut());
          toast(
            (t) => (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {"Error: Your session has timed out. Please re-login."}
                <button
                  className="create-team-btn-dismiss"
                  onClick={() => [setOverlay(false), toast.dismiss(t.id)]}
                >
                  Dismiss
                </button>
              </span>
            ),
            {
              duration: Infinity,
              data: {
                isTeamMgmtCustom: true,
              },
            }
          );
        } else {
          setOverlay(true);
          toast(
            (t) => (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {"Team creation unsuccessful as " +
                  (errorResponse?.detail["error message"]).toLowerCase() +
                  "."}
                <button
                  className="create-team-btn-dismiss"
                  onClick={() => [setOverlay(false), toast.dismiss(t.id)]}
                >
                  Dismiss
                </button>
              </span>
            ),
            {
              duration: Infinity,
              data: {
                isTeamMgmtCustom: true,
              },
            }
          );
        }
      }
    } catch (err) {
      toast.error("Error. Team name already exist.");
    }
  };

  return (
    <div className={"create-team-container"}>
      {overlay && <div className="create-team-toast-overlay"></div>}
      <div className="create-team-title">Create a Team</div>

      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "1.5625rem",
          marginBottom: "1.875rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="text"
        name="name"
        placeholder="Team Name"
        value={teamName}
        onChange={teamNameHandler}
      />

      {teamName.length === 0 && formSubmitted ? (
        <div
          data-testid="create-team-required"
          style={{
            marginRight: "16.25rem",
            paddingTop: "1.25rem",
            paddingBottom: "0.625rem",
            marginBottom: "0.3125rem",
            marginTop: "-0.9375rem",
            color: "red",
          }}
        >
          *required
        </div>
      ) : null}

      <CustomButton
        className="basic"
        testId="submit-team-name-btn"
        content="Submit Team Name"
        clicked={async () => {
          setFormSubmitted(true);

          if (teamName.length !== 0) {
            createTeam(teamName.trim());

            setFormSubmitted(false);
          }
        }}
      ></CustomButton>

      <CustomButton
        className="back-button"
        testId="team-dashboard-back-btn"
        content="Back"
        clicked={async () => {
          navigate("/team-dashboard");
        }}
      ></CustomButton>
    </div>
  );
};

export default CreateTeam;
