import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Define public benchmark records here (in 4 decimal places)
// - Public benchmark records are inserted into private leaderboard.
// - Set PUBLIC_BENCHMARK_RECORDS = [] (empty array) to not show the benchmark 
// - record in the public leaderboard.
const PUBLIC_BENCHMARK_RECORDS = [
  // {
  //   model_name: "GCG (Llama-2)",
  //   asr_hmean_public: "0.2251",
  //   asr_llama: "0.3800",
  //   asr_vicuna: "0.1600",
  // },
];

// Define private benchmark records here (in 4 decimal places)
// - Private benchmark records are inserted into private leaderboard
// - Set PRIVATE_BENCHMARK_RECORDS = [] (empty array) to not show the benchmark 
// - record in the private leaderboard.
const PRIVATE_BENCHMARK_RECORDS = [
  // {
  //   model_name: "GCG (Llama-2)",
  //   asr_hmean_private: "0.2777",
  //   avg_token_length_ratio: "0.8560",
  //   asr_secret_model_1: "0.5200",
  //   asr_hmean_public: "0.2251",
  //   asr_llama: "0.3800",
  //   asr_vicuna: "0.1600",
  // },
];

const sortedRecords = (records, arrayOfScoresToSort) => {
  /* 
   * Sorts an array of leaderboard records, with the baseline/benchmark records, 
   * based on the scores' key names defined, in order, in arrayOfScoresToSort.
  
   * Params:
   *   - records (Array<Object>): 
   *     - The unsorted array of public leaderboard records, with the benchmark/baseline records.
   *   - arrayOfScoresToSort (Array<string>): 
   *     - An array of key names of scores to sort by. The first score in the list will take highest scorting precedence. 
      
   * Returns:
   *   - sorted (Array<Object>):
   *     - An array of public leaderboard records, with the benchmark/baseline records correctly sorted within the array.
  */

  const sorted = records.sort((a, b) => {
    // JS .sort() compare function to sort
    // via the score names, in order, in arrayOfScoresToSort
    for (let key of arrayOfScoresToSort) {
      let diff = parseFloat(b[key]) - parseFloat(a[key]);
      // console.log(key, b[key],a[key], diff)
      if (diff !== 0) {
        return diff;
      }
    }
    return 0;
  });

  return sorted;
};

const BenchmarkRecordRow = (props) => {
  /* 
   * Function that renders the row of a benchmark ML model record on the leaderboard.
   * NOTE: this function has to be adjusted if the score names or the number of scores changes.
  */
  const { row, leaderboardType } = props;
  const benchmarkRowBackgroundColor = "#fdeadc"; // change benchmark record css background color here

  if (leaderboardType === "public") {
    // FOR PUBLIC LEADERBOARD: benchmark model's row component for public leaderboard (styling should align with public leaderboard)
    return (
      <TableRow
        key={row.model_name}
        style={{ backgroundColor: benchmarkRowBackgroundColor }}
      >
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            -
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div>
            <b>Baseline: </b>
            {row.model_name}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_hmean_public}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_llama}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_vicuna}
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (leaderboardType === "private") {
    // FOR PRIVATE LEADERBOARD: benchmark model's row component for private leaderboard (styling should align with private leaderboard)
    return (
      <TableRow
        key={row.model_name}
        style={{ backgroundColor: benchmarkRowBackgroundColor }}
      >
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            -
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div>
            <b>Baseline: </b>
            {row.model_name}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_hmean_private}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.avg_token_length_ratio}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_secret_model_1}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_hmean_public}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_llama}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.asr_vicuna}
          </div>
        </TableCell>
      </TableRow>
    );
  }
};

export {
  //   convertStringToFloat,
  PUBLIC_BENCHMARK_RECORDS,
  PRIVATE_BENCHMARK_RECORDS,
  sortedRecords,
  BenchmarkRecordRow,
};
