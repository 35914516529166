import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "./MainDash.css";
import { useNavigate } from "react-router-dom";
import CustomButton from "shared/CustomButton/CustomButton";
import { backendDomain } from "utils/envConfig";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { logOut } from "redux/loginSlice";

import checkValidJsonParse from "utils/checkValidJsonParse";

const MainDash = () => {
  const [data, setData] = useState([]);
  const [isTeamLead, setIsTeamLead] = useState(false);

  const [teamMemberRequestData, setTeamMemberRequestData] = useState([]);
  //team lead sorted data
  const [teamSortedData, setTeamSortedData] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [username, setChangeUsername] = useState("");
  const [teamDt, setTeamDt] = useState([]);
  const [teamRequestPhaseFlag, setTeamRequestPhaseFlag] = useState(null);

  const [loading, setLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);

  const [overlayTeamLead, setOverlayTeamLead] = useState(false);

  const [overlayRequest, setOverlayRequest] = useState(false);

  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const usernameHandler = (event) => {
    setChangeUsername(event.target.value);
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      localStorage.setItem("historyPath", "3");
      navigate("/login");
      // window.location.reload()
      return;
    }
    fetchUserProfile();
    getTeamRequestPhase();
    fetchTeamProfile();
  }, []);

  let teamProfile = teamDt?.team_name;

  function EnhancedTableHeadRequests() {
    return (
      <TableHead
        data-testid="team-table-requests-head"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          <TableCell
            sx={{
              fontWeight: "bold",

              width: "100",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Username
            </TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",

              width: "300",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Status
            </TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Action
            </TableHead>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const renderRequestsTable = () => {
    return (
      <Box sx={{ marginTop: "30px", paddingBottom: "20px" }}>
        <Paper>
          <TableContainer style={{ overflowX: "initial" }}>
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHeadRequests />

              <TableBody data-testid="team-table-requests-tbody">
                {teamSortedData?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      data-testid="team-table-requests-row"
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell id={labelId}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {row.username}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row.request_status}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row.request_status === "PENDING" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <CustomButton
                                testId="accept-invitation-btn"
                                className="invitation-action-button"
                                content="Approve"
                                clicked={() => [
                                  setOverlayTeamLead(true),

                                  toast(
                                    (t) => (
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignContent: "center",
                                        }}
                                      >
                                        {
                                          "Warning, this action is irreversible."
                                        }
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          <CustomButton
                                            testId="team-create-nav-btn"
                                            className="create-team-nav-button"
                                            content="Confirm"
                                            clicked={() => [
                                              setOverlayTeamLead(false),
                                              approveTeamMemberRequest(
                                                row.team_request_id
                                              ),
                                              toast.dismiss(t.id),
                                            ]}
                                          ></CustomButton>
                                          <CustomButton
                                            testId="dismiss-team-create-nav-btn"
                                            className="create-team-nav-button"
                                            content="Cancel"
                                            clicked={() => [
                                              setOverlayTeamLead(false),
                                              toast.dismiss(t.id),
                                            ]}
                                          ></CustomButton>
                                        </div>
                                      </span>
                                    ),
                                    {
                                      duration: Infinity,
                                      data: {
                                        isTeamMgmtCustom: true,
                                      },
                                    }
                                  ),
                                ]}
                              ></CustomButton>
                              <CustomButton
                                testId="accept-invitation-btn"
                                className="invitation-action-button"
                                content="Reject"
                                clicked={() => [
                                  setOverlayTeamLead(true),

                                  toast(
                                    (t) => (
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignContent: "center",
                                        }}
                                      >
                                        {
                                          "Warning, this action is irreversible."
                                        }
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          <CustomButton
                                            testId="proceed-request-btn"
                                            className="create-team-nav-button"
                                            content="Proceed"
                                            clicked={async () => [
                                              setOverlayTeamLead(false),
                                              rejectTeamRequest(
                                                row.team_request_id
                                              ),

                                              toast.dismiss(t.id),
                                            ]}
                                          ></CustomButton>
                                          <CustomButton
                                            testId="dismiss-request-btn"
                                            className="create-team-nav-button"
                                            content="Cancel"
                                            clicked={() => [
                                              setOverlayTeamLead(false),
                                              toast.dismiss(t.id),
                                            ]}
                                          ></CustomButton>
                                        </div>
                                      </span>
                                    ),
                                    {
                                      duration: Infinity,
                                      data: {
                                        isTeamMgmtCustom: true,
                                      },
                                    }
                                  ),
                                ]}
                              ></CustomButton>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  function EnhancedTableHeadInvitation() {
    return (
      <TableHead
        data-testid="team-table-invitations-head"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          <TableCell
            sx={{
              fontWeight: "bold",

              width: "100",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Team Name
            </TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",

              width: "300",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Status
            </TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Action
            </TableHead>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const renderInvitationsTable = () => {
    return (
      <Box sx={{ marginTop: "30px", paddingBottom: "20px" }}>
        <Paper>
          <TableContainer style={{ overflowX: "initial" }}>
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHeadInvitation />

              <TableBody data-testid="team-table-invitations-tbody">
                {teamMemberRequestData?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      data-testid="team-table-invitations-row"
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {row.is_invite === "True" && (
                        <>
                          <TableCell id={labelId}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {row.team_name}
                            </div>
                          </TableCell>

                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "3px",
                              }}
                            >
                              {row.request_status}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "3px",
                              }}
                            >
                              {row.request_status !== "REJECT" && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <CustomButton
                                    testId="accept-invitation-btn"
                                    className="invitation-action-button"
                                    content="Accept"
                                    clicked={() => [
                                      setOverlayRequest(true),

                                      toast(
                                        (t) => (
                                          <span
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              alignContent: "center",
                                            }}
                                          >
                                            {
                                              "Warning, this action is irreversible."
                                            }
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                              }}
                                            >
                                              <CustomButton
                                                testId="proceed-request-btn"
                                                className="create-team-nav-button"
                                                content="Proceed"
                                                clicked={async () => [
                                                  setOverlayRequest(false),
                                                  approveTeamLeadRequest(
                                                    row.team_request_id
                                                  ),

                                                  toast.dismiss(t.id),
                                                ]}
                                              ></CustomButton>
                                              <CustomButton
                                                testId="dismiss-request-btn"
                                                className="create-team-nav-button"
                                                content="Cancel"
                                                clicked={() => [
                                                  setOverlayRequest(false),
                                                  toast.dismiss(t.id),
                                                ]}
                                              ></CustomButton>
                                            </div>
                                          </span>
                                        ),
                                        {
                                          duration: Infinity,
                                          data: {
                                            isTeamMgmtCustom: true,
                                          },
                                        }
                                      ),
                                    ]}
                                  ></CustomButton>
                                  <CustomButton
                                    testId="accept-invitation-btn"
                                    className="invitation-action-button"
                                    content="Reject"
                                    clicked={() => [
                                      setOverlayRequest(true),

                                      toast(
                                        (t) => (
                                          <span
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              alignContent: "center",
                                            }}
                                          >
                                            {
                                              "Warning, this action is irreversible."
                                            }
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignContent: "center",
                                              }}
                                            >
                                              <CustomButton
                                                testId="proceed-request-btn"
                                                className="create-team-nav-button"
                                                content="Proceed"
                                                clicked={async () => [
                                                  setOverlayRequest(false),
                                                  rejectTeamInvite(
                                                    row.team_request_id
                                                  ),

                                                  toast.dismiss(t.id),
                                                ]}
                                              ></CustomButton>
                                              <CustomButton
                                                testId="dismiss-request-btn"
                                                className="create-team-nav-button"
                                                content="Cancel"
                                                clicked={() => [
                                                  setOverlayRequest(false),
                                                  toast.dismiss(t.id),
                                                ]}
                                              ></CustomButton>
                                            </div>
                                          </span>
                                        ),
                                        {
                                          duration: Infinity,
                                          data: {
                                            isTeamMgmtCustom: true,
                                          },
                                        }
                                      ),
                                    ]}
                                  ></CustomButton>
                                </div>
                              )}
                            </div>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  function EnhancedTableHead() {
    return (
      <TableHead
        data-testid="team-table-current-members-head"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          <TableCell
            sx={{
              fontWeight: "bold",

              width: "100%",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Role
            </TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",

              width: "300",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Username
            </TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
            }}
          >
            <TableHead
              style={{ display: "block", textAlign: "center", width: "100%" }}
            >
              Full Name
            </TableHead>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const renderCurrentMembersTable = () => {
    return (
      <Box sx={{ marginTop: "30px", paddingBottom: "20px" }}>
        <Paper>
          <TableContainer style={{ overflowX: "initial" }}>
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead />
              <TableBody data-testid="team-table-current-member-tbody">
                {data?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      data-testid="team-table-current-member-row"
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell id={labelId}>
                        <div
                          data-testid="team-table-cell"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {row.is_lead ? "Leader" : "Participant"}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row.username}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row.full_name}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  //get submission history
  const inviteTeamMember = async (username) => {
    getTeamRequestPhase();

    var raw = JSON.stringify({
      username: username,
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${backendDomain}/api/v0.1/team/member`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Successfully sent invite.");
      } else {
        // toast.error("Error in rejecting team request");
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      // console.log(err);
      console.log("Error inviting team member");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Inviting team member failed.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Error: Inviting team member failed.", {
          id: parsed_error["error code"],
        });
      }
    }
  };

  const getTeamRequestPhase = async () => {
    let url = `${backendDomain}/api/v0.1/team/team-request-phase`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const res = await response.json();

        setTeamRequestPhaseFlag(res.setting_value);
        return;
      } else {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      console.log("Error getting team request phase");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Getting team request phase failed.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Error: Getting team request phase failed.", {
          id: parsed_error["error code"],
        });
      }
    }
  };

  const fetchUserProfile = async () => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/auth/profile`;

    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const res = await response.json();

        setIsTeamLead(res.is_lead);
      } else {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      console.log("Error getting user profile");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Fetching user profile failed.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Error: Fetching user profile failed.", {
          id: parsed_error["error code"],
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchTeamProfile = async () => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/team/team-profile`;

    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const res = await response.json();

        setTeamDt(res);
      } else {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      // console.log(error);
      console.log("Error getting all team profile");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Getting all team profile failed.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log(parsed_error);
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        // toast.error("Error: Getting all team profile failed.", {
        //   id: parsed_error["error code"],
        // });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchTeamMembers = async () => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/team/member`;

    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();
        const message = response_data.result;

        setData(message);
      } else {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      console.log("Error getting all team members");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log(parsed_error);
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        // toast.error("Error: Fetching team members failed.", {
        //   id: parsed_error["error code"],
        // });
      }
    } finally {
      setLoading(false);
    }
  };

  const getAllTeamRequest = async () => {
    getTeamRequestPhase();

    let url = `${backendDomain}/api/v0.1/team/request?ignore=false&status=PENDING,COMPLETED,REJECT`;
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();

        const data = response_data.result;
        const res = data.filter((data) => data.is_invite === "False");

        setTeamSortedData(res);
      } else {
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      console.log("Error getting all team request");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Getting all team request failed.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log(parsed_error);
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        // toast.error("Error: Getting all team request failed.", {
        //   id: parsed_error["error code"],
        // });
      }
    }
  };

  // for participant to accept team lead request
  const getAllTeamLeadRequest = async () => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/team/user-queue?status=PENDING,COMPLETED,REJECT`;
    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();
        const res = response_data.result;

        const sortedRes = res.filter((data) => data.is_invite === "True");

        setTeamMemberRequestData(sortedRes);

        return res;
      } else {
        // if api returns an error response
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      }
    } catch (error) {
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Getting all team lead request failed.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      // console.log(parsed_error);
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const approveTeamLeadRequest = async (requestId) => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/team/request/` + requestId;
    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        getAllTeamLeadRequest();
        fetchTeamMembers();
        fetchTeamProfile();
        window.location.reload();
      } else {
        // if api returns an error response
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      }
    } catch (error) {
      console.log("Error approving team lead request");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Approving team lead request failed.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Error: Approving team lead request failed.", {
          id: parsed_error["error code"],
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const approveTeamMemberRequest = async (requestId) => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/team/request/` + requestId;
    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        getAllTeamRequest();
        fetchTeamMembers();
        fetchTeamProfile();
        toast.success("Accepted request");
      } else {
        // if api returns an error response
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      }
    } catch (error) {
      console.log("Error approving team member request");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Approving team member request failed.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Error: Approving team member request failed.", {
          id: parsed_error["error code"],
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const rejectTeamInvite = async (requestId) => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/team/invite/` + requestId + "/reject";
    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        getAllTeamLeadRequest();
        getAllTeamRequest();
        toast.success("Rejected Team Invite");
      } else {
        // toast.error("Error in rejecting team invite");
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      console.log("Error in rejecting team invite");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Rejecting team invite failed.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Error: Rejecting team invite failed.", {
          id: parsed_error["error code"],
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const rejectTeamRequest = async (requestId) => {
    getTeamRequestPhase();
    let url = `${backendDomain}/api/v0.1/team/request/` + requestId + "/reject";
    try {
      setLoading(true);
      let response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        getAllTeamRequest();
        fetchTeamMembers();
        window.location.reload();
      } else {
        // toast.error("Error in rejecting team request");
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail));
      }
    } catch (error) {
      // console.log(error);
      console.log("Error rejecting team request");
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Rejecting team request failed.", {
          id: "json_parse_error",
        });
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11002") {
        // user's jwt token is invalid or
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else if (parsed_error["error code"] === "AUTH-11021") {
        // jwt token has expired
        dispatch(logOut());
        navigate("/login");
        toast.error("Error: Your session has timed out. Please re-login.", {
          id: parsed_error["error code"],
        });
      } else {
        toast.error("Error: Rejecting team request failed.", {
          id: parsed_error["error code"],
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      localStorage.setItem("historyPath", "3");
      navigate("/login");
      return;
    }

    fetchTeamMembers();
    getAllTeamRequest();
    getAllTeamLeadRequest();
  }, []);

  // useEffect(() => {
  //   if (token === null || token === "") {
  //     localStorage.setItem("historyPath", "3");
  //     navigate("/login");
  //   }
  // }, []);

  if (
    localStorage.getItem("token") === null ||
    localStorage.getItem("token") === ""
  ) {
    localStorage.setItem("historyPath", "3");
    return <Navigate to="/login" />;
  }

  const renderTeamLeadPending = () => {
    return (
      <div className="main-dash-title">
        <h1 style={{ fontSize: "1.25rem" }}>
          <b>Requests</b>
        </h1>
        {teamSortedData.length === 0 ? (
          <div
            data-testid="team-table-no-requests"
            style={{
              marginTop: "10px",
              marginBottom: "25px",
              fontSize: "18px",
              color: "black",
            }}
          >
            - No Requests -
          </div>
        ) : (
          <>{renderRequestsTable()}</>
        )}
      </div>
    );
  };

  const checkerForIncomingInvitations = async () => {
    const res = await getAllTeamLeadRequest();
    let navFlagStop = false;
    if (res && Array.isArray(res)) {
      for (const row of res) {
        if (row.request_status === "PENDING" && row.is_invite === "True") {
          navFlagStop = true;
          setOverlay(true);
          toast(
            (t) => (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {
                  "WARNING: You have new or pending invitations. Page will refresh for you to view them first."
                }
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <CustomButton
                    testId="team-create-nav-btn"
                    className="create-team-nav-button"
                    content="Ok"
                    clicked={async () => [
                      setOverlay(false),

                      toast.dismiss(t.id),
                      window.location.reload(),
                    ]}
                  ></CustomButton>
                </div>
              </span>
            ),
            {
              duration: Infinity,
              data: {
                isTeamMgmtCustom: true,
              },
            }
          );
          break;
        }
      }
    }

    if (navFlagStop === false) {
      navigate("/team-create");
    }
  };

  const renderTeamMemberPending = () => {
    return (
      <div style={{ marginTop: "1.5rem" }}>
        <h1>Invitations</h1>
        {teamMemberRequestData.length === 0 ? (
          <div
            data-testid="team-invitations-no-table"
            style={{
              marginTop: "10px",
              marginBottom: "25px",
              fontSize: "18px",
              color: "black",
            }}
          >
            - No invitations -
          </div>
        ) : (
          <>{renderInvitationsTable()}</>
        )}
      </div>
    );
  };

  const renderTeamLeadCurrentMembers = () => {
    return (
      <>
        <div className="invite-team-title">
          <h1 style={{ fontSize: "1.25rem" }}>
            <b>Your Team Name:</b>
          </h1>
          {teamProfile ? (
            teamProfile
          ) : (
            <div
              style={{ marginTop: "10px", fontSize: "18px", color: "black" }}
            >
              - None -
            </div>
          )}
        </div>

        <h1 style={{ fontSize: "1.25rem" }}>
          <b>Current Members</b>
        </h1>
        {!teamProfile ? (
          <div
            data-testid="team-current-member-no-table"
            style={{
              marginTop: "5px",
              marginBottom: "10px",
              fontSize: "16px",
              color: "black",
            }}
          >
            - No members -
          </div>
        ) : (
          <div
            className="ul-table"
            data-testid="team-current-member-with-table"
          >
            {renderCurrentMembersTable()}
          </div>
        )}
      </>
    );
  };

  const renderTeamLeadUI = () => {
    return (
      <div className={"invite-team-container"}>
        <div className="invite-title">
          <h1 style={{ fontSize: "1.25rem" }}>
            <b> Invite Participant to Join Team</b>
          </h1>
        </div>
        <input
          style={{
            borderRadius: "0.25rem",
            marginTop: "1.5625rem",
            marginBottom: "1.875rem",
            backgroundColor: "aliceblue",
            border: "0.0625rem solid #d8e0ff",
            width: "21.25rem",
            height: "3.125rem",
            padding: "1.25rem",
            fontSize: "16px",
          }}
          type="text"
          name="name"
          placeholder="Team Member Username"
          value={username}
          onChange={usernameHandler}
        />

        {username?.length === 0 && formSubmitted ? (
          <div
            data-testid="main-dash-team-required"
            style={{
              marginRight: "16.25rem",
              paddingTop: "1.25rem",
              paddingBottom: "0.625rem",
              marginBottom: "0.3125rem",
              marginTop: "-0.9375rem",
              color: "red",
            }}
          >
            *required
          </div>
        ) : null}

        <CustomButton
          className="basic"
          testId="invite-team-member-btn"
          content="Invite Team Member"
          clicked={async () => {
            setFormSubmitted(true);
            inviteTeamMember(username);
          }}
        ></CustomButton>
      </div>
    );
  };

  return (
    <div className="MainDash">
      {overlay && <div className="create-team-nav-toast-overlay"></div>}
      {overlayTeamLead && (
        <div className="team-lead-confirmation-toast-overlay"></div>
      )}

      {overlayRequest && <div className="request-toast-overlay"></div>}
      {loading ? (
        <div className="spinner" style={{ marginTop: "50px" }}>
          <Spinner size={50} />
        </div>
      ) : (
        <>
          <h1>Team Dashboard</h1>

          {teamRequestPhaseFlag === "True" ? (
            <>
              {renderTeamLeadCurrentMembers()}
              <div>
                {isTeamLead
                  ? renderTeamLeadPending()
                  : renderTeamMemberPending()}
              </div>
              <>
                {/* {teamLeadExist ? ( */}
                {isTeamLead ? (
                  renderTeamLeadUI()
                ) : (
                  <>
                    {!teamProfile && (
                      <>
                        <CustomButton
                          className="basic"
                          testId="create-team-btn"
                          content="Create a team"
                          clicked={async () => {
                            checkerForIncomingInvitations();
                          }}
                        ></CustomButton>
                        <CustomButton
                          className="basic"
                          testId="join-team-btn"
                          content="Join a team"
                          clicked={async () => {
                            navigate("/team-join");
                          }}
                        ></CustomButton>
                      </>
                    )}
                  </>
                )}
              </>
            </>
          ) : (
            <>
              <div className="main-dash-border">
                <div className="main-dash-title-box">
                  Team formation is currently disabled at this phase of the
                  challenge. However, you may still view your team in the table
                  below.
                </div>
              </div>
              {renderTeamLeadCurrentMembers()}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MainDash;
