import React from "react"; //,{ useState, useEffect, useRef }
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
// import AnnouncementIcon from "@mui/icons-material/Announcement";
import AnnouncementRoundedIcon from "@mui/icons-material/AnnouncementRounded";
// import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";

function AggregatedStatusIcon(props) {
  /*
   * Functional component that handles the rendering of the icon of the aggregated_status
   * on the participants submissions table
   */
  const {
    statusType,
    // row,
    errorTooltipMessage,
  } = props;
  // console.log(props)
  // console.log(statusType);

  // define status icons styles
  const runningStyle = {
    backgroundColor: "#DBEDFD",
    color: "#2C97F4",
    fontWeight: "bold",
  };
  const errorStyle = {
    backgroundColor: "#FEE9E9",
    color: "#F05959",
    fontWeight: "bold",
  };
  const inQueueStyle = {
    backgroundColor: "#FFF4DA",
    color: "#EBA011",
    fontWeight: "bold",
  };

  // define object mapping of statusType to Chip component details
  const chipDetailsMap = {
    running: {
      label: "Running",
      style: runningStyle,
    },
    sysError: {
      label: "SysError",
      style: errorStyle,
    },
    userError: {
      label: "Error",
      style: errorStyle,
    },
    inQueue: {
      label: "In Queue",
      style: inQueueStyle,
    },
  };

  // handle rendering of userError icons
  if (statusType === "userError") {

    return (
      <Tooltip
        title={errorTooltipMessage}
        slotProps={{
          // adjust distance of tooltip message from anchor
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}
      >
        <div
          style={{
            height: "2.25em",
          }}
        >
          <Chip
            label={chipDetailsMap[statusType]["label"]}
            style={chipDetailsMap[statusType]["style"]}
          />
          <div
            style={{
              position: "relative" /* Make the container position relative */,
              top: "-3.5em" /* Adjust the top position to make it appear as a superscript */,
              left: "2.25em" /* Adjust the left position if needed */,
            }}
          >
            <span>
              <AnnouncementRoundedIcon
                style={{ color: chipDetailsMap[statusType]["style"].color, fontSize: "2em" }}
              />
            </span>
          </div>
        </div>
      </Tooltip>
    );
  }

  // handle rendering of other icons (i.e. non userError)
  return (
    <Chip
      label={chipDetailsMap[statusType]["label"]}
      style={chipDetailsMap[statusType]["style"]}
    />
  );
}

export default AggregatedStatusIcon;
