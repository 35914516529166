import React, { useState } from "react";
import { toast, Toaster, ToastBar } from "react-hot-toast";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./AppNavigator.css";
import Logo from "assets/ailogowhite.png";
import { FaChevronDown } from "react-icons/fa";
import { useLoggedInData } from "redux/loginSlice";
import ToasterMain from "components/AppNavigator/ToasterMain";
// page components
import {
  ChallengeInformation,
  ChallengeStructure,
  TechnicalDetails,
  Background,
  TermsAndConditions,
  // AnnexA,
} from "components/Faq";
import {
  UserProfile,
  LoginForm,
  ForgetLoginForm,
  RegisterForm,
  UserList,
  UserVerifyRedirect,
  SendVerificationEmail,
  ForgetPasswordRedirect,
  ChangePasswordForm,
} from "components/Auth";

import {
  TeamDashboard,
  CreateTeam,
  TeamJoin,
  InviteTeam,
} from "components/Team";
import PublicLeaderboard from "components/Results/PublicLeaderboard/PublicLeaderboard";
// import PublicLeaderboardPlaceholder from "components/Results/PublicLeaderboard/PublicLeaderboardPlaceholder";
import PrivateLeaderboard from "components/Results/PrivateLeaderboard/PrivateLeaderboard";
import SubmitCreate from "components/Submit/SubmitCreate";
import AdminTeamMng from "components/Admin/AdminTeamMng";
import AdminSubmission from "components/Admin/AdminSubmission";

const AppNavigator = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isChallengeExpanded, setIsChallengeExpanded] = useState(false);

  const loginData = useLoggedInData();
  const findRole = loginData?.payload?.role;
  const findRole2 = localStorage.getItem("role");

  // Handle click event for the link
  const triggerReloadIfOnSamePath = (targetURL) => {
    /*
     * Function to trigger a page reload if user is on the same path as the navbar item's path that they click on.
     * E.g. if user is on the path '/submit-create' and they click 'Submissions' in the navbar, it will trigger a reload of the page.
     * This reload is mainly used to check that the JWT token still exist/valid and log the user out if it doesn't.
     */
    // Check if the link is already active
    if (window.location.pathname === targetURL) {
      // Navigate to the new path to force a re-mount of the component
      window.location.href = targetURL;
    }
    return;
  };

  const challengeDetailsDropdownComponents = () => {
    return (
      <>
        <Link to="/">Challenge Information</Link>
        <Link to="/background">Background</Link>
        <Link to="/challenge-overview">Challenge Overview</Link>
        <Link to="/technical-details">Technical Details</Link>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
        {/* <Link to="/annex-a">Annex A</Link> */}
      </>
    );
  };

  return (
    <div className="the-big-container">
      <Router>
        <nav className="nav-styling">
          {/* <Toaster /> */}
          {ToasterMain()}
          
          <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            <img className="nav-image-styling" src={Logo} alt="ailogo"></img>
          </Link>

          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {/* Challenge Details Dropdown (Hamburger Version) */}
          {isNavExpanded && (
            <div className="nav-burger">
              <Link to="/" className="nav-link-styling-ham">
                <ul className="dropdown-ham">
                  <a
                    href="#"
                    className="drpbtn-ham"
                    onClick={() => {
                      setIsChallengeExpanded(!isChallengeExpanded);
                    }}
                  >
                    Challenge Details
                  </a>
                  <span style={{ marginLeft: "0.625rem" }}>
                    <FaChevronDown />
                  </span>
                  {isChallengeExpanded && (
                    <div className="drpmenu-ham">
                      {challengeDetailsDropdownComponents()}
                    </div>
                  )}
                </ul>
              </Link>
              <Link
                to="/leaderboard"
                onClick={() => triggerReloadIfOnSamePath("/leaderboard")}
                className="nav-link-styling-ham"
              >
                Public Leaderboard
              </Link>

              {findRole === "admin" || findRole2 === "admin" ? (
                <Link
                  to="/leaderboard-private"
                  onClick={() =>
                    triggerReloadIfOnSamePath("/leaderboard-private")
                  }
                  className="nav-link-styling"
                >
                  Private Leaderboard
                </Link>
              ) : null}

              {findRole === "admin" || findRole2 === "admin" ? (
                <Link
                  to="/admin-submission"
                  onClick={() => triggerReloadIfOnSamePath("/admin-submission")}
                  className="nav-link-styling"
                >
                  Submissions
                </Link>
              ) : (
                <Link
                  to="/submit-create"
                  onClick={() => triggerReloadIfOnSamePath("/submit-create")}
                  className="nav-link-styling-ham"
                >
                  Submissions
                </Link>
              )}

              {findRole === "admin" || findRole2 === "admin" ? (
                <Link
                  to="/admin-team-mng"
                  onClick={() => triggerReloadIfOnSamePath("/admin-team-mng")}
                  className="nav-link-styling"
                >
                  Team Management
                </Link>
              ) : (
                <Link
                  to="/team-dashboard"
                  onClick={() => triggerReloadIfOnSamePath("/team-dashboard")}
                  className="nav-link-styling-ham"
                >
                  Team Management
                </Link>
              )}
              <Link
                to="/user-profile"
                onClick={() => triggerReloadIfOnSamePath("/user-profile")}
                className="nav-link-styling-ham"
              >
                User Profile
              </Link>
            </div>
          )}

          {/* Challenge Details Dropdown */}
          <Link to="/" className="nav-link-styling">
            <ul className="dropdown">
              <a href="#" className="drpbtn">
                Challenge Details
              </a>
              <span style={{ marginLeft: "0.625rem" }}>
                <FaChevronDown />
              </span>
              <div className="drpmenu">
                {challengeDetailsDropdownComponents()}
              </div>
            </ul>
          </Link>
          <Link
            to="/leaderboard"
            onClick={() => triggerReloadIfOnSamePath("/leaderboard")}
            className="nav-link-styling"
          >
            Public Leaderboard
          </Link>

          {findRole === "admin" || findRole2 === "admin" ? (
            <Link
              to="/leaderboard-private"
              onClick={() => triggerReloadIfOnSamePath("/leaderboard-private")}
              className="nav-link-styling"
            >
              Private Leaderboard
            </Link>
          ) : null}

          {findRole === "admin" || findRole2 === "admin" ? (
            <Link
              to="/admin-submission"
              onClick={() => triggerReloadIfOnSamePath("/admin-submission")}
              className="nav-link-styling"
            >
              Submissions
            </Link>
          ) : (
            <Link
              to="/submit-create"
              onClick={() => triggerReloadIfOnSamePath("/submit-create")}
              className="nav-link-styling"
            >
              Submissions
            </Link>
          )}

          {findRole === "admin" || findRole2 === "admin" ? (
            <Link
              to="/admin-team-mng"
              onClick={() => triggerReloadIfOnSamePath("/admin-team-mng")}
              className="nav-link-styling"
            >
              Team Management
            </Link>
          ) : (
            <Link
              to="/team-dashboard"
              onClick={() => triggerReloadIfOnSamePath("/team-dashboard")}
              className="nav-link-styling"
            >
              Team Management
            </Link>
          )}

          <Link
            to="/user-profile"
            onClick={() => triggerReloadIfOnSamePath("/user-profile")}
            className="nav-link-styling"
          >
            User Profile
          </Link>
        </nav>

        <Routes>
          <Route exact path="/" element={<ChallengeInformation />} />
          <Route exact path="/background" element={<Background />} />
          <Route
            exact
            path="/technical-details"
            element={<TechnicalDetails />}
          />
          <Route
            exact
            path="/challenge-overview"
            element={<ChallengeStructure />}
          />
          {/* <Route exact path="/pnt" element={<PrizesAndTimeline />} /> */}
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          {/* <Route
            exact
            path="/annex-a"
            element={<AnnexA/>}
          /> */}
          <Route exact path="/leaderboard" element={<PublicLeaderboard />} />
          {/* <Route exact path="/leaderboard" element={<PublicLeaderboardPlaceholder />} /> */}

          <Route
            exact
            path="/leaderboard-private"
            element={<PrivateLeaderboard />}
          />
          <Route exact path="/admin-submission" element={<AdminSubmission />} />
          <Route exact path="/admin-team-mng" element={<AdminTeamMng />} />

          {/* DISABLE REGISTRATION FOR 2B by commenting out next line */}
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/user-list" element={<UserList />} />
          <Route path="/forget-login" element={<ForgetLoginForm />} />
          <Route path="/change-password" element={<ChangePasswordForm />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/submit-create" element={<SubmitCreate />} />
          <Route path="/team-dashboard" element={<TeamDashboard />} />
          <Route path="/team-create" element={<CreateTeam />} />
          <Route path="/team-join" element={<TeamJoin />} />
          <Route path="/invite-team" element={<InviteTeam />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/user-verify-redirect" element={<UserVerifyRedirect />} />
          <Route
            path="/send-verification-email"
            element={<SendVerificationEmail />}
          />
          <Route
            path="/forget-password-redirect"
            element={<ForgetPasswordRedirect />}
          />

          {/* redirect user to ChallengeInformation page if they enter an invalid url */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* </ErrorBoundary> */}
      </Router>
    </div>
  );
};

export default AppNavigator;
