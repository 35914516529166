import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const initialState = {
  registerLoading: false,
  // isRegisterCreated: false,
  registerStatus: null,
  isRegisterDuplicateUserError: false, // to track error returned when user attempt to register an account with duplicate information
  isUserCreationFailedError: false, // to track error returned when user creation fails
  isUserRoleCreationFailedError: false, // to track error returned when user role creation fails
};
const name = "registerState";

//register
export const registerAuth = createAsyncThunk(
  `${name}/api/v0.1/auth/register`,
  async ({ username, email, full_name, organization, country_of_origin, password }) => {
   
    try {
      const response = await axios.post("/api/v0.1/auth/register", {
        username: username,
        email: email,
        full_name: full_name,
        organization: organization,
        country_of_origin: country_of_origin,
        password: password,
      });

      if (response.status >= 200 && response.status < 300) {
   
      } else {
        toast.error("Failed to register");
      }
    } catch (err) {
      // toast.error("Registration failed");
     
      throw new Error(err.response.data.detail["error code"]); // throw error for reducer to catch
    }
  }
);

const registerSlice = createSlice({
  name,
  initialState,
  reducers: {
    completeRegister: (state) => {
      // state.isRegisterCreated = initialState.isRegisterCreated;
      state.registerLoading = initialState.registerLoading;
      state.registerStatus = initialState.registerStatus;
      state.isRegisterDuplicateUserError =
        initialState.isRegisterDuplicateUserError;
      state.isRegisterDuplicateUserError =
        initialState.isUserCreationFailedError;
      state.isRegisterDuplicateUserError =
        initialState.isUserRoleCreationFailedError;
    },
  },

  extraReducers: (builder) => {
    //handle register api call statuses
    builder.addCase(registerAuth.fulfilled, (state) => {
   
      state.registerStatus = "fulfilled";
      state.registerLoading = false;
      state.isRegisterDuplicateUserError = false;
      state.isUserCreationFailedError = false;
      state.isUserRoleCreationFailedError = false;

    });
    builder.addCase(registerAuth.pending, (state) => {
      state.registerLoading = true;
      state.registerStatus = "pending";
    });
    builder.addCase(registerAuth.rejected, (state, action) => {
      state.registerLoading = false;
      state.registerStatus = "rejected";
      if (action.error.message === "AUTH-11007") {
        // if duplicate registration info error, set state accordingly
        state.isRegisterDuplicateUserError = true;
      } else if (action.error.message === "AUTH-22008") {
        // if user creation error, set state accordingly
        state.isUserCreationFailedError = true;
      } else if (action.error.message === "AUTH-22009") {
        // if user role creation error, set state accordingly
        state.isUserRoleCreationFailedError = true;
      }
    });
  },
});

export const { completeRegister } = registerSlice.actions;

export default registerSlice.reducer;

export const useRegisterLoading = () =>
  useSelector((state) => state.registerState.registerLoading);

// export const useRegisterCreated = () =>
//   useSelector((state) => state.registerState.isRegisterCreated);

export const useRegisterStatus = () =>
  useSelector((state) => state.registerState.registerStatus);

export const useIsRegisterDuplicateUserError = () =>
  useSelector((state) => state.registerState.isRegisterDuplicateUserError);

export const useIsUserCreationFailedError = () =>
  useSelector((state) => state.registerState.isUserCreationFailedError);

export const useIsUserRoleCreationFailedError = () =>
  useSelector((state) => state.registerState.isUserRoleCreationFailedError);
