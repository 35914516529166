import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import axiosAuth from "utils/axiosAuth";
import { backendDomain } from "utils/envConfig";

const initialState = {
  loginLoading: false,
  loginData: [],
  isLoggedIn: false,
  registerLoading: false,
  isRegisterCreated: false,
  teamCreateLoading: false,
  isTeamCreated: false,
  getUserListLoading: false,
  getUserListData: [],
  isGetUserListCreated: false,

  isUserVerified: false, // to track when user has successfully verified
  verifyUserLoading: false, // to track when user verify api is in transit to the server
  userUnverifiedLogin: false, // is true if an unverified user logins
  userLoginErrors: [], // to track errors when login api is called

  resetVerificationSuccess: false, // to track when user has successfully reset his email verification
  resetVerificationLoading: false, // to track when reset-verify api is in transit to the server
  isVerificationLinkExpired: false, // to track when user's verification link has expired

  isForgetPasswordLoading: false, // to track when forget-password api is in transit to the server
  isForgetPasswordSuccess: false, // to track when forget-password api call is successfully complete
  forgetPasswordErrors: [], // to track when an error occurred when calling forget-password
  isForgetPasswordUserNotFoundError: false, // to track when user's email/username not found when calling forget-password api

  resetPasswordStatus: null, // track the status of the reset-password api call
  resetPasswordErrors: [], // to track when an error occurred when calling reset-password api
  isResetPasswordTokenExpiredError: false, // to track when the token has expired when calling reset-password api
  isResetPasswordTokenInvalidError: false, // to track when the token is invalid when calling reset-password api

  changePasswordStatus: null, // track the status of the change-password api call
  changePasswordErrors: [], // to track when an error occurred when calling change-password api
  changePasswordHasError: false, // to track if any error exists when calling change-password api
  isOldPasswordNotMatch: false, // to track when user enters the wrong old password
  isPasswordChangeFailed: false, // to track when server update password fails
};
const name = "appState";

//get user list
export const getUserList = createAsyncThunk(
  `${name}/api/v0.1/auth/user`,
  async () => {
    try {
      const res = await axiosAuth.get("/api/v0.1/auth/user");

      if (res.status !== 200) {
        toast.error("Get list failed");

        return;
      } else {
        return res.data;
      }
    } catch (err) {
      toast.error("Failed");
    }
  }
);

// register
export const registerAuth = createAsyncThunk(
  `${name}/api/v0.1/auth/register`,
  async ({ username, email, full_name, country_of_origin, password }) => {
    try {
      const res = await axios.post("/api/v0.1/auth/register", {
        username: username,
        email: email,
        full_name: full_name,
        country_of_origin: country_of_origin,
        password: password,
      });

      if (res.status >= 200 && res.status < 300) {
        toast.success("Successful, email sent.");
        return;
      }
    } catch (err) {
      toast.error("Register failed");
    }
  }
);

//send verification
export const verifyUserAuth = createAsyncThunk(
  `${name}/api/v0.1/auth/verify`,
  async ({ verificationToken }) => {
    try {
      const res = await axios.post("/api/v0.1/auth/verify", {
        token: verificationToken,
      });

      if (res.status !== 200) {
        toast.error("Verification failed");
        return;
      } else {
        toast.success("Successful, verification success.");
      }
    } catch (err) {
      toast.error("Verification failed");

      throw new Error(err.response.data.error); // throw Error for builder to catch
    }
  }
);

// reset verification
export const resetVerifyAuth = createAsyncThunk(
  `${name}/api/v0.1/auth/reset-verify`,
  async ({ username, email }) => {
    try {
      const res = await axios.post("/api/v0.1/auth/reset-verify", {
        username: username,
        email: email,
      });

      if (res.status !== 200) {
        toast.error("Verification failed");
        return;
      } else {
        toast.success("Successful, verification success.");
      }
    } catch (err) {
      toast.error("Verification failed");

      throw new Error(err.response.data.error); // throws error for builder to catch
    }
  }
);

//forget password
export const forgetPasswordAuth = createAsyncThunk(
  /**
   * auth/forget-password:
   * - triggers a email with a unique link to be sent to the user's email
   * - user can click on the link to be redirected to the forgetPasswordRedirect page to enter their new password
   */
  `${name}/api/v0.1/auth/forget-password`,
  async ({ username, email }) => {
    try {
      const res = await axios.post("/api/v0.1/auth/forget-password", {
        username: username,
        email: email,
      });
      if (res.status >= 200 && res.status < 300) {
        toast.success(
          "Email to reset your password will be sent to your registered email id."
        );
        return;
      } else {
        toast.error("Error, something went wrong.");
      }
    } catch (err) {
      toast.error("Error, something went wrong.");

      throw new Error(err.response.data.error); // throws error for builder to catch
    }
  }
);

//reset password
export const resetPasswordAuth = createAsyncThunk(
  /**
   * auth/reset-password:
   * - in the forgetPasswordRedirect page, user can change their password and submit
   * - on submit, the page will take in the token(from url) and newPassword and send it to the backend
   */
  `${name}/api/v0.1/auth/reset-password`,
  async ({ token, password }) => {
    try {
      const res = await axios.post("/api/v0.1/auth/reset-password", {
        token: token,
        password: password,
      });

      if (res.status >= 200 && res.status < 300) {
        // toast.success("Successful, your password has been changed.", {
        //   duration: 7000,
        // });
        return;
      } else {
        // moved toast error logic to page code instead
        // toast.error("Error, something went wrong.");
      }
    } catch (err) {
      // moved toast error logic to page code instead
      // toast.error("Error, something went wrong.");

      throw new Error(err.response.data.error); // throws error for builder to catch
    }
  }
);

const api = axios.create({
  baseURL: `${backendDomain}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});
//change password
export const changePasswordAuth = createAsyncThunk(
  /**
   * auth/change-password:
   * - used in ChangePasswordForm to allow the user to change their password
   * - takes in a new password chosen by the user to be sent to the backend
   */

  `${name}/api/v0.1/auth/change-password`,
  async ({ old_password, password }) => {
    try {
      const res = await api.post("/api/v0.1/auth/change-password", {
        old_password: old_password,
        password: password,
      });

      if (res.status >= 200 && res.status < 300) {
        // toast.success("Your password has been changed successfully.");
        return;
      }
      // else {
      //    toast.error("Error: something went wrong.");
      // }
    } catch (err) {
      // toast.error("Error, something went wrong.");

      // throw new Error(JSON.stringify(err.response.data)); // throws error for builder to catch
      throw new Error(err.response.data.detail["error code"]); // throws error for builder to catch
    }
  }
);

//create team
export const createTeam = createAsyncThunk(
  `${name}/api/v0.1/team/`,
  async ({ team_name }) => {
    try {
      const response = await axiosAuth.post("/api/v0.1/team/", {
        team_name: team_name,
      });

      if (response.status >= 200 && response.status < 300) {
        toast.success("Created team");
      } else {
        let errorResponse = await response.json();

        toast.error(
          "Create team failed here. " + errorResponse.error.toString()
        );
      }
    } catch (error) {
      toast.error("Create team failed catch.");
    }
  }
);

//login
export const login = createAsyncThunk(
  `${name}/api/v0.1/auth/login`,
  async ({ username, email, password }) => {
    try {
      const res = await axios.post("/api/v0.1/auth/login", {
        username,
        email,
        password,
      });

      if (res.status !== 200) {
        return;
      }
      if (res.status === 200) {
        localStorage.setItem("token", res.data.auth_token);
        localStorage.setItem("role", res.data.payload.role);
        localStorage.setItem("team_lead", res.data.payload.team_lead); // bool value

        return res.data;
      }
    } catch (err) {
      throw new Error(err.response.data.error); // throws error for builder to catch
    }
  }
);

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    // reducers are functions that modify state
    logOut: (state) => {
      state.loginData = initialState.loginData;
      state.isLoggedIn = initialState.isLoggedIn;
      state.userUnverifiedLogin = initialState.userUnverifiedLogin;
      state.userLoginErrors = initialState.userLoginErrors;
    },
    completeRegister: (state) => {
      state.isRegisterCreated = initialState.isRegisterCreated;
      state.registerLoading = initialState.registerLoading;
    },
    completeGetUserList: (state) => {
      state.getUserListData = initialState.getUserListData;
    },
    completeLogin: (state) => {
      state.userUnverifiedLogin = initialState.userUnverifiedLogin;
      state.userLoginErrors = initialState.userLoginErrors;
    },
    completeForgetPassword: (state) => {
      state.forgetPasswordErrors = initialState.forgetPasswordErrors;
      state.isForgetPasswordUserNotFoundError =
        initialState.isForgetPasswordUserNotFoundError;
      state.isForgetPasswordLoading = initialState.isForgetPasswordLoading;
      state.isForgetPasswordSuccess = initialState.isForgetPasswordSuccess;
    },
    completeResetPassword: (state) => {
      state.resetPasswordErrors = initialState.resetPasswordErrors;
      state.resetPasswordStatus = initialState.resetPasswordStatus;
      state.isResetPasswordTokenExpiredError =
        initialState.isResetPasswordTokenExpiredError;
      state.isResetPasswordTokenInvalidError =
        initialState.isResetPasswordTokenInvalidError;
    },
    completeChangePassword: (state) => {
      state.changePasswordStatus = initialState.changePasswordStatus;
      state.changePasswordErrors = initialState.changePasswordErrors;
      state.changePasswordHasError = initialState.changePasswordHasError;
      state.isOldPasswordNotMatch = initialState.isOldPasswordNotMatch;
      state.isPasswordChangeFailed = initialState.isPasswordChangeFailed;
    },
  },

  extraReducers: (builder) => {
    // extraReducers are used to defined how state should be updated based on the the status of an action (eg. api call)
    //handle get user list api call statuses
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.getUserListData = payload;

      if (payload) {
        state.isGetUserListCreated = true;
      }

      state.getUserListLoading = false;
    });
    builder.addCase(getUserList.pending, (state) => {
      state.getUserListLoading = true;
    });
    builder.addCase(getUserList.rejected, (state) => {
      state.getUserListLoading = false;
    });

    //handle login api call statuses
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loginData = payload;
      state.isUserVerified = true;

      if (payload) {
        state.isLoggedIn = true;
      }
      // reset to initial state
      state.loginLoading = false;
      state.userUnverifiedLogin = false;
      state.userLoginErrors = [];
      state.isVerificationLinkExpired = false;
    });
    builder.addCase(login.pending, (state) => {
      state.loginLoading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginLoading = false;
      state.userLoginErrors.push(action.error.message); // catch error message thrown by api and append to state
      if (action.error.message === "Please verify your email address") {
        // if user is unverified, store condition to state for sendVerificationEmail page to load
        state.userUnverifiedLogin = true;
      }
    });

    //handle register api call statuses
    builder.addCase(registerAuth.fulfilled, (state) => {
      state.isRegisterCreated = true;
      state.registerLoading = false;
    });
    builder.addCase(registerAuth.pending, (state) => {
      state.registerLoading = true;
    });
    builder.addCase(registerAuth.rejected, (state) => {
      state.registerLoading = false;
    });

    //handle create team api call statuses
    builder.addCase(createTeam.fulfilled, (state) => {
      state.isTeamCreated = true;

      state.teamCreateLoading = false;
    });
    builder.addCase(createTeam.pending, (state) => {
      state.teamCreateLoading = true;
    });
    builder.addCase(createTeam.rejected, (state) => {
      state.teamCreateLoading = false;
    });

    //handle send verification api call statuses
    builder.addCase(verifyUserAuth.fulfilled, (state) => {
      state.isUserVerified = true;

      state.verifyUserLoading = false;
      state.isVerificationLinkExpired = false;
    });
    builder.addCase(verifyUserAuth.pending, (state) => {
      state.verifyUserLoading = true;
    });
    builder.addCase(verifyUserAuth.rejected, (state, action) => {
      state.isUserVerified = false;
      state.verifyUserLoading = false;
      if (action.error.message === "Verification expired") {
        state.isVerificationLinkExpired = true;
      }
    });

    //handle reset verification api call status
    builder.addCase(resetVerifyAuth.fulfilled, (state) => {
      state.resetVerificationSuccess = true;
      state.resetVerificationLoading = false;
    });
    builder.addCase(resetVerifyAuth.pending, (state) => {
      state.resetVerificationLoading = true;
    });
    builder.addCase(resetVerifyAuth.rejected, (state) => {
      state.resetVerificationSuccess = false;
      state.resetVerificationLoading = false;
    });

    //handle forget password api call status
    builder.addCase(forgetPasswordAuth.fulfilled, (state, action) => {
      state.isForgetPasswordLoading = false;
      state.isForgetPasswordSuccess = true;
      state.forgetPasswordErrors = [];
      state.isForgetPasswordUserNotFoundError = false;
    });
    builder.addCase(forgetPasswordAuth.pending, (state) => {
      state.isForgetPasswordLoading = false;
    });
    builder.addCase(forgetPasswordAuth.rejected, (state, action) => {
      state.isForgetPasswordSuccess = false;
      state.isForgetPasswordLoading = false;
      if (action.error.message) {
        state.forgetPasswordErrors.push(action.error.message);
        if (action.error.message === "No account found") {
          state.isForgetPasswordUserNotFoundError = true;
        }
      }
    });

    //handle reset password api call status
    builder.addCase(resetPasswordAuth.fulfilled, (state, action) => {
      state.resetPasswordStatus = "fulfilled";
      // state.isResetPasswordLoading = false;
      // state.isResetPasswordSuccess = true;
      state.resetPasswordErrors = [];
      state.isResetPasswordTokenInvalidError = false;
      state.isResetPasswordTokenExpiredError = false;
    });
    builder.addCase(resetPasswordAuth.pending, (state) => {
      state.resetPasswordStatus = "pending";
      // state.isResetPasswordLoading = false;
    });
    builder.addCase(resetPasswordAuth.rejected, (state, action) => {
      state.resetPasswordStatus = "rejected";
      // state.isResetPasswordSuccess = false;
      // state.isResetPasswordLoading = false;
      if (action.error.message) {
        // TODO: confirm if this is the proper way to check whether there's an error message
        state.resetPasswordErrors.push(action.error.message);
        if (action.error.message === "Password reset expired") {
          state.isResetPasswordTokenExpiredError = true;
        } else {
          // park all other errors as invalidtoken error
          state.isResetPasswordTokenInvalidError = true;
        }
      }
    });

    //handle change password api call status
    builder.addCase(changePasswordAuth.fulfilled, (state, action) => {
      state.changePasswordStatus = "fulfilled";
      state.changePasswordErrors = [];
      state.changePasswordHasError = false;

      state.isOldPasswordNotMatch = false;
      state.isPasswordChangeFailed = false;
    });
    builder.addCase(changePasswordAuth.pending, (state, action) => {
      state.changePasswordStatus = "pending";
    });
    builder.addCase(changePasswordAuth.rejected, (state, action) => {
      state.changePasswordStatus = "rejected";
      state.changePasswordHasError = true;
      state.changePasswordErrors.push(action.error.message);

      if (action.error.message === "AUTH-11024") {
        // if user enters the wrong old password
        state.isOldPasswordNotMatch = true;
      } else if (action.error.message === "AUTH-22019") {
        // if server fails to change password
        state.isPasswordChangeFailed = true;
      }
    });
  },
});

// == export reducers as actions to be used in other components ==
// ===============================================================

// each case under reducers becomes an action
export const { logOut } = appSlice.actions;
export const { completeRegister } = appSlice.actions;
export const { completeCreateTeam } = appSlice.actions;
export const { completeGetUserList } = appSlice.actions;
export const { completeLogin } = appSlice.actions;
export const { completeForgetPassword } = appSlice.actions;
export const { completeResetPassword } = appSlice.actions;
export const { completeChangePassword } = appSlice.actions;

export default appSlice.reducer;

// == export selectors to access state values ==
// =============================================

// get user list selectors (for admins)
export const useGetUserListData = () =>
  useSelector((state) => state.appState.getUserListData);

//login complete selectors
export const useUserLoginErrors = () =>
  useSelector((state) => state.appState.userLoginErrors);

export const useUserUnverifiedLogin = () =>
  useSelector((state) => state.appState.userUnverifiedLogin);

//register complete selectors
export const useRegisterLoading = () =>
  useSelector((state) => state.appState.registerLoading);

export const useRegisterCreated = () =>
  useSelector((state) => state.appState.isRegisterCreated);

//create team complete selectors
export const useTeamCreated = () =>
  useSelector((state) => state.appState.isTeamCreated);

export const useIsLoggedIn = () =>
  useSelector((state) => state.appState.isLoggedIn);

export const useLoggedInData = () =>
  useSelector((state) => state.appState.loginData);

//send verification selectors
export const useIsUserVerified = () =>
  useSelector((state) => state.appState.isUserVerified);

export const useVerifyUserLoading = () =>
  useSelector((state) => state.appState.verifyUserLoading);

export const useIsVerificationLinkExpired = () =>
  useSelector((state) => state.appState.isVerificationLinkExpired);

//reset verification complete status
export const useResetVerificationSuccess = () =>
  useSelector((state) => state.appState.resetVerificationSuccess);

export const useResetVerificationLoading = () =>
  useSelector((state) => state.appState.resetVerificationLoading);

//forget password selectors
export const useIsForgetPasswordLoading = () =>
  useSelector((state) => state.appState.isForgetPasswordLoading);

export const useIsForgetPasswordSuccess = () =>
  useSelector((state) => state.appState.isForgetPasswordSuccess);

export const useForgetPasswordErrors = () =>
  useSelector((state) => state.appState.forgetPasswordErrors);

export const useIsForgetPasswordUserNotFoundError = () =>
  useSelector((state) => state.appState.isForgetPasswordUserNotFoundError);

//reset password selectors
export const useResetPasswordStatus = () =>
  useSelector((state) => state.appState.resetPasswordStatus);

export const useResetPasswordErrors = () =>
  useSelector((state) => state.appState.resetPasswordErrors);

export const useIsResetPasswordTokenExpiredError = () =>
  useSelector((state) => state.appState.isResetPasswordTokenExpiredError);

export const useIsResetPasswordTokenInvalidError = () =>
  useSelector((state) => state.appState.isResetPasswordTokenInvalidError);

//change password selectors
export const useChangePasswordStatus = () =>
  useSelector((state) => state.appState.changePasswordStatus);

export const useChangePasswordErrors = () =>
  useSelector((state) => state.appState.changePasswordErrors);

export const useChangePasswordHasError = () =>
  useSelector((state) => state.appState.changePasswordHasError);

export const useIsOldPasswordNotMatch = () =>
  useSelector((state) => state.appState.isOldPasswordNotMatch);

export const useIsPasswordChangeFailed = () =>
  useSelector((state) => state.appState.isPasswordChangeFailed);
