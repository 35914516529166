import React, { useState, useEffect } from "react";
import "./AdminSubmission.css";
import Select from "react-select";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import toast from "react-hot-toast";

import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Switch from "shared/Switch/Switch";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";

import { TryRounded } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EventIcon from "@mui/icons-material/Event";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import NotesIcon from "@mui/icons-material/Notes";
import TopicIcon from "@mui/icons-material/Topic";
import AnnouncementRoundedIcon from "@mui/icons-material/AnnouncementRounded";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import checkValidJsonParse from "utils/checkValidJsonParse";
import { backendDomain } from "utils/envConfig";
import datetimeConverter from "utils/datetimeConverter";

const AdminSubmission = () => {
  // React state to manage selected options
  const [selectedTeamOption, setSelectedTeamOption] = useState(); // to store selected team option for filtering records by team
  const [selected, setSelected] = useState([]);

  const [rows, setRows] = useState({}); // to store submissions returned by GET all submissions api

  const [popUp, setPopUp] = useState(false);
  const [checked, setChecked] = useState(null);
  const [isSubmissionsLoading, setIsSubmissionsLoading] =
    useState("not_started"); // to track whether GET all submissions api is still loading

  const [isTeamsLoading, setIsTeamsLoading] = useState("not_started");

  const [teamsDropdownOptions, setTeamsDropdownOptions] = useState([]); // to store list of teams for filter by team name dropdown box

  const [
    showRefundSubmissionConfirmationModal,
    setShowRefundSubmissionConfirmationModal,
  ] = useState(false); // to store condition w.r.t when confirmation modal should be rendered

  const [selectedSubmissionIdForRefund, setSelectedSubmissionIdForRefund] =
    useState(null); // store submission_id value of user selected record when refunding

  const getAllSubmissionsQueryParams = {
    sort: ["created_date"],
    order: ["DESC"], // sort by submissions created_date descendingly
    // n_limit: 100, // TODO: comment out before pushing
    // n_offset: 10
  };

  const navigate = useNavigate();
  console.log(selectedTeamOption);

  useEffect(() => {
    // log user out if user's token is not admin
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("role") !== "admin"
    ) {
      navigate("/");
      return;
    }
    // setRows(data);
    getSubmissionPhase();
    getAllTeams();
    getAllSubmissions(getAllSubmissionsQueryParams);
  }, []);

  const formatTeamsDropdownOptions = (getTeamsResponse) => {
    let formattedResponse = [];
    getTeamsResponse.map((teamRecord) => {
      formattedResponse.push({
        value: teamRecord.team_id,
        label: teamRecord.team_name,
      });
    });

    // sort team names alphabetically ascendingly (i.e. from A to Z)
    formattedResponse.sort((curr, next) =>
      curr.label.localeCompare(next.label)
    );

    // add "All" option to the first item of the array
    formattedResponse.unshift({ value: "", label: "Show All Teams" });

    return formattedResponse;
  };

  const getAllTeams = async () => {
    /*
     * Function to query GET teams API, format API response and store into state.
     * The API response will be used in the page's dropdown box to filter submissions record by teams.
     */
    let url = `${backendDomain}/api/v0.1/team/`;
    // console.log(url)
    try {
      setIsTeamsLoading("pending");
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        setIsTeamsLoading("rejected");
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      } else {
        const response_data = await response.json();
        // console.log(response_data);
        // console.log("success - all team names loaded");
        setTeamsDropdownOptions(
          formatTeamsDropdownOptions(response_data.result)
        );

        setIsTeamsLoading("fulfilled");
      }
    } catch (error) {
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object

      console.log(parsed_error);
    }
  };

  const getAllSubmissions = async (queryParamsObject = null) => {
    let url = `${backendDomain}/api/v0.1/submissions/all`;
    if (queryParamsObject) {
      // format url query params
      url += "?";
      for (const key in queryParamsObject) {
        if (Array.isArray(queryParamsObject[key])) {
          // If the value is an array of strings
          url += `${key}=${queryParamsObject[key].join(",")}&`;
        } else {
          // If the value is an integer
          url += `${key}=${queryParamsObject[key]}&`;
        }
      }
      url = url.slice(0, -1); // remove the last "&" character
    }
    // console.log(url)
    try {
      setIsSubmissionsLoading("pending");
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        setIsSubmissionsLoading("rejected");
        const response_error = await response.json();
        console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      } else {
        const response_data = await response.json();
        // console.log(response_data);
        // console.log("success - all submissions loaded");
        setRows(response_data);
        setIsSubmissionsLoading("fulfilled");
      }
    } catch (error) {
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      console.log(parsed_error);
    }
  };

  const getSubmissionPhase = async () => {
    let url = `${backendDomain}/api/v0.1/submissions/submission-phase`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/csv",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const res = await response.json();
        const resVal = res.setting_value;

        if (resVal === "False") {
          setChecked(false);
        } else {
          setChecked(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const patchUpdateSubmissionQuota = async (submission_id) => {
    /*
     * Function to handle the API call to refund a submission.
     */
    const url = `${backendDomain}/api/v0.1/submissions/quota`;
    const requestBody = {
      submission_ids: [submission_id],
      exclude_from_quota: true,
    };

    try {
      let response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        // if api returns an error response
        // setHasUnsavedChanges(true); // set state to notify user there is unsaved changes
        const response_error = await response.json();
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
      } else {
        // api returns response successfully
        toast.success("Submission quota has been refunded.");
      }
    } catch (error) {
      // console.log(error);
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "AUTH-11001") {
        toast.error("Error: You are not authorized to do this action.");
      } else if (parsed_error["error code"] === "SUBM-12010") {
        toast.error("Error: Submission quota cannot be refunded.");
      }
    }
  };

  // Switch On or Off Team Formation
  const patchSwitchSubmissionPhase = async (checked) => {
    setChecked(!checked);

    let data = JSON.stringify({
      setting_value: !checked,
    });
    let url = `${backendDomain}/api/v0.1/submissions/submission-phase`;

    try {
      let response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: data,
        redirect: "follow",
      });

      if (response.ok) {
        toast.success("Success: Submission phase has been updated.");
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error: Failed to update submission phase.");
    }
  };

  const getLogfileById = async (result_id) => {
    let url = `${backendDomain}/api/v0.1/result/logfile/id/${result_id}`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        toast.success("Success: Logfile is downloaded.");
        const response_error = await response.json();
        // console.log(response_error);
        throw new Error(JSON.stringify(response_error.detail)); // throw error object as string
        return;
      }
      const response_data = await response.json();
      // console.log(response_data);
      return response_data;
    } catch (error) {
      if (!checkValidJsonParse(error.message)) {
        // prevent json.parse() errors from breaking the page
        toast.error("Error: Something went wrong.");
        return;
      }
      const parsed_error = JSON.parse(error.message); // convert back to object
      if (parsed_error["error code"] === "RSLT-11003") {
        toast.error("Error: No record with result_id found");
      } else {
        toast.error("Error: Failed to get logfile.");
      }
      return;
    }
  };

  const handleLogfileButton = async (result_id, submission_id) => {
    try {
      const response = await getLogfileById(result_id); // call get logfile api
      const logfileContent = response.result.logfile; // get logfile text from response

      // Create a Blob containing the extracted value as text
      const blob = new Blob([logfileContent], { type: "text/plain" });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = url;

      const logfileTitle = submission_id.split("-")[0] + ".log";

      // Set the filename for the downloaded file
      link.setAttribute("download", `${logfileTitle}`);

      // Append the link to the document body
      document.body.appendChild(link);

      // Click the link to trigger the download
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);

      // Revoke the URL to free up memory
      window.URL.revokeObjectURL(url);

      // // Open the long text in a new page as raw text
      // const newWindow = window.open("", "_blank");
      // if (newWindow) {
      //   // Write the raw text content to the new window
      //   newWindow.document.write(`<pre>${logfile_content}</pre>`);
      // } else {
      //   // Handle pop-up blocker or other issues
      //   console.error("Failed to open new window.");
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const renderRefundConfirmationPopup = () => {
    return (
      <div
        style={{
          display: "block",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: "100%",
          height: "100%",
          zIndex: 99,
        }}
      >
        <div
          style={{
            position: "fixed",
            top: 200,
            left: 250,
            right: 250,
            bottom: 100,
            backgroundColor: "azure",
            textAlign: "center",
            opacity: 1,
            fontSize: "1.25rem",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              fontSize: "2rem",
              display: "flex",
              justifyContent: "center",
              marginTop: "130px",
              zIndex: 2,
            }}
          >
            <b>Warning</b>
          </div>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            You have requested to refund, please note that this is an
            irreversible action.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              marginTop: "35px",
            }}
          >
            <button
              style={{
                marginLeft: "2rem",
                marginRight: "1.25rem",
              }}
              onClick={() => setPopUp(false)}
            >
              Cancel
            </button>
            <button
              style={{
                marginLeft: "0.625rem",
                marginRight: "1.25rem",
              }}
              onClick={() => setPopUp(false)}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Function triggered on selection
  function handleSelectTeamFilter(userSelectedTeamOption) {
    /*
     * Function to handle admin's selection of the filter by team_name dropdown box.
     */
    // console.log(userSelectedTeamOption);
    setSelectedTeamOption(userSelectedTeamOption);

    if (userSelectedTeamOption) {
      const filterQueryParam = userSelectedTeamOption.value;
      getAllSubmissions({
        ...getAllSubmissionsQueryParams,
        team_id_filters: filterQueryParam,
      });
    }
  }

  // Function triggered on selection
  function handleRefundSubmission(submission_id) {
    /*
     * Function to handle the refund button when user clicks it.
     */
    // console.log(userSelectedTeamOption);
    // setSelectedTeamOption(userSelectedTeamOption);

    // if (userSelectedTeamOption) {
    //   const filterQueryParam = userSelectedTeamOption.value;
    //   getAllSubmissions({
    //     ...getAllSubmissionsQueryParams,
    //     team_id_filters: filterQueryParam,
    //   });
    // }
    setSelectedSubmissionIdForRefund(submission_id);

    setShowRefundSubmissionConfirmationModal(true);
    // patchUpdateSubmissionQuota(submission_id);
    // getAllSubmissions(getAllSubmissionsQueryParams);
  }

  function onRefundSubmissionModalContinue() {
    // call the PATCH update quota API
    patchUpdateSubmissionQuota(selectedSubmissionIdForRefund);

    // update table state data to disable refund button

    // find index of refunded submission record in table state data
    const selectedRefundIndex = rows?.aggregated_response.findIndex(
      (row) => row.Submissions.submission_id === selectedSubmissionIdForRefund
    );

    // If the record is found, update the table's state data directly at that index
    if (selectedRefundIndex !== -1) {
      const updatedTableData = { ...rows };
      updatedTableData.aggregated_response[
        selectedRefundIndex
      ].Submissions.exclude_from_quota = true;
      setRows(updatedTableData);
    } else {
      // if fail to find index, reload the table by calling the GET api again
      getAllSubmissions(getAllSubmissionsQueryParams);
    }
    // console.log(rows)

    // hide confirmation modal
    setShowRefundSubmissionConfirmationModal(false);
    // getAllSubmissions(getAllSubmissionsQueryParams); // reload table by calling the GET api again
  }

  function onRefundSubmissionModalCancel() {
    setShowRefundSubmissionConfirmationModal(false);
  }

  function refundSubmissionConfirmationModal() {
    /*
     * Function that handles the rendering of the confirmation
     * modal when admin clicks on the Refund button.
     */

    return (
      <Modal
        open={showRefundSubmissionConfirmationModal}
        onClose={onRefundSubmissionModalCancel}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        // slotProps={{ timeout: 500 }}
      >
        <Fade in={showRefundSubmissionConfirmationModal}>
          <div className={"refund-submission-confirmation-modal"}>
            <div className={"refund-submission-modal-content"}>
              <div>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ marginBottom: "15px" }}
                >
                  {/* You have chosen {selected.size} out of{" "}
                  {process.env.REACT_APP_MAX_NUM_OF_PICKED_SUBMISSIONS}{" "}
                  submissions. <br></br>
                  Are you sure you want to submit? <br></br> */}
                  <p
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    WARNING!
                  </p>
                  <p>
                    You are about to refund this submission. This action is{" "}
                    <b>
                      <u>irreversible</u>
                    </b>{" "}
                    and will exclude this submission from the competition
                    permanently. <br />
                  </p>

                  <p>Are you sure you want to refund this submission?</p>
                </Typography>
              </div>
              <Stack
                direction="horizontal"
                gap={5}
                style={{
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={onRefundSubmissionModalCancel}
                  variant="danger"
                  style={{ width: "100px" }}
                >
                  No
                </Button>
                <Button
                  onClick={onRefundSubmissionModalContinue}
                  variant="success"
                  style={{ width: "100px" }}
                >
                  Yes
                </Button>
              </Stack>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }

  function EnhancedTableHead(props) {
    return (
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          {/* SCORES AND STATUSES HEADER */}
          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "25%",
            }}
          >
            <TableHead>Scores and Statuses</TableHead>
          </TableCell>

          {/* PUBLIC  COLUMN HEADER */}
          {/* <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "10%",
            }}
          >
            <TableHead>Public</TableHead>
          </TableCell> */}

          {/* PRVIATE COLUMN HEADER */}
          {/* <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "10%",
            }}
          >
            <TableHead>Private</TableHead>
          </TableCell> */}

          {/* STATUS COLUMN HEADER */}
          {/* <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "10%",
            }}
          >
            <TableHead>Status</TableHead>
          </TableCell> */}

          {/* TEAM COLUMN HEADER */}
          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "10%",
            }}
          >
            <TableHead>Team</TableHead>
          </TableCell>

          {/* SUBMISSION DETAILS COLUMN HEADER */}
          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "50%",
            }}
          >
            <TableHead>Details</TableHead>
          </TableCell>
          {/* <TableCell
            style={{ borderBottom: "3px solid grey" }}
            padding="checkbox"
          ></TableCell> */}

          {/* LOGFILE COLUMN HEADER */}
          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "5%",
              whiteSpace: "nowrap",
            }}
          >
            <TableHead>Logfile</TableHead>
          </TableCell>

          {/* QUOTA ACTION COLUMN HEADER */}
          <TableCell
            sx={{
              fontWeight: "bold",
              // borderBottom: "3px solid grey",
              width: "10%",
            }}
          >
            <TableHead>Quota Action</TableHead>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%", textAlign: "left" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <b>Submissions</b>
          </Typography>
        )}

        {numSelected > 0 ? (
          <>
            <Tooltip title="Submit K Submissions">
              <button
                className="submission-k-button"
                testId="submit-k-submissions"
              >
                Submit
              </button>
            </Tooltip>
          </>
        ) : null}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const renderTable = () => {
    if (isSubmissionsLoading !== "fulfilled") {
      return (
        <Box sx={{ paddingBottom: "20px" }}>
          <Paper>
            <CircularProgress />
          </Paper>
        </Box>
      );
    }
    return (
      <Box sx={{ paddingBottom: "20px" }}>
        <Paper>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer style={{ overflowX: "initial" }}>
            {refundSubmissionConfirmationModal()}
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead
                numSelected={selected.length}
                rowCount={rows?.length}
              />
              <TableBody>
                {rows?.num_records_returned === 0 ? (
                  // if no submissions, render a component on the table to notify
                  // admin that this user doesn't have any submissions
                  <TableRow>
                    <TableCell colSpan={7} align="center" sx={{ width: 1000 }}>
                      This team does not have a submission yet.
                    </TableCell>
                  </TableRow>
                ) : (
                  // else if there's submissions, render the submission records
                  rows?.aggregated_response?.map((row, index) => {
                    const isItemSelected = isSelected(row.Results?.result_id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // return
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.Results?.result_id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        {row.Results?.asr_hmean_public &&
                        row.Results?.asr_hmean_private ? (
                          // if the submission record has scores i.e. it is a successful submission
                          <>
                            <TableCell sx={{ width: "25%" }}>
                              <div>
                                <div>
                                  <b>Hmean Private:</b>{" "}
                                  {row.Results?.asr_hmean_private}
                                </div>
                                <div>
                                  <b>Avg Token Length Ratio:</b>{" "}
                                  {row.Results?.avg_token_length_ratio}
                                </div>
                                <div>
                                  <b>Hmean Public:</b>{" "}
                                  {row.Results?.asr_hmean_public}
                                </div>
                                <div>
                                  <b>Llama:</b> {row.Results?.asr_llama}
                                </div>
                                <div>
                                  <b>Secret Model 1:</b>{" "}
                                  {row.Results?.asr_secret_model_1}
                                </div>
                                <div>
                                  <b>Secret Model 2:</b>{" "}
                                  {row.Results?.asr_secret_model_2}
                                </div>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <div>
                                  <b>Aggregated:</b> {row.aggregated_status}
                                </div>
                                <div>
                                  <b>Executor:</b>{" "}
                                  {row.Submissions.submission_exec_status}
                                </div>
                                <div>
                                  <b>Scorer:</b> {row.Results?.scoring_status}
                                </div>
                              </div>
                            </TableCell>

                            {/* PUBLIC COLUMN */}
                            {/* PRIVATE COLUMN */}
                            {/* STATUS COLUMN */}
                            {/* <TableCell id={labelId} sx={{ width: "10%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  <div>
                                    <b>Hmean Public:</b> {row.aggregated_status}
                                  </div>
                                  <div>
                                    <b>Llama:</b>{" "}
                                    {row.Submissions.submission_exec_status}
                                  </div>
                                  <div>
                                    <b>Vicuna :</b> {row.Results?.scoring_status}
                                  </div>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell id={labelId} sx={{ width: "10%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  <div>
                                    <b>Hmean Private:</b> {row.aggregated_status}
                                  </div>
                                  <div>
                                    <b>Avg Token Length Ratio:</b>{" "}
                                    {row.Submissions.submission_exec_status}
                                  </div>
                                  <div>
                                    <b>Secret Model 1:</b> {row.Results?.scoring_status}
                                  </div>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell sx={{ width: "10%" }}>
                              <div>
                                <div>
                                  <b>Aggregated:</b> {row.aggregated_status}
                                </div>
                                <div>
                                  <b>Executor:</b>{" "}
                                  {row.Submissions.submission_exec_status}
                                </div>
                                <div>
                                  <b>Scorer:</b> {row.Results?.scoring_status}
                                </div>
                              </div>
                            </TableCell> */}
                          </>
                        ) : (
                          // if the submission record does not have scores
                          <TableCell sx={{ width: "25%" }}>
                            <div>
                              <div>
                                <b>Aggregated:</b> {row.aggregated_status}
                              </div>
                              <div>
                                <b>Executor:</b>{" "}
                                {row.Submissions.submission_exec_status}
                              </div>
                              <div>
                                <b>Scorer:</b> {row.Results?.scoring_status}
                              </div>
                            </div>
                          </TableCell>
                        )}

                        {/* TEAM COLUMN */}
                        <TableCell sx={{ width: "10%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3px",
                            }}
                          >
                            {row.team_name}
                          </div>
                        </TableCell>

                        {/* SUBMISSIONS DETAILS COLUMN */}
                        <TableCell sx={{ width: "55%" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                            }}
                          >
                            <div style={{ marginRight: "5px" }}>
                              <Tooltip
                                title="Submission ID"
                                slotProps={{
                                  // adjust distance of tooltip message from anchor
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -14],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              >
                                <FingerprintIcon />
                              </Tooltip>{" "}
                              {row?.Submissions?.submission_id}
                            </div>

                            <div>
                              <Tooltip
                                title="Filename"
                                slotProps={{
                                  // adjust distance of tooltip message from anchor
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -14],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              >
                                <AttachFileIcon />
                              </Tooltip>{" "}
                              <b> {row.Submissions.submit_filename}</b>
                            </div>
                          </div>
                          <div
                            style={{
                              color: "grey",
                              marginRight: "5px",
                              marginTop: "7.5px",
                            }}
                          >
                            <Tooltip
                              title="Created Date"
                              slotProps={{
                                // adjust distance of tooltip message from anchor
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, -14],
                                      },
                                    },
                                  ],
                                },
                              }}
                            >
                              <EventIcon />
                            </Tooltip>{" "}
                            {datetimeConverter(
                              row.Submissions.created_date,
                              true
                            )}
                          </div>
                          <div
                            style={{ marginRight: "5px", marginTop: "7.5px" }}
                          >
                            {row.Submissions.submit_title && (
                              <div>
                                <p>
                                  <Tooltip
                                    title="Title"
                                    slotProps={{
                                      // adjust distance of tooltip message from anchor
                                      popper: {
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, -14],
                                            },
                                          },
                                        ],
                                      },
                                    }}
                                  >
                                    <TopicIcon />
                                  </Tooltip>{" "}
                                  {row.Submissions.submit_title}
                                </p>
                              </div>
                            )}
                            {row.Submissions.submit_comments && (
                              <div>
                                <p>
                                  <Tooltip
                                    title="Comments"
                                    slotProps={{
                                      // adjust distance of tooltip message from anchor
                                      popper: {
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, -14],
                                            },
                                          },
                                        ],
                                      },
                                    }}
                                  >
                                    <NotesIcon />
                                  </Tooltip>
                                  <br />
                                  {row.Submissions.submit_comments}
                                </p>
                              </div>
                            )}
                          </div>
                        </TableCell>

                        {/* LOGFILE COLUMN */}
                        <TableCell sx={{ width: "5%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {row.Results ? (
                              <IconButton
                                aria-label="logfile"
                                onClick={() => {
                                  handleLogfileButton(
                                    row.Results?.result_id,
                                    row.Submissions?.submission_id
                                  );
                                }}
                              >
                                <FileDownloadIcon
                                  style={{ fontSize: "1.5em" }}
                                />
                              </IconButton>
                            ) : (
                              <>
                                <Tooltip title="No logfile as Result record doesn't exist.">
                                  <WarningAmberIcon
                                    style={{
                                      color: "orange",
                                      fontSize: "2em",
                                    }}
                                  />
                                </Tooltip>
                              </>
                            )}
                          </div>
                        </TableCell>

                        {/* QUOTA ACTION COLUMN */}
                        <TableCell sx={{ width: "10%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Button
                              variant="success"
                              onClick={() =>
                                handleRefundSubmission(
                                  row.Submissions.submission_id
                                )
                              }
                              disabled={row.Submissions.exclude_from_quota}
                            >
                              Refund
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  return (
    <div
      className={
        popUp
          ? "admin-submission-container-no-scroll"
          : "admin-submission-container"
      }
    >
      <div className="admin-submission-switch-container">
        <h1 className="admin-submission-title">
          <b>Admin Submission Queue</b>
        </h1>
        <div className="admin-submission-switch">
          <Switch
            isOn={checked}
            handleToggle={() => patchSwitchSubmissionPhase(checked)}
            colorOne="#2c8f5a"
            colorTwo="#eb3458"
            includeTitle={true}
            titleOne="Submissions - On"
            titleTwo="Submissions - Off"
            className="switch-checkbox"
          />
        </div>
      </div>
      <div
        style={{
          marginBottom: "1.25rem",
          display: "flex",
          flexDirection: "row",
          marginRight: "880px",
        }}
      >
        <div
          style={{ fontSize: "1.25rem", marginTop: "5px", marginRight: "20px" }}
        >
          Team
        </div>
        <div className="admin-submission-dropdown">
          <Select
            options={teamsDropdownOptions}
            placeholder="Type to search"
            value={selectedTeamOption}
            onChange={handleSelectTeamFilter}
            isSearchable={true}
          />
        </div>
      </div>
      <div className="admin-submission-table">
        <div style={{ wordBreak: "break-word" }}>{renderTable()}</div>
        <div>{popUp && renderRefundConfirmationPopup()}</div>
      </div>
    </div>
  );
};

export default AdminSubmission;
