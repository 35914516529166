import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import axios from "axios";
import { backendDomain } from "utils/envConfig";
import { logOut } from "redux/loginSlice";


const initialState = {
  userProfileLoading: false,
  userProfileData: [],
  isUserProfileCreated: false,
  isTokenErrorThenLogout: false,
};
const name = "userProfileState";

export const getProfileList = createAsyncThunk(
  `${name}/api/v0.1/auth/profile`,
  async () => {
    try {
      let data = "";
      const res = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${backendDomain}/api/v0.1/auth/profile`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: data,
      });

      // // if api returns an error
      // console.log(res)
      // if (res.status < 200 && res.status > 299 ) {
      //   console.log(res)
      //   // let errorResponse = await response.json();
      //   // setApiError(errorResponse["error"]);
      //   const response_error = await res.json();
      //   // console.log(JSON.stringify(response_error.detail));
      //   throw new Error(JSON.stringify(response_error.detail));
      //   return;
      // }

      return res.data;
    } catch (err) {
      // console.log(err)
      throw new Error(err.response.data.detail["error code"]); // throws error for builder to catch
    }
  }
);

const userProfileSlice = createSlice({
  name,
  initialState,
  reducers: {
    completeGetUserProfile: (state) => {
      state.userProfileData = initialState.userProfileData;
      state.isTokenErrorThenLogout = initialState.isTokenErrorThenLogout;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProfileList.fulfilled, (state, { payload }) => {
      state.userProfileData = payload;
      state.isTokenErrorThenLogout = false

      if (payload) {
        state.isUserProfileCreated = true;
      }

      state.userProfileLoading = false;
    });
    builder.addCase(getProfileList.pending, (state) => {
      state.userProfileLoading = true;
      state.isTokenErrorThenLogout = false
      
    });
    builder.addCase(getProfileList.rejected, (state, action) => {
      state.userProfileLoading = false;

      if (action.error.message === "AUTH-11002" || action.error.message === "AUTH-11021") {
        // user's jwt token is invalid or jwt token has expired
        state.isTokenErrorThenLogout = true;
      } 
    });
  },
});

export const { completeGetUserProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;

export const useUserProfileData = () =>
  useSelector((state) => state.userProfileState.userProfileData);

export const useIsTokenErrorThenLogout = () =>
  useSelector((state) => state.userProfileState.isTokenErrorThenLogout);

export const useUserProfileLoading = () =>
  useSelector((state) => state.userProfileState.userProfileLoading);
