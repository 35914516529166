import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { backendDomain } from "utils/envConfig";
import "./AdminTeamMng.css";
import Switch from "shared/Switch/Switch";
import CustomButton from "shared/CustomButton/CustomButton";
import { Navigate, useNavigate } from "react-router-dom";

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

const AdminTeamMng = () => {
  const [userGroups, setUserGroups] = useState([]);
  const [searchMember, setSearchMember] = useState("");
  const [searchTeam, setSearchTeam] = useState("");
  const [checked, setChecked] = useState(null);

  const [filteredTeamGroup, setFilteredGroup] = useState([]);

  const [focusSearchTeam, setFocusSearchTeam] = useState(false);
  const [focusSearchMember, setFocusSearchMember] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // block user if user's token is not admin
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("role") !== "admin"
    ) {
      navigate("/");
      return;
    }
    if (focusSearchTeam === false || focusSearchMember === false) {
      GETTeamGroupView();
    }
    GETTeamRequestPhase();
  }, []);

  useEffect(() => {
    searchByMemberName(userGroups, searchMember);
  }, [userGroups, searchMember]);

  useEffect(() => {
    searchByTeamName(userGroups, searchTeam);
  }, [userGroups, searchTeam]);

  const searchMemberHandler = (event) => {
    setFocusSearchTeam(false);
    setFocusSearchMember(true);
    setSearchMember(event.target.value);
    const res = searchByMemberName(userGroups, event.target.value);
    setFilteredGroup(res);

    if (event.target.value === "") {
      setFilteredGroup(userGroups);
    }
  };

  const searchTeamHandler = (event) => {
    setFocusSearchMember(false);
    setFocusSearchTeam(true);
    setSearchTeam(event.target.value);
    const res = searchByTeamName(userGroups, event.target.value);

    setFilteredGroup([res]);

    if (event.target.value === "") {
      setFilteredGroup(userGroups);
    }
  };

  function searchByMemberName(json, searchMember) {
    const test = json.map((element) => {
      return {
        ...element,
        member: element.member.filter(
          (details) =>
            details.username.toLowerCase() === searchMember.toLowerCase()
        ),
      };
    });

    const rest = test.filter((i) => i.member.length === 1);

    return rest;
  }

  function searchByTeamName(arr, searchTeam) {
    let res = arr?.find(
      (record) => record?.team_name?.toLowerCase() === searchTeam?.toLowerCase()
    );

    return res;
  }

  function downloadCSV(s) {
    var blob = new Blob([s], { type: "text/csv;encoding:utf-8" });
    var a = document.createElement("a");
    a.setAttribute("download", "exportedData.csv");
    a.setAttribute("href", window.URL.createObjectURL(blob));
    a.click();
  }
  const GETUserlistCSV = async () => {
    let url = `${backendDomain}/api/v0.1/auth/user-list-csv`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/csv",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const res = await response.text();
        downloadCSV(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GETTeamRequestPhase = async () => {
    let url = `${backendDomain}/api/v0.1/team/team-request-phase`;

    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/csv",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const res = await response.json();

        const resVal = res.setting_value;

        if (resVal === "False") {
          setChecked(false);
        } else {
          setChecked(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GETTeamGroupView = async () => {
    let url = `${backendDomain}/api/v0.1/team/group-view`;
    setLoading(true);
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/csv",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const res = await response.json();
        const results = await sortUserGroupView(res.results);
        setUserGroups(results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Make Leader
  const patchMakeLeader = async (teamId, userId) => {
    setFocusSearchTeam(false);
    setSearchTeam("");

    setFocusSearchMember(false);
    setSearchMember("");

    let data = JSON.stringify({
      user_id: userId,
    });
    let url = `${backendDomain}/api/v0.1/team/${teamId}/leader`;

    try {
      let response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: data,
        redirect: "follow",
      });

      if (response.ok) {
        GETTeamGroupView();
        toast.success("Team member updated");
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error");
    }
  };

  // Disable User
  const postDisableUser = async (userId) => {
    setFocusSearchTeam(false);
    setSearchTeam("");

    setFocusSearchMember(false);
    setSearchMember("");

    let data = JSON.stringify({
      user_id: userId,
    });
    let url = `${backendDomain}/api/v0.1/auth/disable-user`;

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: data,
        redirect: "follow",
      });

      if (response.ok) {
        GETTeamGroupView();
        toast.success("User disabled");
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error");
    }
  };

  // Switch On or Off Team Formation
  const patchSwitchTeamFormation = async (checked) => {
    setFocusSearchTeam(false);
    setSearchTeam("");

    setFocusSearchMember(false);
    setSearchMember("");

    setChecked(!checked);

    let data = JSON.stringify({
      setting_value: !checked,
    });
    let url = `${backendDomain}/api/v0.1/team/team-request-phase`;

    try {
      let response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: data,
        redirect: "follow",
      });

      if (response.ok) {
        toast.success("Toggled");
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error");
    }
  };

  function sortUserGroupView(json) {
    json?.map((row) => {
      row.member.sort((a, b) => b.is_lead - a.is_lead);
    });
    return json;
  }

  const renderHeader = () => {
    let headerElement = ["team name", "members"];

    return (
      <>
        {headerElement?.map((key, index) => (
          <th style={{ width: 315 }} key={index}>
            {key.toUpperCase()}
          </th>
        ))}
      </>
    );
  };

  const renderFilteredTeamName = () => {
    return (
      <>
        {filteredTeamGroup[0] === undefined ||
        filteredTeamGroup.length === 0 ? (
          <>
            <div
              style={{
                marginTop: "50px",
                fontWeight: "normal",
                display: "flex",
                position: "absolute",
                marginLeft: "280px",
              }}
            >
              No results found.
            </div>
          </>
        ) : (
          <>
            {filteredTeamGroup?.map((row) => (
              <tr>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-20px",
                      width: "120px",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {row?.team_name}
                    </div>
                    {row?.team_name === undefined ? null : (
                      <div>
                        Submissions:
                        <div>
                          {row?.curr_quota} | {row?.total_quota}
                        </div>
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <table border="0" class="plain_tbl" width="100%">
                    {row?.member?.map((mem) => (
                      <tr>
                        {mem?.is_lead === true && mem?.is_disabled !== true && (
                          <td>
                            Leader:
                            <u
                              style={{
                                marginLeft: "10px",
                                wordBreak: "break-word",
                              }}
                            >
                              {mem?.username}
                            </u>
                          </td>
                        )}
                        {mem?.is_lead === false &&
                          mem?.is_disabled !== true && (
                            <td>
                              Member:
                              <u
                                style={{
                                  marginLeft: "10px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {mem?.username}
                              </u>
                            </td>
                          )}
                        {mem?.is_lead === true && mem?.is_disabled !== true ? (
                          <td>- No Action -</td>
                        ) : (
                          <td>
                            {mem?.is_disabled !== true &&
                              renderButtons(mem.user_id, row.team_id)}
                          </td>
                        )}
                      </tr>
                    ))}
                  </table>
                </td>
              </tr>
            ))}
          </>
        )}
      </>
    );
  };

  const renderButtons = (userId, teamId) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "5px",
          width: "250px",
        }}
      >
        <button
          className="admin-team-btns"
          onClick={() => postDisableUser(userId)}
        >
          DISABLE
        </button>
        <button
          className="admin-team-btns"
          onClick={() => patchMakeLeader(teamId, userId)}
        >
          <span style={{ display: "block", width: 105 }}>MAKE LEADER</span>
        </button>
      </div>
    );
  };
  const renderUnfiltered = () => {
    return (
      <>
        {userGroups?.map((row) => (
          <tr>
            <td>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "-20px",
                  width: "120px",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  {row?.team_name}
                </div>

                <div>
                  Submissions:
                  <div>
                    {row?.curr_quota} | {row?.total_quota}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <table border="0" class="plain_tbl" width="100%">
                {row?.member?.map((mem) => (
                  <tr>
                    {mem?.is_lead === true && mem?.is_disabled !== true && (
                      <td>
                        Leader:
                        <u
                          style={{
                            marginLeft: "10px",
                            wordBreak: "break-word",
                          }}
                        >
                          {mem?.username}
                        </u>
                      </td>
                    )}
                    {mem?.is_lead === false && mem?.is_disabled !== true && (
                      <td>
                        Member:
                        <u
                          style={{
                            marginLeft: "10px",
                            wordBreak: "break-word",
                          }}
                        >
                          {mem?.username}
                        </u>
                      </td>
                    )}
                    {mem?.is_lead === true && mem?.is_disabled !== true ? (
                      <td>- No Action -</td>
                    ) : (
                      <td>
                        {mem?.is_disabled !== true &&
                          renderButtons(mem.user_id, row.team_id)}
                      </td>
                    )}
                  </tr>
                ))}
              </table>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        {focusSearchTeam || focusSearchMember ? (
          <>{renderFilteredTeamName()}</>
        ) : (
          <>{renderUnfiltered()}</>
        )}
      </>
    );
  };
  return (
    <div className="admin-team-container">
      <div className="admin-team-switch-container">
        <h1 className="admin-team-title">
          <b>Admin Team Page</b>
        </h1>
        <div className="admin-team-switch">
          <Switch
            isOn={checked}
            handleToggle={() => patchSwitchTeamFormation(checked)}
            colorOne="#2c8f5a"
            colorTwo="#eb3458"
            includeTitle={true}
            titleOne="Team Formation - On"
            titleTwo="Team Formation - Off"
            className="switch-checkbox"
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: "1.25rem",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <input
          style={{ paddingLeft: "5px", paddingRight: "5px" }}
          placeholder="Search Team"
          value={searchTeam}
          onChange={searchTeamHandler}
          autoFocus={focusSearchTeam}
        />
        <input
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            marginLeft: "1rem",
            marginRight: "50px",
          }}
          placeholder="Search Member"
          value={searchMember}
          onChange={searchMemberHandler}
          autoFocus={focusSearchMember}
        />
        <CustomButton
          testId="admin-team-formation-btn"
          className="admin-team-btn"
          content="EXPORT CSV"
          clicked={GETUserlistCSV}
        ></CustomButton>
      </div>
      <div className="admin-team-table">
        {loading ? (
          <div className="spinner" style={{ marginTop: "50px" }}>
            <Spinner size={50} />
          </div>
        ) : (
          <table class="tbl">
            <thead style={{ backgroundColor: "white" }}>
              <tr>{renderHeader()}</tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AdminTeamMng;
