const getBackendDomain = () => {
  /* 
    Function to check that backend domain in .env file is configured properly.
  */

  // import env variables here
  const { REACT_APP_BACKEND_DOMAIN } = process.env;

  if (!REACT_APP_BACKEND_DOMAIN || REACT_APP_BACKEND_DOMAIN === "") {
    // check if REACT_APP_BACKEND_DOMAIN exist in .env file
    throw new Error("Missing Backend Domain from .env file.");
    return;
  } else if (
    !REACT_APP_BACKEND_DOMAIN.includes("https://") &&
    !REACT_APP_BACKEND_DOMAIN.includes("http://")
  ) {
    // check if http/https protocol specified in REACT_APP_BACKEND_DOMAIN from .env file
    throw new Error(
      "Missing http/https protocol in Backend Domain from .env file."
    );
    return;
  }

  return REACT_APP_BACKEND_DOMAIN;
};

export const backendDomain = getBackendDomain();

