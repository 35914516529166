import * as React from "react";
import "./CustomButton.css";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

const CustomButton = ({ testId, className, content, clicked, loading, type='button' }) => {
  return (
    <button
      data-testid={`custom-btn-${testId}`}
      className={className}
      onClick={clicked}
      type={type}
    >
      {loading ? (
        <div className="spinner">
          <Spinner />
        </div>
      ) : (
        <div>{content}</div>
      )}
    </button>
  );
};

export default CustomButton;
