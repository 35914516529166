const bytesConverter = (
  bytes,
  mode = "auto",
  decimal_place = 2,
  with_units = true
) => {
  /*
   * Function to format bytes to bytes/mb/gb format, with the corresponding unit.
   * @params:
   *  - bytes (str/int): the number in bytes to format.
   *  - mode (str):
   *    - specifies the unit to format the bytes into, i.e. bytes, mb, gb or auto.
   *    - auto will determine the appropriate unit to format into based on size of the input bytes.
   *  - decimal_place (int): set the number of decimal place for the formatted result.
   *  - with_units (bool): adds the appropriate units to the end as a string
   */

  if (mode === "GB") {
    const gigabytes = parseInt(bytes, 10) / (1024 * 1024 * 1024);
    let result = gigabytes.toFixed(decimal_place);
    if (with_units) {
      result += ` ${mode}`;
    }
    // console.log(result);
    return result;
  } else if (mode === "MB") {
    const megabytes = parseInt(bytes, 10) / (1024 * 1024);
    // console.log(result);
    let result = megabytes.toFixed(decimal_place);
    if (with_units) {
      result += ` ${mode}`;
    }
    return result;
  } else if (mode === "KB") {
    const kilobytes = parseInt(bytes, 10) / (1024);
    // console.log(result);
    let result = kilobytes.toFixed(decimal_place);
    if (with_units) {
      result += ` ${mode}`;
    }
    return result;
  } else if (mode === "auto") {
    // automatically determine the appropriate unit (e.g. bytes/mb/gb)
    // to return the bytes based on the size of the input bytes
    var kb_value = parseInt(bytes, 10) / 1024;
    var mb_value = kb_value / 1024;
    var gb_value = mb_value / 1024;

    if (gb_value >= 1) {
      if (decimal_place) {
        gb_value = gb_value.toFixed(2)
      }
      if (with_units) {
        gb_value += ` GB`;
      }
      return gb_value;
    } else if (mb_value >= 1) {
      if (decimal_place) {
        mb_value = mb_value.toFixed(2)
      }
      if (with_units) {
        mb_value += ` MB`;
      }
      return mb_value;
    } else if (kb_value >= 1) {
      if (decimal_place) {
        kb_value = kb_value.toFixed(2)
      }
      if (with_units) {
        kb_value += ` KB`;
      }
      return kb_value;
    }
    if (with_units) {
      bytes += ` Bytes`;
    }
    return bytes;
  }
};

export default bytesConverter;
