import React, { useEffect, useState } from "react";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetVerifyAuth } from "redux/appSlice";
import "./SendVerificationEmail.css";
import logo from "assets/check.png";
import CustomButton from "shared/CustomButton/CustomButton";
import toast from "react-hot-toast";
import { useTimer } from "utils/useTimer";

const SendVerificationEmail = () => {
  /**
   * Function that returns a page where an unverified user is redirected
   * to after registering or logging in.
   */
  const [isEmail, setIsEmail] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const username = searchParams.get("username");

  const email = searchParams.get("email");

  const dispatch = useDispatch();

  const { seconds, start, reset, stop } = useTimer();

  // send verification email to attached username/email
  useEffect(() => {
    email ? setIsEmail(true) : setIsEmail(false);
  }, []);

  // function to handle reset verification
  const handleResetVerification = () => {
    if (seconds === 30) {
      if (email) {
        dispatch(resetVerifyAuth({ username: null, email: email }));
        start();
      } else if (username) {
        dispatch(resetVerifyAuth({ username: username, email: null }));
        start();
      }
    } 
  };

  useEffect(() => {
    if (seconds === 0) {
      stop();
      reset();
    }
  }, [seconds]);

  return (
    <div data-testid="vemail-container" className={"vemail-container"}>
      <img
        style={{
          marginTop: "1.25rem",
          width: "120px",
          height: "120px",
          marginBottom: "14px",
        }}
        src={logo}
      />
      <div className={"vemail-title"}>Verify your email address</div>
      {isEmail ? (
        <p className={"vemail-details"}>
          An email verification has been sent to <b>{email}.</b>
        </p>
      ) : (
        <p className={"vemail-details"}>
          An email verification has been sent to the email of <b>{username}.</b>
        </p>
      )}

      <p className={"vemail-details"}>
        Please verify your email before accessing the website.
      </p>
      <p className={"vemail-details"}>
        Please check your email and follow the instructions to complete the
        verification process.
      </p>
      {seconds === 30 ? (
        <CustomButton
          testId="vemail-btn"
          className="verification-button"
          content={"Resend Email"}
          clicked={handleResetVerification}
        ></CustomButton>
      ) : (
        <CustomButton
          testId="vemail-btn"
          className="disabled-button"
          content={seconds}
        ></CustomButton>
      )}
    </div>
  );
};

export default SendVerificationEmail;
