import React, { useState, useEffect } from "react";
import "./Switch.css";

const Switch = ({
  isOn,
  handleToggle,
  colorOne,
  colorTwo,
  titleOne,
  titleTwo,
  includeTitle,
  className,
}) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={className}
        id={`switch`}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? colorOne : colorTwo }}
        className={includeTitle ? "switch-label-two" : "switch-label"}
        htmlFor={`switch`}
      >
        <span className={`switch-button`} />
        {includeTitle && isOn && (
          <div
            style={{
              marginLeft: "35px",
            }}
          >
            {titleOne}
          </div>
        )}
        {includeTitle && !isOn && (
          <div
            style={{
              marginLeft: "35px",
            }}
          >
            {titleTwo}
          </div>
        )}
      </label>
    </>
  );
};

export default Switch;
