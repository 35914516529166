import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import userListReducer from "./userListSlice";
import loginReducer from "./loginSlice";
import registerReducer from "./registerSlice";
import createTeamReducer from "./createTeamSlice";
import leaderboardReducer from "./leaderboardSlice";
import mainDashReducer from "./mainDashSlice";
import userProfileReducer from "./userProfileSlice";
import submitResultsReducer from "./submitResultsSlice";


const store = configureStore({
  reducer: {
    appState: appReducer,
    userListState: userListReducer,
    loginState: loginReducer,
    registerState: registerReducer,
    createTeamState: createTeamReducer,
    leaderboardState: leaderboardReducer,
    mainDashState: mainDashReducer,
    userProfileState: userProfileReducer,
    submitResultsState: submitResultsReducer,
  },
});

export default store;
