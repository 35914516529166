import React from "react";
// Added 20230104 for navigating after form submit to home
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./ForgetLoginForm.css";
import CustomButton from "shared/CustomButton/CustomButton";
import {
  forgetPasswordAuth,
  completeForgetPassword,
} from "redux/appSlice";

const schema = yup.object().shape({
  userIdentifier: yup
    .string()
    .required("Email or Username is required")
    .matches(
      /^[^\s]+$/, // check for any spaces
      "Email or Username is of invalid format"
    ),
});

const ForgetLoginForm = () => {
  /**
   * Function that returns a page is able to reset
   * their password if they forget it.
   */

  // Navigation after forget login
  const navigateLogin = useNavigate();
  const dispatch = useDispatch();

  // register - register inputs, handleSubmit - handle form submit, reset - reset the form, formState containing error
  const {
    register,
    handleSubmit,
    // control,
    // reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const checkIsEmail = (identifier) => {
    // function to check whether identifier (str) is in the email format
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const result = emailRegex.test(identifier);
    return result;
  };

  const onSubmit = async (data) => {
    /**
     * Function that processes the user's input and sends it to the 
     * forget password api when the submit button is clicked by the user.
     * 
     * Params: 
     * - data: the form data in the page that has been registered (eg. userIdentifier because of ...register("userIdentifer"))
     */
    let username = null;
    let email = null;

    // check of user uses username or email to reset their password
    if (checkIsEmail(data.userIdentifier)) {
      email = data.userIdentifier;
    } else {
      username = data.userIdentifier;
    }

    // Navigating after form submit to home (Redirection after successful login)
    dispatch(forgetPasswordAuth({ username: username, email: email })); // dispatch Backend API to send the reset_token to the email
    dispatch(completeForgetPassword()); // dispatch action to reset forget password states in redux store
  };

  const handlePressEnter = (e) => {
    /*
      Placed in forget password input box so submit function is 
      triggered when user presses the "enter" key.
    */
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className={"forget-login-container"}>
      {/* FORM INSTRUCTIONS */}
      <div className={"forget-login-title"}>
        Enter your account's Email or Username and
        <br /> we will send you instructions to reset your password.
      </div>
      <div>
        {/* USER INPUT BOX (USERNAME OR EMAIL) */}
        <div>
          <input
            style={{
              borderRadius: "0.25rem",
              marginTop: "0.9375rem",
              backgroundColor: "aliceblue",
              border: "0.0625rem solid #d8e0ff",
              width: "21.25rem",
              height: "3.125rem",
              padding: "1.25rem",
              marginBottom: "1.875rem",
            }}
            type="text"
            name="userIdentifier"
            {...register("userIdentifier")} // required for react-hook-form's useForm
            // value={userIdentifierValue} // required to send value
            // onChange={userIdentifierHandler}
            onKeyDown={handlePressEnter}
            placeholder="Email or Username"
          />
        </div>
        {/* USER USERNAME/EMAIL INPUT BOX ERROR MESSAGE */}
        {/* <p
            style={{
              color: "red",
              marginTop: "0.5rem",
              textAlign: "left",
              marginLeft: "1.85rem",
            }}> */}
        {errors.userIdentifier && (
          <p data-testid="forget-login-input-box-error-message" style={{ color: "red" }}>{errors.userIdentifier?.message}</p>
          )}
        {/* </p> */}

        {/* SUBMIT BUTTON */}
        <div>
          <CustomButton
            className="basic"
            testId="forget-login-submit-btn"
            content="Submit"
            clicked={handleSubmit(onSubmit)}
          ></CustomButton>
        </div>
        <div>
          {/* BACK BUTTON */}
          <CustomButton
            className="back-button"
            testId="forget-login-back-btn"
            content="Back"
            clicked={async () => {
              navigateLogin("/login");
            }}
          ></CustomButton>
        </div>
      </div>
    </div>
  );
};

export default ForgetLoginForm;
