import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import "./UserList.css";
import { backendDomain } from "utils/envConfig";

const UserList = () => {


  let sortedList = [];

  const [list, setList] = useState([]);
  const [values, setValue] = useState("50");


  const getUserList = async () => {
    let url =
      values === "All"
        ? `${backendDomain}/api/v0.1/auth/user`
        : `${backendDomain}/api/v0.1/auth/user?n_offset=` +
          "0" +
          `&n_limit=${values}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();

        setList(response_data);
      } else {
        let errorResponse = await response.json();
      }
    } catch (err) {
   
    }
  };

  useEffect(() => {
    getUserList();
    //setList(data);
  }, [values]);

  useEffect(() => {
    if (typeof list !== "") {
      sortedArr(list);
    }
  }, [list]);

  function sortedArr(data) {
    if (data.length === 0) {
      return;
    }
    data?.results?.map(function (element) {
      sortedList.push({
        username: element.username,
        email: element.email,
        organisation: element.organisation,
      });

      return sortedList;
    });
  }

  const data2 = {
    columns: [
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Organisation",
        field: "organisation",
        sort: "asc",
        width: 270,
      },
    ],
    rows: sortedList,
  };

  const deleteUser = async (username) => {
    let url = `${backendDomain}/api/v0.1/auth/user`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          username: username,
        }),
      });

      if (response.ok) {
        // await read the body stream response.json (Promise)
        const response_data = await response.json();

        window.location.reload();
      } else {
        let errorResponse = await response.json();
      }
    } catch (err) {

    }
  };

  return (
    <div className="o-userlist-container">
      <div className="o-userlist-title">
        <h1>List of Users</h1>
      </div>
      <MDBDataTable
        responsive={true}
        large
        dark
        noBottomColumns
        data={data2}
        displayEntries={false}
      />
    </div>
  );
};

export default UserList;
