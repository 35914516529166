import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  login,
  logOut,
  useIsLoggedIn,
  useUserLoginErrors,
  useUserUnverifiedLogin,
  useIsLoginFailedError,
  useIsResetLoginAttemptFailedError,
  useIsUserLoginAttemptsExceeded,
  completeLogin,
  useLoginLoading,
  useLoginStatus,
} from "redux/loginSlice";

import "./LoginForm.css";
import CustomButton from "shared/CustomButton/CustomButton";
import { Link, Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const LoginForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  const [username, setChangeUsername] = useState("");
  const [password, setChangePassword] = useState("");
  const [identifierForVerificationEmail, setIdentifierForVerificationEmail] =
    useState("");

  const dispatch = useDispatch(); // for dispatching actions to redux slice
  const navigate = useNavigate(); // for navigation of pages

  // event handlers for form inputs
  const usernameHandler = (event) => {
    /*
     * Function to set user's input to state when username's input box is changed.
     */
    setChangeUsername(event.target.value);
  };

  const passwordHandler = (event) => {
    /*
     * Function to set user's input to state when password's input box is changed.
     */
    setChangePassword(event.target.value);
  };

  // const userLoginErrors = useUserLoginErrors(); // check for login errors stored in redux store
  // if (userLoginErrors.length > 0) {
  //   'pass'
  // }

  // initialise redux hooks
  const loginLoading = useLoginLoading();
  const loginStatus = useLoginStatus();
  const isLoginFailedError = useIsLoginFailedError();
  const isResetLoginAttemptFailedError = useIsResetLoginAttemptFailedError();
  const isUserLoginAttemptsExceeded = useIsUserLoginAttemptsExceeded();

  // redirecting unverified users to email verification page
  const userUnverifiedLogin = useUserUnverifiedLogin(); // checks if logged in user's email is verified
  const isLoggedIn = useIsLoggedIn(); // check if user is logged in

  useEffect(() => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      return;
    }
  }, []);

  useEffect(() => {
    // if login has error
    if (loginStatus === "rejected") {
      // checks if user that is logging in is verified or not
      if (userUnverifiedLogin) {
        // if logged in user's email is not verified, redirect to sendVerificationEmail page
        const queryParams = new URLSearchParams();

        // check if user entered their email address or username
        isEmail
          ? queryParams.set("email", identifierForVerificationEmail)
          : queryParams.set("username", identifierForVerificationEmail);

        const sendVerificationEmailPageUrl = `/send-verification-email?${queryParams.toString()}`; // craft redirection url
        dispatch(completeLogin()); // reset states
        navigate(sendVerificationEmailPageUrl); // redirect to sendVerificationEmail page
      } else if (isLoginFailedError) {
        toast.error("Login failed: Incorrect Email, Username or Password. "); // toast message for user's login failure
        dispatch(logOut()); // reset states
      } else if (isResetLoginAttemptFailedError) {
        toast.error(
          "Login failed: Something went wrong, please contact the challenge's Admin. "
        ); // toast message for backend's reset login attempt failure
        dispatch(logOut()); // reset states
      } else if (isUserLoginAttemptsExceeded) {
        toast.error(
          "Login failed: Login attempts exceeded limit. Please contact the challenge's Admin."
        ); // toast message for backend's reset login attempt failure
        dispatch(logOut()); // reset states
      }
    }
  }, [loginStatus]);

  if (isLoggedIn) {
    // when user logs in, redirect them to the page they previously visited
    // if logged in user is verified
    // TODO: consider using the useHistory hook instead
    //       to implement routing back to the most recent protected route that
    //       a newly logged in user clicked on
    // let historyPath = localStorage.getItem("historyPath");

    if (localStorage.getItem("historyPath") === "1") {
      return <Navigate to="/submit-create" />;
    } else if (localStorage.getItem("historyPath") === "2") {
      return <Navigate to="/leaderboard" />;
    } else if (localStorage.getItem("historyPath") === "3") {
      return <Navigate to="/team-dashboard" />;
    } else if (localStorage.getItem("historyPath") === "4") {
      return <Navigate to="/user-profile" />;
    }
    return <Navigate to="/user-profile" />;
  }

  const checkIsEmail = (identifier) => {
    // function to check whether identifier (str) is in the email format
    const emailRegex = /^[^\s@]+@[^\s@]+$/;
    const result = emailRegex.test(identifier);
    setIsEmail(result);
    return result;
  };

  const handleLoginButton = async () => {
    /*
      the function to run when login button is clicked
    */

    // update state
    setFormSubmitted(true);

    if (username.length !== 0 && password.length !== 0) {
      // if username not empty
      if (checkIsEmail(username)) {
        // check if user entered their username or email
        dispatch(
          login({
            username: null,
            email: username,
            password: password,
          })
        );
      } else {
        dispatch(
          login({
            username: username,
            email: null,
            password: password,
          })
        );
      }

      setIdentifierForVerificationEmail(username);
      setFormSubmitted(false);
      setChangeUsername("");
      setChangePassword("");
    }
  };

  const handlePressEnter = (e) => {
    /*
     *  Placed in password input field so login action is triggered when user
     * presses the "enter" key.
     */
    if (e.key == "Enter") {
      handleLoginButton();
    }
  };

  return (
    <div data-testid="login-container" className={"login-container"}>
      <div data-testid="login-title" className="login-title">
        Login
      </div>

      {/* USERNAME INPUT FIELD */}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="text"
        name="name"
        placeholder="Email or Username"
        value={username}
        onChange={usernameHandler}
        onKeyDown={handlePressEnter}
      />

      {/* USERNAME REQUIRED TOOLTIP */}
      {username.length === 0 && formSubmitted ? (
        <div
          data-testid="login-username-required"
          style={{
            marginRight: "16.25rem",
            paddingTop: "1.25rem",
            paddingBottom: "0.625rem",
            marginBottom: "0.3125rem",
            marginTop: "-0.9375rem",
            color: "red",
          }}
        >
          *required
        </div>
      ) : null}

      {/* PASSWORD INPUT FIELD */}
      <input
        style={{
          borderRadius: "0.25rem",
          marginTop: "0.9375rem",
          backgroundColor: "aliceblue",
          border: "0.0625rem solid #d8e0ff",
          width: "21.25rem",
          height: "3.125rem",
          padding: "1.25rem",
        }}
        type="password"
        name="name"
        placeholder="Password"
        value={password}
        onChange={passwordHandler}
        onKeyDown={handlePressEnter}
      />

      {/* PASSWORD REQUIRED TOOLTIP */}
      {password.length === 0 && formSubmitted ? (
        <div
          data-testid="login-password-required"
          style={{
            marginRight: "16.25rem",
            paddingTop: "1.25rem",
            paddingBottom: "0.625rem",
            marginBottom: "0.3125rem",
            marginTop: "-0.9375rem",
            color: "red",
          }}
        >
          *required
        </div>
      ) : null}

      {/* FORGET PASSWORD BUTTON */}
      <Link
        style={{
          margin: "1.25rem",
          color: "#0969da",
          textDecorationLine: "none",
        }}
        to="/forget-login"
      >
        Forget Password ?
      </Link>

      {/* LOGIN BUTTON */}
      <CustomButton
        testId="login-btn"
        className="basic"
        content="Login"
        clicked={handleLoginButton}
        loading={loginLoading}
      ></CustomButton>

      {/* DISABLE REGISTRATION FOR 2B by commenting out next <div>*/}
      {/* SIGN UP BUTTON */}
      <div data-testid="login-sign-up-link" className={"login-sign-up-link"}>
        {"Don't have an account ? "}
        <Link to="/register">
          <div className="sign-up-link">Sign Up</div>
        </Link>
      </div>


    </div>
  );
};

export default LoginForm;
